import {
    getReferenceItemsSuccess, getReferenceItemsFail
} from "../constants";

export const initialState = {
    user: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getReferenceItemsSuccess:
            return {
                ...state,
                referenceItems: action.payload,
            };
        case getReferenceItemsFail:
            return {
                ...state,
                referenceItems: action.payload.message,
            };
        default:
            return state;
    }

}