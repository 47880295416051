import { connect } from 'react-redux'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useMemo, useState } from 'react';
import { ApiCall_GET, ApiCall_POST } from '../../../Redux/Generic/action'
import { PermissionType, getUserInformation, returnNullIfEmpty, showNotificationMessage } from '../../../common';
import { useAppContext } from '../../../AppContextProvider ';
import Swal from 'sweetalert2';



export const Permission = ({ ApiCall_POST, ApiCall_GET }) => {
    // const [username, setUsername] = useState()
    // const [password, setPassword] = useState()
    const [userTypeList, setUserTypeList] = useState(null)
    const [activeItem, setActiveItem] = useState(1)
    const [selectedItem, setSelectedItem] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [permissionsList, setPermissionsList] = useState([])
    const [showSummary, setShowSummary] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [showtype, setshowtype] = useState('')
    const [ShowModule, setShowModule] = useState(false)
    const [showInnerModal, setShowInnerModal] = useState(false);
    const theme = useAppContext()?.themeDetails;
    const [userType, setUserType] = useState('');
    const [numInputs, setNumInputs] = useState(5);

    const inputRefs = useMemo(() => {
        return Array.from({ length: numInputs }, () => React.createRef());
    }, [numInputs]);

    const modelHandler = (x) => {
        setShowModule(true, x)
        setshowtype(x)
        // setActiveIndex(index === activeIndex ? null : index);
    };

    const moduleType = {
        addModule: 1,
        addUser: 2
    }

    const [themeset, setTheme] = useState('');

    useEffect(() => {

        ApiCall_GET('UserManagment/GetUserAndUserTypeDetails', true).then(function (payload) {
            setUserTypeList(payload)
        });

    }, [])

    const handleSubmit = () => {

        setShowInnerModal(false)
        ApiCall_POST('UserManagment/SaveNewUserType/' + userType, null, true).then(function (payload) {
            if (payload === 2) {
                showNotificationMessage('warning', 'User type already exist.', true)
            } else {
                showNotificationMessage('success', 'User type has been saved.', false)
            }


        });
        setShowModule(false)

    }

    const handlePinChange = (e, index) => {

        const value = e.target.value;
        if (value.length === 1 && index < 4) {
            inputRefs[index + 1].current.focus();
            inputRefs[index].current.value = value;
        }

        else if (value.length === 0 && index > 0) {
            inputRefs[index].current.value = "";
            inputRefs[index - 1].current.focus();
        }

        // else if (!value && inputRefs.map(ref => ref.current.value).length > index) {
        //   inputRefs[index].current.focus();
        // }

        const pinvalue = inputRefs.map(ref => ref.current.value).join(''); // Extract the PIN value from the inputRefs array


        if (pinvalue.length === 5) {
            const userInfo = getUserInformation();
            if (userInfo.PinNumber === parseInt(pinvalue, 10)) {
                handleSubmit()
                // sendDataToApi();
              
                // setShowModule(false);
            } else {
                setShowAlert(true);
            }
        } else {
            setShowAlert(false);
        }
        return;
    };

    const handleInputChange = (e) => {

        var value = e.target.value
        if (value.length > 4) {
            var userInfo = getUserInformation()
            if (userInfo.PinNumber === Number(value)) {
                handleSubmit();

                setShowModule(false)
                setShowInnerModal(false)
            } else {
                setShowAlert(true);
            }
        } else {
            setShowAlert(false);
        }
        return;
    };






    const [activeIndex, setActiveIndex] = useState(null);

    const items = [
        {
            title: 'Section 1',
            content: 'Content for section 1 goes here.',
        },
        {
            title: 'Section 2',
            content: 'Content for section 2 goes here.',
        },
        {
            title: 'Section 3',
            content: 'Content for section 3 goes here.',
        },
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const showPermissions = (item, isUser) => {
        cancelChanges(false)
        setSelectedItem(item)

        ApiCall_GET('UserManagment/GetAllUsersPermissionsDetails/' + (item.UserId || 0) + '/' + (item.usertypeid || 0), true).then(function (payload) {
            setPermissionsList(returnNullIfEmpty(payload))
        });

    }

    function isEmptyObject(obj) {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    const [permissionsListUpdate, setPermissionsListUpdate] = useState([])


    const changePermissionType = (e, item, val) => {
        const { checked } = e.target;
        if (checked) {
            let indexValue = Number(e.target.getAttribute('data-key'))
            indexValue && setActiveIndex(indexValue)
            const existsIndex = permissionsListUpdate.findIndex(x => x.Id === item.Id);
            if (existsIndex !== -1) {
                // If the item already exists, update the existing array without mutating the original state
                const updatedPermissionsList = [...permissionsListUpdate];

                // Update the current item
                updatedPermissionsList[existsIndex].PermissionTypeId = val;
                updatedPermissionsList[existsIndex].UserId = activeItem === 1 ? selectedItem.UserId : selectedItem.usertypeid;

                // Update children
                const childrenToUpdate = updatedPermissionsList.filter(x => x.ParentModule === item.Id);
                childrenToUpdate.forEach(child => {
                    child.PermissionTypeId = val;
                    child.UserId = activeItem === 1 ? selectedItem.UserId : selectedItem.usertypeid;
                });

                setPermissionsListUpdate(updatedPermissionsList);
            } else {
                // If the item doesn't exist, add a new item to the array
                const updatedItem = { ...item, PermissionTypeId: val, UserId: (activeItem === 1 ? selectedItem.UserId : selectedItem.usertypeid) };

                // Update children
                const children = permissionsList.filter(x => x.ParentModule === item.Id);
                children.forEach(child => {
                    child.PermissionTypeId = val;
                    child.UserId = activeItem === 1 ? selectedItem.UserId : selectedItem.usertypeid;
                });

                setPermissionsListUpdate(prevData => [...prevData, updatedItem, ...children]);
            }


            const updatedPermissionsList = permissionsList.map(permission => {
                if (permission.Id === item.Id) {
                    // Update the current item
                    return { ...permission, PermissionTypeId: val };
                } else if (permission.ParentModule === item.Id) {
                    // Update children
                    return { ...permission, PermissionTypeId: val };
                } else {
                    // No change needed for other items
                    return permission;
                }
            });

            setPermissionsList(updatedPermissionsList);

        }
    }

    const changeAllPermissions = (pType) => {
        const updatedPermissions = permissionsList.map(permission => ({
            ...permission,
            PermissionTypeId: pType,
            UserId: (activeItem === 1 ? selectedItem.UserId : selectedItem.usertypeid)
        }));

        setPermissionsList(updatedPermissions);
        setPermissionsListUpdate(updatedPermissions);
    }

    const cancelChanges = (IscallfromCancel) => {
        setPermissionsList([]);
        setPermissionsListUpdate([]);
        setSelectedItem(null);
        IscallfromCancel && showPermissions(selectedItem, false)
    }

    const savePermissionsUpdate = () => {

        var url = activeItem === 1 ? 'UserManagment/SaveUserModeulePermissions' : 'UserManagment/SaveModulePermissions';

        ApiCall_POST(url, permissionsListUpdate, true).then(function (payload) {

            Swal.fire({
                icon: 'success',
                title: 'Permission(s) Saved.',
                showConfirmButton: false,
                timer: 1500
            })

        });
    }
    var Themes = require('../../../Theme.json');
    const toggleTheme = (clr) => {
        let selectedTheme = Themes.filter(x => x.themeColorId === clr)[0]
        setTheme(selectedTheme || Themes[0])
    };
    return (
        <div className='w-full h-full p-2 bg-white '>

            <div className={theme.bg_Light + ' flex items-between p-1 rounded'}>
                <div className='w-2/5 text-left'>
                    <label className={theme.txt_color + ' text-md text-gray-700 font-medium pl-1'}>User Management</label>
                </div>
                <div className='w-3/5 text-right flex justify-end'>
                    <button
                        // onClick={() => navigate('/coupledetails', { state: { id: coupleid } })}
                        className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
                        </svg>
                        <label className='pl-2 cursor-pointer'> Back to ART</label>
                    </button>


                </div>
            </div>

            <div className='w-full flex my-2 '>
                <Button onClick={() => setShowSummary(true)} variant="outlined" sx={{ marginRight: 2, padding: 0.5, fontSize: 11 }} color="success"><AddIcon fontSize='small' /> Add User</Button>
                <Button onClick={() => modelHandler(moduleType.addUser)} variant="outlined" sx={{ marginRight: 2, padding: 0.5, fontSize: 11 }} color="success"><AddIcon fontSize='small' /> Add User Group</Button>
                <Button onClick={() => modelHandler(moduleType.addModule)} variant="outlined" sx={{ marginRight: 2, padding: 0.5, fontSize: 11 }} color="success"><AddIcon fontSize='small' /> Add Module</Button>
            </div>


            {/* ========= */}
            <div style={{ height: '88%' }} className='w-full flex justify-between '>
                <div className='shadow-md rounded-md w-3/12  mr-1 border'>
                    <div className='p-2 pb-0'>
                        <div className={' flex mb-2 justify-start items-center shadow-md cursor-pointer w-full rounded'}>
                            <div className={(activeItem === 1 ? theme.itemActive : theme.txt_color) + " w-1/2   text-white activeART rounded border mr-0.5 " + theme.side_Br_Hover} onClick={() => setActiveItem(1)}>
                                <p className="my-3 hover:scale-110 text-sm text-center font-bold textOverflowNavART ">Users</p>
                            </div>
                            <div className={(activeItem === 2 ? theme.itemActive : theme.txt_color) + " w-1/2   text-white activeART rounded border " + theme.side_Br_Hover} onClick={() => setActiveItem(2)}>
                                <p className="my-3 hover:scale-110 text-sm text-center font-bold textOverflowNavART ">User Groups</p>
                            </div>
                        </div>
                        {userTypeList &&
                            <div className="relative w-full px-2">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-4 text-gray-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                </div>
                                <input type="text" id="simple-search" className=" w-full h-8 rounded-3xl border   pl-10 p-2.5 text-sm" placeholder={activeItem === 1 ? "Search User" : "Search User Group"} value={searchText} onChange={handleSearchTextChange} />
                                {/* <input type="text" id="simple-search" className=" h-6 w-full outline-none rounded-3xl  block  pl-10 p-2.5  dark:bg-white-700  dark:placeholder-gray-400 dark:text-black text-sm" placeholder="Search Patient/Couple"  /> */}
                            </div>
                        }
                        {userTypeList &&
                            <p className='text-end text-xs pt-1 pb-1 border-b'>
                                Showing&nbsp;
                                {activeItem === 1 ? userTypeList?.UserDetails.filter(x => x.FullName.toLowerCase().includes(searchText.toLowerCase()) > 0).length : userTypeList?.UserTypes.filter(x => x.usertypelabel.toLowerCase().includes(searchText.toLowerCase()) > 0).length}
                                &nbsp;of&nbsp;
                                {activeItem === 1 ? userTypeList?.UserDetails.length : userTypeList?.UserTypes.length}
                            </p>
                        }</div>
                    {/* <div className={theme.thumb + ' ' + theme.track + ' overflow-y-scroll  scrollbar  scrollbar-thin'} style={{ height: '36rem' }}> */}
                    <div style={{ height: '80%' }} className={theme.thumb + ' ' + theme.track + '  overflow-y-scroll scrollbar scrollbar-thin '}>
                        {userTypeList && (activeItem === 1 ?
                            userTypeList?.UserDetails.filter(x => x.FullName.toLowerCase().includes(searchText.toLowerCase()) > 0).map((item) => (
                                <>
                                    <div className={(selectedItem?.UserId === item.UserId ? theme.navbar + ' text-white ' : '') + ' shadow-md rounded-md p-2 mt-1 flex justify-between items-center cursor-pointer ' + theme.sidebarSub} onClick={() => showPermissions(item, true)}>
                                        <div className=' flex items-center'>
                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.53658 7.85714C7.65833 7.85714 8.73413 7.44324 9.52732 6.70649C10.3205 5.96974 10.7661 4.97049 10.7661 3.92857C10.7661 2.88665 10.3205 1.8874 9.52732 1.15065C8.73413 0.413902 7.65833 0 6.53658 0C5.41483 0 4.33903 0.413902 3.54583 1.15065C2.75264 1.8874 2.30703 2.88665 2.30703 3.92857C2.30703 4.97049 2.75264 5.96974 3.54583 6.70649C4.33903 7.44324 5.41483 7.85714 6.53658 7.85714ZM2.30703 9.28572C1.69517 9.28572 1.10836 9.51148 0.675713 9.91334C0.243061 10.3152 0 10.8603 0 11.4286V11.5357C0 11.5357 0 15.7143 6.53658 15.7143C13.0732 15.7143 13.0732 11.5357 13.0732 11.5357V11.4286C13.0732 10.8603 12.8301 10.3152 12.3974 9.91334C11.9648 9.51148 11.378 9.28572 10.7661 9.28572H2.30703Z" fill={(selectedItem?.UserId === item.UserId ? '#fff' : theme.iconColor)} />
                                            </svg>

                                            <div className='ml-2'>
                                                <p className='text-sm'>{item.FullName}</p>
                                                <p className='text-xs'>{item.primaryusertypename}</p>
                                            </div>
                                        </div>
                                        <div className='py-2 '>
                                            <svg width="18" height="16" viewBox="0 0 10 10" className='mr-1 cursor-pointer hover:scale-125' fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <title>Edit User</title>
                                                <path d="M1.5 8.00058H3.62C3.6858 8.00096 3.75103 7.98835 3.81195 7.96348C3.87287 7.9386 3.92828 7.90195 3.975 7.85562L7.435 4.39163L8.855 3.00203C8.90186 2.95556 8.93906 2.90028 8.96445 2.83936C8.98983 2.77845 9.0029 2.71312 9.0029 2.64713C9.0029 2.58115 8.98983 2.51581 8.96445 2.4549C8.93906 2.39399 8.90186 2.3387 8.855 2.29223L6.735 0.147856C6.68852 0.101006 6.63322 0.0638193 6.57229 0.0384423C6.51136 0.0130653 6.44601 0 6.38 0C6.31399 0 6.24864 0.0130653 6.18771 0.0384423C6.12678 0.0638193 6.07148 0.101006 6.025 0.147856L4.615 1.56245L1.145 5.02644C1.09866 5.07315 1.062 5.12854 1.03711 5.18944C1.01223 5.25034 0.99962 5.31555 1 5.38134V7.50072C1 7.63329 1.05268 7.76043 1.14645 7.85418C1.24021 7.94792 1.36739 8.00058 1.5 8.00058ZM6.38 1.20755L7.795 2.62214L7.085 3.33193L5.67 1.91734L6.38 1.20755ZM2 5.58628L4.965 2.62214L6.38 4.03673L3.415 7.00087H2V5.58628ZM9.5 9.00029H0.5C0.367392 9.00029 0.240215 9.05295 0.146447 9.14669C0.0526784 9.24043 0 9.36757 0 9.50014C0 9.63271 0.0526784 9.75986 0.146447 9.8536C0.240215 9.94734 0.367392 10 0.5 10H9.5C9.63261 10 9.75979 9.94734 9.85355 9.8536C9.94732 9.75986 10 9.63271 10 9.50014C10 9.36757 9.94732 9.24043 9.85355 9.14669C9.75979 9.05295 9.63261 9.00029 9.5 9.00029Z" fill={(selectedItem?.UserId === item.UserId ? '#fff' : theme.iconColor)} />
                                            </svg>
                                        </div>
                                    </div>
                                </>
                            ))
                            :
                            userTypeList?.UserTypes.filter(x => x.usertypelabel.toLowerCase().includes(searchText.toLowerCase()) > 0).map((item) => (
                                <div className={(selectedItem?.usertypeid === item.usertypeid ? theme.navbar + ' text-white ' : '') + ' shadow-md rounded-md p-2 mb-1 mt-0.5 flex items-center justify-between cursor-pointer ' + theme.sidebarSub} onClick={() => showPermissions(item, false)}>

                                    <div className=' flex'>
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.53658 7.85714C7.65833 7.85714 8.73413 7.44324 9.52732 6.70649C10.3205 5.96974 10.7661 4.97049 10.7661 3.92857C10.7661 2.88665 10.3205 1.8874 9.52732 1.15065C8.73413 0.413902 7.65833 0 6.53658 0C5.41483 0 4.33903 0.413902 3.54583 1.15065C2.75264 1.8874 2.30703 2.88665 2.30703 3.92857C2.30703 4.97049 2.75264 5.96974 3.54583 6.70649C4.33903 7.44324 5.41483 7.85714 6.53658 7.85714ZM2.30703 9.28572C1.69517 9.28572 1.10836 9.51148 0.675713 9.91334C0.243061 10.3152 0 10.8603 0 11.4286V11.5357C0 11.5357 0 15.7143 6.53658 15.7143C13.0732 15.7143 13.0732 11.5357 13.0732 11.5357V11.4286C13.0732 10.8603 12.8301 10.3152 12.3974 9.91334C11.9648 9.51148 11.378 9.28572 10.7661 9.28572H2.30703Z" fill={(selectedItem?.usertypeid === item.usertypeid ? '#fff' : theme.iconColor)} />
                                        </svg>
                                        <p className='text-sm ml-2'>{item.usertypelabel}</p>
                                    </div>
                                    <div className='py-2 '>
                                        <svg width="18" height="16" viewBox="0 0 10 10" className='mr-1 cursor-pointer hover:scale-125' fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <title>Edit User Group</title>
                                            <path d="M1.5 8.00058H3.62C3.6858 8.00096 3.75103 7.98835 3.81195 7.96348C3.87287 7.9386 3.92828 7.90195 3.975 7.85562L7.435 4.39163L8.855 3.00203C8.90186 2.95556 8.93906 2.90028 8.96445 2.83936C8.98983 2.77845 9.0029 2.71312 9.0029 2.64713C9.0029 2.58115 8.98983 2.51581 8.96445 2.4549C8.93906 2.39399 8.90186 2.3387 8.855 2.29223L6.735 0.147856C6.68852 0.101006 6.63322 0.0638193 6.57229 0.0384423C6.51136 0.0130653 6.44601 0 6.38 0C6.31399 0 6.24864 0.0130653 6.18771 0.0384423C6.12678 0.0638193 6.07148 0.101006 6.025 0.147856L4.615 1.56245L1.145 5.02644C1.09866 5.07315 1.062 5.12854 1.03711 5.18944C1.01223 5.25034 0.99962 5.31555 1 5.38134V7.50072C1 7.63329 1.05268 7.76043 1.14645 7.85418C1.24021 7.94792 1.36739 8.00058 1.5 8.00058ZM6.38 1.20755L7.795 2.62214L7.085 3.33193L5.67 1.91734L6.38 1.20755ZM2 5.58628L4.965 2.62214L6.38 4.03673L3.415 7.00087H2V5.58628ZM9.5 9.00029H0.5C0.367392 9.00029 0.240215 9.05295 0.146447 9.14669C0.0526784 9.24043 0 9.36757 0 9.50014C0 9.63271 0.0526784 9.75986 0.146447 9.8536C0.240215 9.94734 0.367392 10 0.5 10H9.5C9.63261 10 9.75979 9.94734 9.85355 9.8536C9.94732 9.75986 10 9.63271 10 9.50014C10 9.36757 9.94732 9.24043 9.85355 9.14669C9.75979 9.05295 9.63261 9.00029 9.5 9.00029Z" fill={(selectedItem?.usertypeid === item.usertypeid ? '#fff' : theme.iconColor)} />
                                        </svg>
                                    </div>
                                </div>
                            ))
                        )}

                    </div>



                    {/* <label className={theme.txt_color + ' text-md text-gray-700 font-medium pl-1'}>Create User</label>

                    <div className="mb-4">
                        <input
                            type="text"
                            placeholder="Username"
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoFocus={true}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            placeholder="Password"
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <select className={'border shadow rounded rounded p-2  mb-2 text-xss  text-gray-800  w-full focus:ring-1 focus:ring-green-500 focus:border-green-500'} >
                        <option className={'text-sm p-1 font-medium text-gray-800'} value={0}>--Select User Type--</option>
                        <option className={'text-xxs'} value="Clinician">System Administrator</option>
                        <option className={'text-xxs'} value="Clinician">Nurse</option>
                        <option className={'text-xxs'} value="Clinician">Clinician</option>
                    </select>
                    <div className=' py-2 rounded flex justify-end items-center border px-6 '>
                        <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"  > Cancel  </button>
                        <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded" >  Confirm</button>
                    </div> */}

                </div>
                <div className='shadow-md rounded-md w-9/12 p-2 ml-1 border round shadow'>

                    <div className={theme.navbar + " w-full mb-2 p-2 text-white activeART "}>
                        <p className=" my-1  text-sm  font-bold textOverflowNavART ">Permissions</p>
                    </div>

                    {selectedItem ?
                        <>
                            <div className={'my-2  rounded w-full'}>
                                <div className='text-left flex'>

                                    <div className='flex   items-center ml-2'>
                                        <label className="relative flex items-center cursor-pointer">
                                            <input type="radio" id="RemoveAll" name="checkAll" className={theme.toggle + " toggle-checkbox "} defaultChecked={false} onChange={() => changeAllPermissions(PermissionType.None)} />
                                            <label htmlFor="RemoveAll" className="toggle-label"></label>
                                        </label>
                                        <label htmlFor="RemoveAll" className="ml-2 cursor-pointer text-sm text-gray-700  font-bold">Remove All</label>
                                    </div>

                                    <div className='flex cursor-pointer items-center ml-2'>
                                        <label className="relative flex items-center cursor-pointer">
                                            <input type="radio" id="ReadAll" name="checkAll" className={theme.toggle + " toggle-checkbox "} defaultChecked={false} onChange={() => changeAllPermissions(PermissionType.Read)} />
                                            <label htmlFor="ReadAll" className="toggle-label"></label>
                                        </label>
                                        <label htmlFor="ReadAll" className="ml-2 cursor-pointer text-sm text-gray-700  font-bold">Read All</label>
                                    </div>

                                    {/* <div className='flex   items-center ml-2'>
                                        <label className="relative flex items-center cursor-pointer">
                                            <input type="radio" id="WriteAll" name="checkAll" className={theme.toggle + " toggle-checkbox "} defaultChecked={false} onChange={() => changeAllPermissions(PermissionType.Write)} />
                                            <label htmlFor="WriteAll" className="toggle-label"></label>
                                        </label>
                                        <label htmlFor="WriteAll" className="ml-2 cursor-pointer text-sm text-gray-700  font-bold">Write All</label>
                                    </div> */}

                                    <div className='flex   items-center ml-2'>
                                        <label className="relative flex items-center cursor-pointer">
                                            <input type="radio" id="Read/WriteAll" name="checkAll" className={theme.toggle + " toggle-checkbox "} defaultChecked={false} onChange={() => changeAllPermissions(PermissionType.ReadWrite)} />
                                            <label htmlFor="Read/WriteAll" className="toggle-label"></label>
                                        </label>
                                        <label htmlFor="Read/WriteAll" className="ml-2 cursor-pointer text-sm text-gray-700  font-bold">Read/Write All</label>
                                    </div>

                                </div>
                            </div>

                            <div className={theme.bg_Light + ' flex items-between items-center mb-1 p-1 rounded'}>
                                <div className='w-2/5 text-left'>
                                    <label className={theme.txt_color + ' text-md text-gray-700 font-medium pl-1'}>Modules</label>
                                </div>
                                <div className='w-3/5 text-center flex text-sm justify-end pr-1'>
                                    <label className='px-1  w-32'>  None  </label>
                                    <label className='px-1  w-32'>  Read  </label>
                                    {/* <label className='px-1  w-24'>  Write  </label> */}
                                    <label className='px-1  w-32'>  Read/Write  </label>
                                </div>
                            </div>


                            <div className={theme.thumb + ' ' + theme.track + ' overflow-y-scroll  scrollbar  scrollbar-thin'} >

                                {permissionsList.filter(x => isEmptyObject(x.ParentModule)).map((item, index) => (
                                    <div key={index} >
                                        <div
                                            className={"border-b border-gray-300 bg-gray-200 rounded cursor-pointer flex  items-center mb-0.5 p-1 " + theme.sidebarSub}

                                        >
                                            <svg
                                                onClick={() => toggleAccordion(index)}
                                                className={`w-4 h-4 ${activeIndex === index && 'transform rotate-180'}`}
                                                fill="none"
                                                stroke={theme.iconColor}
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                            <div className='flex justify-between w-full'>
                                                <h2 className="text-sm font-medium pl-2">  {item.Name}</h2>
                                                <div className={'flex items-center '}>
                                                    <div className={'flex items-center justify-center  w-32 flex items-center cursor-pointer'}>
                                                        <input data-key={index} type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.None} onChange={(e) => (changePermissionType(e, item, PermissionType.None))} className={theme.unCheqColor + ' cursor-pointer border focus:outline-none'} />
                                                    </div>
                                                    <div className={'flex items-center justify-center   w-32 flex items-center cursor-pointer'}>
                                                        <input data-key={index} type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.Read} onChange={(e) => changePermissionType(e, item, PermissionType.Read)} className={theme.cheqColor + ' cursor-pointer border focus:outline-none'} />
                                                    </div>
                                                    {/* <div className={'flex items-center justify-center  w-32 flex items-center cursor-pointer'}>
                                                        <input type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.Write} onChange={(e) => changePermissionType(e, item, PermissionType.Write)} className={theme.cheqColor + ' cursor-pointer border focus:outline-none'} />
                                                    </div> */}
                                                    <div className={'flex items-center justify-center   w-32 flex items-center cursor-pointer'}>
                                                        <input data-key={index} type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.ReadWrite} onChange={(e) => changePermissionType(e, item, PermissionType.ReadWrite)} className={theme.cheqColor + ' cursor-pointer border focus:outline-none'} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {activeIndex === index && <>
                                            {permissionsList.filter(x => !isEmptyObject(x.ParentModule) && x.ParentModule === item.Id).sort(x => x.ModuleOrder).map((item, index) => (
                                                <div className={(permissionsListUpdate.filter(x => x.Id === item.Id).length > 0 ? "bg-yellow-100" : "") + " p-1 border-b border-gray-200 flex justify-between"} key={index}>
                                                    <label className='pl-10 text-sm'>
                                                        {item.Name}
                                                    </label>

                                                    <div className={'flex items-center '}>
                                                        <div className={'flex items-center justify-center  w-32 flex items-center cursor-pointer'}>
                                                            <input type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.None} onChange={(e) => changePermissionType(e, item, PermissionType.None)} className={theme.unCheqColor + ' cursor-pointer border focus:outline-none'} />
                                                        </div>
                                                        <div className={'flex items-center justify-center   w-32 flex items-center cursor-pointer'}>
                                                            <input type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.Read} onChange={(e) => changePermissionType(e, item, PermissionType.Read)} className={theme.cheqColor + ' cursor-pointer border focus:outline-none'} />
                                                        </div>
                                                        {/* <div className={'flex items-center justify-center  w-32 flex items-center cursor-pointer'}>
                                                        <input type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.Write} onChange={(e) => changePermissionType(e, item, PermissionType.Write)} className={theme.cheqColor + ' cursor-pointer border focus:outline-none'} />
                                                    </div> */}
                                                        <div className={'flex items-center justify-center   w-32 flex items-center cursor-pointer'}>
                                                            <input type='radio' name={'permission_' + item.Id} checked={item.PermissionTypeId === PermissionType.ReadWrite} onChange={(e) => changePermissionType(e, item, PermissionType.ReadWrite)} className={theme.cheqColor + ' cursor-pointer border focus:outline-none'} />
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                        </>}

                                    </div>
                                ))}

                            </div>


                            <div className='text-left  pt-5 flex'>
                                <div className='w-2/5'>
                                    <button type="button" onClick={() => cancelChanges(true)} className="text-red-500 w-32 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 ">Cancel</button>
                                </div>
                                <div className='w-3/5 flex justify-end'>
                                    <button type="button" onClick={() => savePermissionsUpdate()} className="text-green-600 w-32  bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 " >Confirm</button>
                                </div>
                            </div>
                        </>
                        : <div className='flex justify-center'>
                            <p className='text-center'>Please select a {activeItem === 1 ? 'user' : 'user group'} from sidebar</p>
                        </div>
                    }

                </div>

            </div>
            {/* Edit User Popup===== */}
            {/* ${showAlert ? 'animate-pulse  border border-red-300 ' : ''} */}
            {showSummary &&
                <div className="fixed z-10 inset-0 overflow-y-auto ">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true" >
                            <div className="absolute inset-0 bg-gray-500 opacity-75" ></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true" ></span>

                        <div className={`w-4/6 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform  sm:my-8 sm:align-middle  `}
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >

                            <div className={`bg-white p-4    relative `}>
                                <div className={theme.thumb + ' ' + theme.track + '   rounded-md   overflow-y-auto scrollbar scrollbar-thin height-80 shadow mb-2'} >
                                    <div className='w-full  items-between  '>
                                        <div className={theme.tHedUpdte + ' text-white text-left  p-2 rounded-t'}>
                                            <p className='text-sbase font-medium text-center'>Edit User </p>
                                        </div>
                                        <div className='w-full shadow'>
                                            <div className={'flex justify-between items-center  border-b-[2px]  border-b-gray-200  p-2'}>
                                                <div className={' text-left  flex items-center w-1/2'}>
                                                    <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>Full Name</p>
                                                    <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </div>
                                                <div className={' text-left  flex items-center w-1/2'}>
                                                    <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>Login ID</p>
                                                    <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </div>
                                            </div>

                                            <div className={'flex justify-between items-center  border-b-[2px]  border-b-gray-200  p-2'}>
                                                <div className={' text-left  flex items-center w-1/2'}>
                                                    <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>Email Address</p>
                                                    <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </div>
                                                <div className={' text-left  flex items-center w-1/2'}>
                                                    <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>Password</p>
                                                    <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </div>
                                            </div>

                                            <div className={'flex justify-between items-center  border-b-[2px]  border-b-gray-200  p-2'}>
                                                <div className={' text-left  flex items-center w-1/2'}>
                                                    <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>Start Date</p>
                                                    <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </div>
                                                <div className={' text-left  flex items-center w-1/2'}>
                                                    <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>User Type</p>
                                                    {/* <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} /> */}
                                                    <select name='userType' className={theme.cheqColor + ' w-48 shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '}>
                                                        <option value={null}>~Select~</option>
                                                        {
                                                            // usertype && usertype.map((item) => (
                                                            <option
                                                            // value={item.usertype}

                                                            >user type  </option>
                                                            // ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className={'flex  items-center  border-b-[2px]  border-b-gray-200  p-2 '}>
                                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Mentor & Trainee</p>
                                                <div className={'pl-1 text-left  flex items-center  w-full'}>
                                                    {/* <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} /> */}
                                                    <div className='flex'>
                                                        <input type='checkbox' name='dayoneofcycle' className={'    border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                        <p className=' ml-2 cursor-pointer text-sbase text-gray-600'>Tick if Clinician is a mentor.</p>
                                                    </div>
                                                    <div className='flex mx-4'>
                                                        <input type='checkbox' name='dayoneofcycle' className={'    border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                        <p className=' ml-2 cursor-pointer text-sbase text-gray-600'>Tick if Clinician is a trainee</p>
                                                    </div>
                                                    <select name='userType' className={theme.cheqColor + ' w-48 shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '}>
                                                        <option value={null}>~Select~</option>
                                                        {
                                                            // clinicianlist && clinicianlist.map((item) => (
                                                            <option
                                                            // value={item.userid}
                                                            // selected={}
                                                            >Clinician  </option>
                                                            // ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={'flex  items-center  border-b-[2px]  border-b-gray-200  p-2  w-full'}>
                                                <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>RF Access</p>
                                                {/* <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} /> */}
                                                <div className='flex'>
                                                    <input type='checkbox' name='dayoneofcycle' className={'    border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                    <p className=' ml-2 cursor-pointer text-sbase text-gray-600'>Tick if Clinician may access RAINBOW FERTILITY data at linked CFC clinic.</p>
                                                </div>

                                            </div>
                                            <div className={'flex  items-center  border-b-[2px]  border-b-gray-200  p-2  w-full'}>
                                                <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>FSF Access</p>
                                                {/* <input type='text' name='dayoneofcycle' className={' w-1/2  shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} /> */}
                                                <div className='flex'>
                                                    <input type='checkbox' name='dayoneofcycle' className={'   shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                    <p className=' ml-2 cursor-pointer text-sbase text-gray-600'>Tick if Clinician may access FIRST STEP FERTILITY data at linked CFC clinic.</p>
                                                </div>
                                            </div>

                                            <div className={'flex justify-between items-center     p-2'}>
                                                <div className={' text-left  flex items-center  w-1/3'}>
                                                    <p className='cursor-pointer text-sbase font-bold w-32 text-gray-700'>Pin Code</p>
                                                    <input type='text' name='pin' className={'mr-2  w-1/2 shadow border rounded rounded-xl p-1text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </div>
                                                <div className={' text-left  flex items-center   w-1/3 '}>
                                                    <p className='cursor-pointer text-sbase font-bold   text-gray-700  mx-2'>Theme</p>
                                                    <select className={"w-full backdrop-opacity-50  items-center text-gray-700  rounded-md  p-1  text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none"} onChange={(e) => { toggleTheme(e.target.value) }}>
                                                        <option value="green" className={theme?.navbar + ' text-white'}>Green</option>
                                                        <option value="lgreen" className={theme?.navbar + ' text-white'}>Light Green</option>
                                                        <option value="teal" className={theme?.navbar + ' text-white'}>Teal</option>
                                                        <option value="maroon" className={theme?.navbar + ' text-white'}>Maroon</option>
                                                        <option value="indigo" className={theme?.navbar + ' text-white'}>Indigo</option>
                                                        <option value="blue" className={theme?.navbar + ' text-white'}>Blue</option>
                                                        <option value="charcoal" className={theme?.navbar + ' text-white'}>Charcoal</option>
                                                    </select> </div>
                                                <div className={'  flex items-center w-1/3  px-4'}>
                                                    <p className='cursor-pointer text-sbase font-bold  text-gray-700 mr-3'>Deactivated</p>
                                                    <input type='checkbox' name='Deactivate' className={'   shadow border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                                </div>
                                            </div>



                                        </div>

                                    </div>

                                </div>


                                <div className=' py-2 rounded flex justify-end items-center border px-6 '>
                                    <button className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"
                                        onClick={() => { setShowSummary(false); setShowAlert(false); }}
                                    > Cancel  </button>
                                    <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                                        onClick={() => { setShowSummary(false); setShowAlert(false); }}
                                    >  Save</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            }
            {/* Add Module User Popup===== */}
            {ShowModule && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true" >
                            <div className="absolute inset-0 bg-gray-500 opacity-50" ></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true" ></span>

                        <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full  ${showAlert ? 'animate-pulse  border border-red-300 ' : ''}`}
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >


                            <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative`}>
                                {/* <button
                                    className="absolute top-2 right-2 bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white px-1 border border-red-300 hover:border-transparent rounded"
                                    onClick={() => { setShowModule(false); setShowInnerModal(false) }}
                                >
                                    X
                                </button> */}
                                <svg className={'absolute top-2 right-2  cursor-pointer shadow  font-semibold  '}
                                    onClick={() => { setShowModule(false); setShowInnerModal(false) }}
                                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                                </svg>

                                {!showInnerModal ? (
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline" >  {showtype === moduleType.addModule ? 'Add Module' : 'Add User Type'}</h3>
                                        <div onMouseDown={(e) => e.stopPropagation()} className="my-3">

                                            <div className='w-full bg-gray-100 p-3  rounded-lg'>

                                                <p className={' leading-6 mt-3 text-xss  '} > {showtype === moduleType.addModule ? 'Module Name' : 'User Type'}</p>
                                                {/* <input type='text' placeholder='' className={'hidden'} /> */}
                                                <input name='userType' id='zxcv' autoFocus={true} type='text' onChange={(e) => setUserType(e.target.value)} placeholder='Enter Name' className={'rounded w-full bg-white px-2 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none '} />

                                                {showtype === moduleType.addModule && <>  <p className={' leading-6 mt-3 text-xss  '} >Select Parant Modules</p>
                                                    <select
                                                        // onChange={(e) => { setTemplateId(e.target.value); }} defaultValue={TemplateId}
                                                        className={'flex justify-between items-center   w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 outline-none '}>
                                                        <option value={null} className='py-2'>--Select Modules--</option>
                                                        {permissionsList.filter(x => isEmptyObject(x.ParentModule)).map((item, index) => (
                                                            <option value={item.usertypelabel} className='py-2'>{item.Name}</option>
                                                        ))}
                                                    </select></>}
                                            </div>

                                            <div className='py-2 text-right'>
                                                <button className="bg-transparent hover:bg-red-700 text-red-600 font-semibold hover:text-white py-1 px-4 border border-red-500 hover:border-transparent mr-3 rounded" onClick={() => { setShowModule(false); setShowInnerModal(false) }}>Cancel</button>
                                                <button className="bg-transparent hover:bg-green-800 text-green-700 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent mr-3 rounded" onClick={() => setShowInnerModal(true)}>Confirm</button>
                                            </div>


                                        </div>
                                    </div>
                                ) : (
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" >   Saving {showtype === moduleType.addModule ? 'Module' : 'User'}</h3>
                                        <div className=' my-3 w-full items-center bg-gray-100 p-3 my-2  rounded-lg' onMouseDown={(e) => e.stopPropagation()}>
                                            <p className='leading-6 text-sbase items-center text-center pb-3 font-semibold' >Enter your PIN</p>

                                            <div className="flex justify-center Items-center space-x-2">
                                                {Array.from({ length: 5 }, (_, index) => (
                                                    <input
                                                        key={index}
                                                        type="password"
                                                        name={`PIN-${index + 1}`}
                                                        placeholder="-"
                                                        maxLength={1}
                                                        autoFocus={index === 0}
                                                        onKeyUp={(e) => handlePinChange(e, index)}
                                                        className={theme.txt_color + `  rounded w-10 h-10 bg-white px-2 py-2 text-xl font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none text-center  ${showAlert ? 'animate-pulse  border border-red-300 ' : ''}`}
                                                        ref={inputRefs[index]}
                                                    />
                                                ))}
                                            </div>

                                            {showAlert && (
                                                <div className="buzz-alert text-red-600 text-sm text-center mt-3">
                                                    Wrong password! Please try again.
                                                </div>
                                            )}
                                        </div>
                                        {/* ---------------------- */}
                                        {/* <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                            Saving {showtype === moduleType.addModule ? 'Module' : 'User'}
                                        </h3> */}
                                        {/* 
                                        <div className='w-full bg-gray-100 p-3 my-2 rounded-lg'>
                                            <div className='flex items-center'>
                                                <p className='leading-6 text-sbase'>Enter your PIN</p>
                                            </div>

                                            <input type='password' name='PIN' placeholder='Enter PIN' autoFocus={true} maxLength={5} onChange={(e) => handleInputChange(e)} className={'rounded w-full bg-white px-2 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none '} />
                                          
                                        </div>
                                        {showAlert && (
                                            <div className="buzz-alert text-red-600 text-sm">
                                                Wrong password! Please try again.
                                            </div>
                                        )} */}

                                    </div>

                                )}









                            </div>

                        </div>

                    </div>
                </div>
            )}


        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(Permission)