import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';
import Swal from 'sweetalert2';
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action'
import { getCorrectDate, getPatientInformation, getUniqueObjectsByKey } from '../../../../common';
import PinModal from '../../../Components/PinModal';


export const AddAndrologyRequest = ({ coupleId, ApiCall_POST, ApiCall_GET, successFunction, artid, setOpenForm, ...otherProps }) => {
    const [ShowModal, setShowModal] = useState(false)

    const theme = useAppContext().themeDetails;
    var patient = getPatientInformation()
    var initObj = {
        coupleid: coupleId,
        patientPersonID: patient?.partnerCode,
        partnerPersonID: patient?.patientCode,
        clinicianuserid: patient?.ClinicianID,
        clinicianname: patient?.clinicianName,
        cliniclocationid: 1
    }
    const [formdata, setFormdata] = useState(initObj)
    const [activeStep, setActiveStep] = useState(true)
    const [clinicsList, setClinicsList] = useState(null)

    useEffect(() => {
        ApiCall_GET('UserManagment/GetClinicDetailsList/', true).then(function (payload) {
            setClinicsList(payload)
        });
    }, [])

    useEffect(() => {
     
    }, [formdata])


    const parentsAndChildren = {
        semenAnalysis: ['semenAnalysisOnly', 'semenAnalysisAntibodies', 'semenAnalysisDNAFrag', 'semenAnalysisDNAFragRepeat', 'semenAnalysisAntibodiesDNAFrag'],
        semenAnalysisARTSuitability: [],
        semenAnalysisARTSuitabilityAntibodies: [],
        spermFreeze: ['spermFreezeSDA'],
        semenAnalysisIUIPrep: ['numberOfFollicles', 'donorPreparation', 'donorCode'],
    };

    const childrenToParent = {
        semenAnalysisOnly: 'semenAnalysis',
        semenAnalysisAntibodies: 'semenAnalysis',
        semenAnalysisDNAFrag: 'semenAnalysis',
        semenAnalysisDNAFragRepeat: 'semenAnalysisDNAFrag',
        semenAnalysisAntibodiesDNAFrag: 'semenAnalysis',
        spermFreezeSDA: 'spermFreeze',
        numberOfFollicles: 'semenAnalysisIUIPrep',
        donorPreparation: 'semenAnalysisIUIPrep',
        donorCode: 'donorPreparation',
    };

    const setChildren = (parentName, value) => {
        const updates = {};


        // Deselect other parents and their children
        Object.keys(parentsAndChildren).forEach(parent => {
            if (parent !== parentName) {
                updates[parent] = '';
                parentsAndChildren[parent].forEach(child => {
                    updates[child] = '';
                   
                    if (parentsAndChildren[child]) {
                        parentsAndChildren[child].forEach(subChild => {
                            updates[subChild] = '';
                        });
                    }
                });
            }
        });

        // Select the specified parent and its children if the parent is being selected
        if (value) {

            updates[parentName] = true;
            parentsAndChildren[parentName].forEach(child => {
                updates[child] = '';
                if (parentsAndChildren[child]) {
                    parentsAndChildren[child].forEach(subChild => {
                        updates[subChild] = '';
                    });
                }
            });
        }
        setFormdata(prevFormData => ({
            ...prevFormData,
            ...updates,
        }));
    };

    const handleInputChange = (e) => {
        const { id, name, value, checked, type } = e.target;

        if (type === 'checkbox') {
            if (id == 'nonurgentRequest') {
                
                setFormdata(prevFormData => ({
                    ...prevFormData,
                    ['urgentRequest']: false,
                    [id]: checked ? true : false,
                }));
            }
            else if (id == 'urgentRequest') {
                setFormdata(prevFormData => ({
                    ...prevFormData,
                    ['nonurgentRequest']: false,
                    [id]: checked ? true : false,
                }));
            }
            else {
                setChildren(id, checked);
                setFormdata(prevFormData => ({
                    ...prevFormData,
                    [id]: checked ? true : false,
                }));
            }

            // setFormdata(prevFormData => ({
            //     ...prevFormData,
            //     [id]: value,
            // }));
        } else if (type === 'radio') {
            const parentName = childrenToParent[name];

            if (parentName) {
                const grandParentName = childrenToParent[parentName];
                if (grandParentName) {
                    setChildren(grandParentName, value);
                    setFormdata(prevFormData => ({
                        ...prevFormData,
                        [grandParentName]: checked ? true : false,
                        [parentName]: checked ? true : false,
                        [name]: checked ? true : false,
                    }));
                } else {
                    setChildren(parentName, value);
                    setFormdata(prevFormData => ({
                        ...prevFormData,
                        [parentName]: checked ? true : false,
                        [name]: checked ? true : false,
                    }));
                }
            } else {
                setFormdata(prevFormData => ({
                    ...prevFormData,
                    [name]: checked ? true : false,
                }));
            }
        } else {
            setFormdata(prevFormData => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const SaveData = () => {
        setShowModal(false)
        ApiCall_POST('Lab/SaveAndrologyDetails', formdata, true).then(function (payload) {
            setOpenForm(false)
            successFunction(artid)
            Swal.fire({
                icon: 'success',
                title: 'Andrology Request Saved!',
                showConfirmButton: false,
                timer: 2000
            });
        });

    };


    return (
        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                {/* <Draggable> */}
                <div className={`sm:my-8   w-2/3  filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className={`bg-white px-4 py-5 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                            onClick={() => setOpenForm(false)}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                        </svg>
                        <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" >New Andrology Request</h3>
                        {/* <h3 className="text-lg leading-6 font-semibold text-gray-900" id="modal-headline" > Saving Bloods  </h3> */}
                        <div className={'  rounded flex items-center '}>
                            <label onClick={() => { setActiveStep(true) }} className={'mr-4 font-bold cursor-pointer ' + (activeStep ? (theme.txt_color + ' border-b-[3px] border-b-amber-500') : 'text-gray-400  border-b-[3px]  border-b-gray-400')}>Andrology Request</label>
                            <label onClick={() => { setActiveStep(false) }} className={'ml-4 font-bold cursor-pointer ' + (activeStep ? 'text-gray-400 border-b-[3px] border-b-gray-400' : (theme.txt_color + ' text-white  border-b-[3px] border-b-amber-500'))}> Summary</label>
                        </div>
                        {activeStep ? < div className=' my-1 w-full flex bg-gray-100 p-3 my-2  rounded-lg' onMouseDown={(e) => e.stopPropagation()}>
                            <div className='w-1/2 p-2'> <div className='w-full  my-2'>
                                <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Requested Andrology Tests / Procedures</label>
                            </div>

                                <div className={'items-center'}>
                                    {/* parent 1 */}
                                    <div className={'flex items-center mt-2 ml-3 flex items-center cursor-pointer'}>
                                        <input type='checkbox' id="semenAnalysis" onChange={handleInputChange} checked={formdata.semenAnalysis} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysis"} />
                                        <label htmlFor="semenAnalysis" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Semen Analysis</label>
                                    </div>
                                    {/* child 1 */}
                                    <div className={'mt-2 ml-8 flex items-center cursor-pointer'}>
                                        <input type='radio' id="semenAnalysisOnly" onChange={handleInputChange} checked={formdata.semenAnalysisOnly} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysisOnly"} />
                                        <label htmlFor="semenAnalysisOnly" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Semen Analysis Only</label>
                                    </div>
                                    {/* child 2 */}
                                    <div className={'mt-2 ml-8 flex items-center cursor-pointer'}>
                                        <input type='radio' id="semenAnalysisAntibodies" onChange={handleInputChange} checked={formdata.semenAnalysisAntibodies} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysisAntibodies"} />
                                        <label htmlFor="semenAnalysisAntibodies" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Semen Analysis & Antibody Testing</label>
                                    </div>
                                    {/* child 3 */}
                                    <div className={'mt-2 ml-8 flex items-center cursor-pointer'}>
                                        <input type='radio' id="semenAnalysisDNAFrag" onChange={handleInputChange} checked={formdata.semenAnalysisDNAFrag} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysisDNAFrag"} />
                                        <label htmlFor="semenAnalysisDNAFrag" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Semen Analysis & Sperm DNA Fragmentation Test</label>
                                    </div>
                                    {/* sub-child of child 3 */}
                                    <div className={'mt-2 ml-8 pl-5 flex items-center cursor-pointer'}>
                                        <input type='radio' id="semenAnalysisDNAFragRepeat" onChange={handleInputChange} checked={formdata.semenAnalysisDNAFragRepeat} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysisDNAFragRepeat"} />
                                        <label htmlFor="semenAnalysisDNAFragRepeat" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Repeat DNA Frag Test within 4 weeks of original test</label>
                                    </div>
                                    {/* child 4 */}
                                    <div className={'mt-2 ml-8 flex items-center cursor-pointer'}>
                                        <input type='radio' id="semenAnalysisAntibodiesDNAFrag" onChange={handleInputChange} checked={formdata.semenAnalysisAntibodiesDNAFrag} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysisAntibodiesDNAFrag"} />
                                        <label htmlFor="semenAnalysisAntibodiesDNAFrag" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Semen Analysis & Antibody Testing & DNA Fragmentation Test</label>
                                    </div>
                                    {/* parent 2 */}
                                    <div className={'flex items-center mt-2 ml-3 flex items-center cursor-pointer'}>
                                        <input type='checkbox' id="semenAnalysisARTSuitability" onChange={handleInputChange} checked={formdata.semenAnalysisARTSuitability} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysisARTSuitability"} />
                                        <label htmlFor="semenAnalysisARTSuitability" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Semen Analysis & Suitability for ART</label>
                                    </div>
                                    {/* parent 3 */}
                                    <div className={'flex items-center mt-2 ml-3 flex items-center cursor-pointer'}>
                                        <input type='checkbox' id="semenAnalysisARTSuitabilityAntibodies" onChange={handleInputChange} checked={formdata.semenAnalysisARTSuitabilityAntibodies} className={theme.cheqColor + ' border focus:outline-none'} name={"semenAnalysisARTSuitabilityAntibodies"} />
                                        <label htmlFor="semenAnalysisARTSuitabilityAntibodies" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Semen Analysis & Suitability for ART + Antibody Testing</label>
                                    </div>
                                    {/* parent 4 */}
                                    <div className={'flex items-center mt-2 ml-3 flex items-center cursor-pointer'}>
                                        <input type='checkbox' id="spermFreeze" onChange={handleInputChange} checked={formdata.spermFreeze} className={theme.cheqColor + ' border focus:outline-none'} name={"spermFreeze"} />
                                        <label htmlFor="spermFreeze" className="ml-2 text-sm text-gray-800 dark:text-gray-600">Sperm Freeze</label>
                                    </div>
                                    {/* child 1 of parent 4 */}
                                    <div className={'mt-2 ml-8 flex items-center cursor-pointer'}>
                                        <input type='radio' id="spermFreezeSDA" onChange={handleInputChange} checked={formdata.spermFreezeSDA} className={theme.cheqColor + ' border focus:outline-none'} name={"spermFreezeSDA"} />
                                        <label htmlFor="spermFreezeSDA" className="ml-2 text-sm text-gray-800 dark:text-gray-600">SDA (Donor Sperm Freeze)</label>
                                    </div>
                                    {/* Parent 5 */}
                                    <div className="flex items-center mt-2 ml-3 cursor-pointer">
                                        <input type="checkbox" id="semenAnalysisIUIPrep" onChange={handleInputChange} checked={formdata.semenAnalysisIUIPrep} className={theme.cheqColor + " border focus:outline-none"} name="semenAnalysisIUIPrep" />
                                        <label htmlFor="semenAnalysisIUIPrep" className="ml-2 text-sm text-gray-800 dark:text-gray-600">UI Preparation for Insemination</label>
                                    </div>
                                    <div className="mt-2 ml-8 cursor-pointer">
                                        {/* Child 1 of Parent 5 */}
                                        <label htmlFor="numberOfFollicles" className="ml-2 text-sm text-gray-800 dark:text-gray-600 px-2">No. of Follicles</label>
                                        <input type="text" name="numberOfFollicles" onChange={handleInputChange} value={formdata.numberOfFollicles} disabled={!formdata.semenAnalysisIUIPrep} className={theme.cheqColor + " shadow border w-1/2 rounded-md py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none"} />
                                        {/* Child 2 of Parent 5 */}
                                        <div className="flex items-center mt-2 ml-3 cursor-pointer">
                                            <input type="radio" id="donorPreparation" onChange={handleInputChange} checked={formdata.donorPreparation} className={theme.cheqColor + " border focus:outline-none"} name="donorPreparation" />
                                            <label htmlFor="donorPreparation" className="ml-2 text-sm text-gray-800 dark:text-gray-600">UI Donor Preparation</label>
                                        </div>
                                        {/* Sub-child of Child 2 of Parent 5 */}
                                        <label htmlFor="donorCode" className="ml-2 mr-3 text-sm text-gray-800 dark:text-gray-600 px-2">Donor Code</label>
                                        <input type="text" name="donorCode" onChange={handleInputChange} value={formdata.donorCode} disabled={!formdata.donorPreparation} className={theme.cheqColor + " shadow border w-1/2 rounded-md py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none"} />
                                    </div>
                                </div>

                            </div>
                            <div className='w-1/2 p-2'>
                                <div className='w-full  my-2'>
                                    <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Scanning and Setup</label>
                                </div>
                                <div className='w-full p-2 shadow border rounnded bg-gray-50 rounded'>
                                    <div className='flex justify-start gap-2 items-start'>
                                        <div className='flex justify-start items-center '>
                                            <input type='checkbox' id='nonurgentRequest' checked={formdata?.nonurgentRequest ? true : false} name='requestType' onChange={handleInputChange} className={'   border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            <label className=' ml-2  cursor-pointer text-sm font-medium  text-gray-600' htmlFor='nonurgentRequest'>Non-urgent</label>
                                        </div>
                                        <div className='flex justify-start items-center ml-3'>
                                            <input type='checkbox' id='urgentRequest' checked={formdata?.urgentRequest ? true : false} name='requestType' onChange={handleInputChange} className={'   border rounded rounded-xl p-1 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                            <label className=' ml-2  cursor-pointer text-sm font-medium  text-gray-600' htmlFor='urgentRequest'>URGENT</label>
                                        </div>

                                    </div>
                                    <div className=' justify-start gap-2 items-start my-2'>
                                        <div className='w-full text-left my-2  justify-between'>
                                            <p className={"text-sm font-medium  "} >To be done by:</p>
                                            <input type='date' name='preferredBookingDate'
                                                onChange={(e) => handleInputChange(e)}
                                                value={formdata.preferredBookingDate}
                                                className={theme.cheqColor + ' shadow border w-full rounded rounded-md  py-1 px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '} />
                                        </div>
                                        <div className='w-full text-left    justify-between'>
                                            <p className={"text-sm font-medium  "} >Clinic Locations</p>
                                            <select
                                                name='cliniclocationid'
                                                onChange={(e) => handleInputChange(e)}
                                                value={formdata?.cliniclocationid}
                                                className={'w-full  flex border justify-between items-center w-25 justify-right rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none border-' + theme.tableHeadrTP}>
                                                {getUniqueObjectsByKey(clinicsList, 'ClinicName')?.filter(x => (x.brandCode == 'CFC' || !x.brandCode)).map((item, i) => (
                                                    <option key={i} value={item?.locationid} className='py-1'>{`(${item?.brandCode}) ${item?.chartinglabel}`}</option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>



                                    <div className=' w-full text-left  justify-between'>
                                        <p className={"text-sm font-medium  "} >Comments / additional instructions for the Patient</p>
                                        <textarea rows="3" style={{ lineHeight: "1" }} type='text'
                                            onChange={(e) => { handleInputChange(e) }} name='txtComments' defaultValue={formdata?.txtComments}
                                            className=" p-2.5 w-full text-sm px-1 py-1 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" />
                                    </div>



                                </div>


                            </div>
                        </div>
                            :
                            <div className='w-full text-left  rounded  rounded-lg  mb-2 p-1 '>
                                <div className='w-full shadow  pb-2'>
                                    <div className={theme.tHedUpdte + ' text-white text-left p-1  rounded-t'}>
                                        <p className='text-sbase font-medium'>Requested Andrology Tests / Procedures</p>
                                    </div>

                                    {formdata.semenAnalysis &&
                                        (<>
                                            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Semen Analysis</p>
                                            </div>
                                            {formdata.semenAnalysisOnly &&
                                                <div className={' text-left p-1 py-2 ml-6 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                    <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Semen Analysis Only</p>
                                                </div>
                                            }
                                            {formdata.semenAnalysisAntibodies &&
                                                <div className={' text-left p-1 py-2 ml-6 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                    <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Semen Analysis & Antibody Testing</p>
                                                </div>
                                            }
                                            {formdata.semenAnalysisDNAFrag &&
                                                <div className={' text-left p-1 py-2 ml-6 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                    <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Semen Analysis & Sperm DNA Fragmentation Test</p>
                                                </div>
                                            }
                                            {
                                                formdata.semenAnalysisDNAFragRepeat &&
                                                <div className={' text-left p-1 py-2 ml-10 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                    <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Repeat DNA Frag Test within 4 weeks of original test</p>
                                                </div>
                                            }
                                            {
                                                formdata.semenAnalysisAntibodiesDNAFrag &&
                                                <div className={' text-left p-1 py-2 ml-6 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                                    <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Semen Analysis & Antibody Testing & DNA Fragmentation Test</p>
                                                </div>
                                            }

                                        </>
                                        )}


                                    {formdata.semenAnalysisARTSuitability &&
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Semen Analysis & Suitability for ART</p>
                                        </div>
                                    }

                                    {formdata.semenAnalysisARTSuitabilityAntibodies &&
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Semen Analysis & Suitability for ART + Antibody Testing</p>
                                        </div>
                                    }

                                    {formdata.spermFreeze &&
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Sperm Freeze</p>
                                        </div>
                                    }
                                    {formdata.spermFreezeSDA &&
                                        <div className={' text-left p-1 py-2 ml-6 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>SDA (Donor Sperm Freeze)</p>
                                        </div>
                                    }

                                    {formdata.semenAnalysisIUIPrep &&
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>UI Preparation for Insemination</p>
                                        </div>
                                    }
                                    {formdata.numberOfFollicles &&
                                        <div className={' text-left p-1 py-2 ml-10 flex items-center border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>No. of Follicles : </p>
                                            <p className='cursor-pointer text-sbase   text ml-2 -gray-700'>{formdata.numberOfFollicles}</p>
                                        </div>
                                    }

                                    {formdata.donorPreparation &&
                                        <div className={' text-left p-1 py-2 ml-10 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>UI Donor Preparation</p>
                                        </div>
                                    }
                                    {formdata.donorCode &&
                                        <div className={' text-left p-1 py-2 ml-14 flex items-center border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Donor Code : </p>
                                            <p className='cursor-pointer text-sbase   text ml-2 -gray-700'>{formdata.donorCode}</p>
                                        </div>
                                    }


                                </div>

                                <div className='w-full shadow mt-3 pb-2'>
                                    <div className={theme.tHedUpdte + ' text-white text-left p-1  rounded-t'}>
                                        <p className='text-sbase font-medium'>Scanning & Setup</p>
                                    </div>
                                    {(formdata.urgentRequest || formdata?.nonurgentRequest) &&
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Request Type : </p>
                                            <p className='cursor-pointer text-sbase ml-2 text-gray-700'>{formdata?.urgentRequest ? <span className='text-red-500 font-bold'>Urgent</span> : formdata?.nonurgentRequest ? <span className='text-green-500 font-bold'>Non-Urgent</span> : ''}</p>
                                        </div>
                                    }
                                    {formdata.preferredBookingDate &&
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>To be done by : </p>
                                            <p className='cursor-pointer text-sbase ml-2 text-gray-700'>{getCorrectDate(formdata.preferredBookingDate)}</p>
                                        </div>
                                    }
                                    {formdata.cliniclocationid &&
                                        <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Clinic Location: </p>
                                            <p className='cursor-pointer text-sbase ml-2 text-gray-700'>{clinicsList?.filter(x => x.locationid == formdata.cliniclocationid)[0]?.ClinicName || ""}</p>
                                        </div>
                                    }
                                    {formdata?.txtComments &&
                                        <div className={' text-left p-1 py-2 mx-2   border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Comments : </p>
                                            <textarea rows="3" style={{ lineHeight: "1" }} type='text' disabled={true}
                                                onChange={(e) => { handleInputChange(e) }} name='txtComments' defaultValue={formdata?.txtComments}
                                                className=" p-2.5 w-full text-sm px-1 mt-2 py-1 border rounded accent-green-700  text-gray-700 focus:border-teal-500 focus:outline-none" />
                                        </div>
                                    }
                                </div>

                            </div>
                        }

                        <div className=' py-2 rounded flex justify-end items-center border px-6'>

                            <button onClick={() => { setActiveStep(true); setFormdata({}); setOpenForm(false); }} className="bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded" > Cancel  </button>
                            {activeStep && activeStep === true ?
                                <button className="bg-transparent hover:bg-green-800 text-green-700 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent mr-3 rounded"
                                    onClick={() => { setActiveStep(false) }}
                                > Next </button>
                                :
                                <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"
                                    onClick={() => { setShowModal(true) }}
                                >  Confirm</button>
                            }

                        </div>

                        {ShowModal &&
                            <PinModal title="Saving Andrology Request" successFunction={SaveData} setStateFunction={setShowModal} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(AddAndrologyRequest)