import React, { useEffect, useState } from 'react';
import TreatmentPlan from './Views/Pages/Magnet/TreatmentPlan/TreatmentPlan';
import Home from './Views/Pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import CoupleDetails from './Views/Pages/CoupleDetails';
import Navbar from './Views/Components/NavBar/Navbar';
import MainSideBarNew from './Views/Components/SideBar/MainSideBarNew';
import Magnet from './Views/Pages/Magnet/Magnet';
import AddReferenceItems from './Views/Pages/Admin/AddReferenceItems';
import AddArt from './Views/Pages/Magnet/AddArt/AddArt';
import Loader from '../src/Views/Components/Loader/loader'
import axios from "axios";
import UpdateTreatmentPlan from './Views/Pages/Magnet/TreatmentPlan/UpdateTreatmentPlan';
import CreateTemplate from './Views/Pages/Magnet/TreatmentPlan/CreateTemplate';
import RunningSheet from './Views/Pages/Nurse/NurseMagnet/RunningSheet/RunningSheet'
import { Helmet } from 'react-helmet';
import BookingForm from './Views/Pages/Magnet/BookingForm/BookingForm';
import UpdateBookingForm from './Views/Pages/Magnet/BookingForm/UpdateBookingForm';
import Scans from './Views/Pages/Magnet/Scans/Scans';
import BloodResult from './Views/Pages/Magnet/BloodResults/BloodResult';
import Ohss from './Views/Pages/Magnet/OHSS/Ohss';
import DoctorSheet from './Views/Pages/Magnet/Doctor/DoctorSheet/DoctorSheet';
import Login from './Views/Pages/Login';
import Embrology from './Views/Pages/Lab/Embryology/Embrology';
import Permission from './Views/Pages/Admin/Permission';
import { SessionExpiryTime, getPatientInformation, getUserInformation } from './common';
import Landing from './Landing';
import { useAppContext } from './AppContextProvider ';
import { useDispatch, useSelector } from 'react-redux';
import PinModal from './Views/Components/PinModal';
import { getGlobalIsLoading, getSession, setSession } from './session';
import StimPlan from './Views/Pages/Nurse/NurseMagnet/StimPlan/StimPlan';
import EditCouple, { EditUser } from './Views/Pages/Couple/EditCouple';
import CoupleInfo from './Views/Pages/Couple/CoupleInfo';
import CreateCouple from './Views/Pages/Couple/CreateCouple';
import CreatePerson from './Views/Pages/Couple/CreatePerson';
import Anzard from './Views/Pages/Nurse/NurseMagnet/RunningSheet/TaskItem/SubTaskDetails/Anzard';
import StimPrint from './Views/Pages/Nurse/NurseMagnet/StimPlan/StimPrint';
import ClinicalHistory from './Views/Pages/Magnet/ClinicalHistory/ClinicalHistory';
import Andrology from './Views/Pages/Magnet/Andrology/Andrology';
import Calendar from './Views/Pages/Calendar/Calendar';
import  WhiteBoard  from './Views/Pages/WhiteBoard/WhiteBoard';
import { ApiCall_GET } from './Redux/Generic/action';
// import PatientDetails from './Views/Pages/Patient/PatientDetails';
export default function App(props) {
  const currentPath = window.location.pathname;

  if (currentPath == '/addreferenceitems') {
    setSession('patient', {});
  }
  const { themeDetails, setThemeDetails } = useAppContext();
  const [data, setData] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [isLoading, setIsLoading] = useState(getGlobalIsLoading());

  const { selectedCoupleDetails, setCoupletData } = useAppContext()
  var Themes = require('./Theme.json');
  const getThemeColor = (clr) => {
    let selectedTheme = Themes.filter(x => x.themeColorId === clr)[0]
    return selectedTheme || Themes[0]
  }


  const removeHightedClasses = () => {
    const elementsWithYellowBg = document.querySelectorAll('.bg-yellow-200');

    elementsWithYellowBg.forEach((element) => {
      const e = { target: element };
      if (e.target.type === 'checkbox' || e.target.type === 'radio') {
        const parentDiv = e.target.closest('div');
        if (parentDiv) {
          parentDiv.classList.remove('bg-yellow-200');
        }
      } else if (e.target.type === 'select-one') {

      } else {
        e.target.classList.remove('bg-yellow-200');
      }
    });
  }

  useEffect(() => {
    if (currentPath === '/home' || currentPath === '/createPerson' || currentPath === '/addreferenceitems' || currentPath === '/') {
      setCoupletData(null);
      setSession('patient', null);
      getPatientInformation(null)
    } else {
      setCoupletData(getPatientInformation())
    }

    let color = getUserInformation()?.ColorId
    // checkIsLogin()

    axios.interceptors.request.use((config) => {
      setIsLoading(getGlobalIsLoading())
      return config;
    }, (error) => {
      return Promise.reject(error);
    })
    axios.interceptors.response.use((response) => {
      setIsLoading(false)
      if (response.config.method === 'post') {
       
        removeHightedClasses()
      }
      //  else if (response.config.method === 'get') {
      //   
      // }
      return response;
    }, (error) => {
      return Promise.reject(error);
    })
    setThemeDetails(getThemeColor(Number(color)))
  }, [currentPath])



  const dispatch = useDispatch();
  var isSessionTimeout = getSession('isSessionTimeout');
  const [isSessionExpired, setisSessionExpired] = useState(isSessionTimeout)
  useEffect(() => {
    if (!isSessionTimeout) {
      startTimer();
    }
    const handleUserActivity = () => {
      if (!getSession('isSessionTimeout')) {
        resetTimer()
      }
    };
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, [dispatch]);
  // useEffect(() => {
  //   let timer;
  //   if (isActive) {
  //     timer = setInterval(() => {
  //       setSeconds(prevSeconds => prevSeconds + 1);
  //       if (seconds === SessionExpiryTime) {
  //         endTimer();
  //       }
  //     }, 1000);
  //   }
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [isActive, seconds]);
  const startTimer = () => {
    setIsActive(true);
    setSession('isSessionTimeout', false)
    setisSessionExpired(false)
    setSeconds(0);
  };
  const endTimer = () => {
    setIsActive(false);
    setSession('isSessionTimeout', true)
    setisSessionExpired(true)
    setSeconds(0);
  };
  const resetTimer = () => {
    setIsActive(true);
    setisSessionExpired(false)
    setSession('isSessionTimeout', false)
    setSeconds(0);
  };
  const [isLogin, setIsLogin] = useState(currentPath === '/login' ? true : false)
  // Function to generate dynamic title based on the current route
  const generateTitle = () => {
    switch (currentPath) {
      case '/':
        return 'CHAART - Home';
      case '/home':
        return 'CHAART - Home';
      case '/coupledetails':
        return 'Couple Details';
      case '/addreferenceitems':
        return 'Add Reference Items';
      case '/treatmentplan':
        return 'Treatment Plan';
      case '/updateTreatmentPlan':
        return 'Update Treatment Plan';
      case '/addArt':
        return 'Add Art';
      case '/magnet':
        return 'Magnet';
      case '/runningSheet':
        return 'Running Sheet';
      case '/scans':
        return 'Scans';
      case '/bloodResult':
        return 'Blood';
      case '/doctorSheet':
        return 'Doctor Sheet';
      case '/login':
        return 'Login';
      case '/permission':
        return 'Permission';
      case '/landing':
        return 'Landing';
      case '/Embrology':
        return 'embrology';
      case '/editCouple':
        return 'editCouple';
      case '/coupleInfo':
        return 'coupleInfo';
      case '/createCouple':
        return 'CreateCouple';
      case '/createPerson':
        return 'CreatePerson';
      case '/calendar':
        return 'Calendar';
      case '/whiteBoard':
        return 'whiteBoard';
      default:
        return 'CHAART';
    }
  }

  return (
    <BrowserRouter>
      <Helmet>
        <title>{generateTitle()} - [ Beta ]</title>
      </Helmet>
      <div className="pageBackground h-screen">
        {!isLogin && <Navbar data={data} setData={setData} />}
        {/* {isSessionExpired &&} */}
        <div className="flex height">
          {!isLogin && <MainSideBarNew sendData={setData} cplData={data} />}
          <Loader visible={isLoading} />
          <div className="App flex justify-items-center items-center flex-col w-full" id="App">
            {isSessionExpired && <PinModal title="Timeout due to Inactivity" subtitle="Enter your PIN to continue" successFunction={startTimer} CloseBlocked={true} />}
            <Routes>
              <Route path="/" element={<Home setIsLogin={setIsLogin} />} />
              <Route path="/home" element={<Home setIsLogin={setIsLogin} />} />
              {/* <Route path="search" element={<SearchUsers />} />
              <Route path="recent" element={<RecentPatients />} /> */}
              <Route path="coupledetails" element={<CoupleDetails sendData={setData} />} />
              <Route path="magnet" element={<Magnet />} />
              <Route path="editUser" element={<EditUser />} />
              <Route path="addreferenceitems" element={<AddReferenceItems />} />
              <Route path="treatmentplan" element={<TreatmentPlan />} />
              <Route path="updateTreatmentPlan" element={<UpdateTreatmentPlan />} />
              <Route path="addArt" element={<AddArt />} />
              <Route path="runningSheet" element={<RunningSheet />} />
              <Route path="bookingform" element={<BookingForm />} />
              <Route path="updateBookingForm" element={<UpdateBookingForm />} />
              <Route path="scans" element={<Scans />} />
              <Route path="bloodResult" element={<BloodResult />} />
              <Route path="ohss" element={<Ohss />} />
              <Route path="doctorSheet" element={<DoctorSheet />} />
              <Route path="login" element={<Login setIsLogin={setIsLogin} sendData={setData} />} />
              <Route path="permission" element={<Permission />} />
              <Route path="landing" element={<Landing sendData={setData} />} />
              <Route path="createTemplate" element={<CreateTemplate sendData={setData} />} />
              <Route path="embrology" element={<Embrology />} />
              <Route path="stimPlan" element={<StimPlan />} />
              <Route path="stimPrint" element={<StimPrint />} />
              <Route path="editCouple" element={<EditCouple />} />
              <Route path="coupleInfo" element={<CoupleInfo />} />
              <Route path="createCouple" element={<CreateCouple />} />
              <Route path="createPerson" element={<CreatePerson />} />
              <Route path="anzard" element={<Anzard />} />
              <Route path="clinicalHistory" element={<ClinicalHistory />} />
              <Route path="andrology" element={<Andrology />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="whiteBoard" element={<WhiteBoard />} />


            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}