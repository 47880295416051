import * as React from 'react';
import logo from '../../Assets/images/CFC_CHA_Logo_2018.svg';
import chaartlogo from '../../Assets/images/chaartlogo.png';


import { useEffect } from 'react';
import { useAppContext } from '../../AppContextProvider ';
import { getPatientInformation } from '../../common';

export default function Home({ setIsLogin }) {
    const { selectedCoupleDetails, setCoupletData } = useAppContext()
    useEffect(() => {
        setIsLogin(false);
        setCoupletData(getPatientInformation())

    }, [])


    return (
        <div className='flex justify-center items-center h-2/3'>

            <img src={chaartlogo} width="584" className="" />
            {/* <img src={logo} className="logoCFC" alt="img" /> */}
            {/* 
            <h3 className="text-2xl ">Welcome to  CHAART</h3> */}

        </div>
    );
}
