import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../Assets/images/white.png';
import clinicIcon from '../../../../Assets/images/clinicIcon.png';
import { formatTime, getCorrectDate, getCycleTypeColor, getTextColor, getUserInformation } from '../../../../common';
import moment from 'moment'
import { getSession } from '../../../../session';

export const BloodPrint = ({ theme, bloodList, artid }) => {
    const user = getUserInformation()
    const [couple, setCouple] = useState(null)
    useEffect(() => {
        setCouple(getSession('patient'))

    }, [])

    return (
        <div id="divPrint" className="absolute flex min-h-screen w-full  p-2 delay-900 duration-700  ">
            {/* Header */}
            <div className="relative w-full   bg-white p-3">
                <div className={` ${theme.navbar} ` + 'flex justify-between   mb-2  p-2'} >

                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='100px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className=' text-white  text-sm ml-1'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                    </div>

                </div>
                <main className=''>
                    <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>Blood Results</label>
                    {/* patient info */}
                    {/* <div className='flex mt-2'>
                        <div className='w-full shadow mr-1 pb-2 mb-2'>
                            <div className={theme.tHedUpdte + ' text-white text-left p-1  rounded-t'}>
                                <p className='text-sm font-medium'>Patient / Couple Details</p>
                            </div>
                            <div className=' text-left  mx-2 flex   border-b-[1px]  border-b-gray-200 py-1   '>
                                <p className='cursor-pointer text-sbase font-medium w-44 text-gray-700'>Patient </p>
                                <div className='flex justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500 w-1/2 '}>{couple?.patientFullName}                                    </p>
                                    <p className={'text-sm text-gray-500 w-1/2 '}><b>DOB: </b> {couple?.patientDOB}                                    </p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex   border-b-[1px]  border-b-gray-200 py-1   '>
                                <p className='cursor-pointer text-sbase font-medium w-44 text-gray-700'>Partner</p>
                                <div className='flex justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500 w-1/2 '}>{couple?.partnerFullName}                                    </p>
                                    <p className={'text-sm text-gray-500  w-1/2'}><b>DOB: </b>  {couple?.partnerDOB}                                    </p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex    pt-1    '>
                                <p className='cursor-pointer text-sbase font-medium w-44 text-gray-700'>Clinician</p>
                                <div className='flex justify-between items-center  w-full'>
                                    <p className={'text-sm text-gray-500 w-1/2'}> {couple?.clinicianName}                                    </p>
                                    <p className={'text-sbase text-gray-500 w-1/2 '}><b>Couple Code: </b> {couple?.coupleCode}                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                        <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>


                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Patient </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.patientFullName}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b>  {couple?.patientDOB}
                                    </p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Partner
                                </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.partnerFullName}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b>  {couple?.partnerDOB}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex items-center  py-1    w-1/2 '>
                                <p className=' text-sm font-medium   text-gray-700'>Clinician</p>
                                <p className={'pl-2 text-sm text-gray-500  '}> {couple?.clinicianName}</p>

                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className='cursor-pointer text-sbase w-28 font-medium   text-gray-700'>Couple Code
                                </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.coupleCode}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>{'ART' + artid}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>Blood Reports</label>
                    <div className={'   w-full mt-3'}>

                        {bloodList?.map((item) => (
                            <div className='w-full mb-2 border '>
                                <div className={((theme.bg_Light + " " + theme.txt_color + '  font-medium')) + ' flex rounded-t text-xss text-left p-1'}>
                                    <label className='w-4/5'>
                                        Blood Scan {(item.testdate) ? 'on ' + getCorrectDate(item.testdate) : ''}
                                    </label>
                                    <label className='w-2/5 text-right'>
                                        {item.addedbyuser ? 'by : ' + item.addedbyuser : ''}
                                    </label>
                                </div>
                                <div className='bg-white rounded  flex flex-wrap p items-center w-full '>
                                    <div className='w-full flex   '>
                                        <div className='w-[40%] flex   border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-16 '>Day</label>
                                            <label className='text-xss text-gray-500  text-center '>{item?.testdayofcycle} </label>
                                        </div>
                                        <div className='w-[30%] flex  border   py-0.5'>
                                            <label className='text-xss text-black  px-2 w-16 '>Test Date</label>
                                            <label className='text-xss text-gray-500  px-2  text-center '>{getCorrectDate(item.testdate)} </label>
                                        </div>
                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-24 '>E2 pmol/L</label>
                                            <label className='text-xss text-gray-500   px-2 rounded-md '>{item.e2_dose} </label>
                                        </div>


                                    </div>
                                    <div className='w-full flex   '>
                                        {/* <div className='w-[20%] flex  border   py-0.5'>
                                            <label className='text-xss text-black  px-2 w-16 '>Test Date</label>
                                            <label className='text-xss text-gray-500   text-center '>{getCorrectDate(item.testdate)} </label>
                                        </div> */}
                                        <div className='w-[40%] flex     border   py-0.5'>
                                            <label className='text-xss text-black  px-2 w-16 '>Clinic</label>
                                            <label className='text-xss text-gray-500      '> {item?.ClinicName}</label>
                                        </div>

                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2   w-24 '>P4 nmol/L</label>
                                            <label className='text-xss text-gray-500 text-center  rounded-md px-2 '>{item.p4_dose} </label>
                                        </div>
                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-24 '>LH IU/L</label>
                                            <label className='text-xss text-gray-500 text-center  px-2'>{item.lh_dose} </label>
                                        </div>
                                    </div>
                                    <div className='w-full flex   '>
                                        <div className='w-[40%] flex     border   py-0.5'>
                                            <label className='text-xss text-black   px-2 '>Procedure Location</label>
                                            <label className='text-xss text-gray-500     '> {item?.HospitalName}</label>
                                        </div>


                                        <div className='w-[30%] flex  border   py-0.5'>
                                            <label className='text-xss text-black px-2 w-24 '>FSH IU/L</label>
                                            <label className='text-xss text-gray-500  px-2 rounded-md '>{item.fsh_dose} </label>

                                        </div>

                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-24 '>HCG IU/L</label>
                                            <label className='text-xss text-gray-500 rounded-md  px-2 text-center '>{item.hcg_dose} </label>

                                        </div>
                                    </div>


                                    <div className='w-full flex   p-2'>
                                        <label className='text-xss text-black  '>Comments</label>
                                        <label className='text-xss text-gray-500   rounded-md px-2 '>{item.comments ? item.comments : " No Comments....."} </label>
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </main >

            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BloodPrint)