export function getData(innerItem, item) {
    var returnVal = '';

    if (item && (item.proctypeid === 1)) {

        if (innerItem.hylase) {
            returnVal = innerItem.egghylasedisplay;
            if (innerItem.injection && innerItem.splitivf <= 0) {
                returnVal += ' (Injected)'
            }
        }

        if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
        if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
        if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';

    }
    if (item && (item.proctypeid === 2)) {
        returnVal = '';

        if (innerItem.syngamy) returnVal += 'Syngamy ';
        if (innerItem.abnormal) returnVal += 'Abnormal ';
        if (innerItem.degenerate) returnVal += 'Degenerate ';
        if (innerItem.gv) returnVal += 'GV ';
        if (innerItem.m1) returnVal += 'M1 ';
        if (innerItem.earlycleavage) returnVal += 'earlycleavage ';
        if (innerItem.ez) returnVal += 'ez ';

        if (returnVal == '') {

            if (innerItem.fragmented) { innerItem.pb = "Fragmented" }

            if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) innerItem.pb += ' <span className="text-xs">[DISC]</span>';
            if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) innerItem.pb += ' <span className="text-xs">[TRAN]</span>';
            if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) innerItem.pb += ' <span className="text-xs">[FZ]</span>';
            returnVal += '<p className="w-1/2 text-lg font-medium mr-3">' + (innerItem.pn || '-') + '</p>';
            returnVal += '<p className="w-1/2 text-lg font-medium">' + (innerItem.pb || '-') + '</p>'
        }
        else {
            if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
            if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
            if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';
        }


    }
    if (item && (item.proctypeid === 3 || item.proctypeid === 4 || item.proctypeid === 5)) {
        returnVal = '';
        if (innerItem.evenness) returnVal += 'u ';
        if (innerItem.cleaving) returnVal += 'cl ';
        if (innerItem.cell) returnVal += innerItem.cell + 'C<sup>' + innerItem.fragmentation + '</sup> ';
        if (innerItem.compacting) returnVal = 'Comp ';
        if (innerItem.add1pn) returnVal = '1PN ';
        if (innerItem.add2pn) returnVal = '2PN ';
        if (innerItem.morula) { returnVal = 'Morula '; }
        if (innerItem.cavmorula) returnVal = 'CavMorula ';
        if (innerItem.cavblast) returnVal = 'Earlyblast ';
        if (innerItem.cavblast2) returnVal = '2 Earlyblast ';
        if (innerItem.blast) returnVal = 'BLAST (' + innerItem.blastcodedisplay + ') ';
        if (innerItem.expblast) returnVal = 'exBLAST(' + innerItem.expblastcodedisplay + ') ';
        if (innerItem.hatchblast) returnVal = 'HatchBLAST (' + innerItem.hatchblastcodedisplay + ') ';
        if (innerItem.hatchedblast) returnVal = 'HatchedBLAST (' + innerItem.hatchedblastcodedisplay + ') ';
        if (innerItem.abnormal) returnVal = 'Abnormal ';
        if (innerItem.degenerate) returnVal = 'Degenerate ';
        if (innerItem.vacuoles) returnVal += '(vac) ';
        if (innerItem.granular) returnVal += '(gr) ';
        if (innerItem.binucleate) returnVal = 'BN/MN ';


        if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
        if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
        if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';

        returnVal = returnVal.trim();
    }
    if (item && (item.proctypeid === 6)) {

        returnVal = innerItem.eggoutcomedisplay;

        if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
        if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
        if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';

    }
    if (item && (item.proctypeid === 7)) {
        returnVal = innerItem.eggpgddisplay;
    
        if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
        if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
        if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';

    }
    if (item && (item.proctypeid === 8)) {
        returnVal = innerItem.oocytefreezedisplay;
    
        if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
        if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
        if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';

    }
    if (item && (item.proctypeid === 12)) {
        returnVal = innerItem.oocytedonordisplay;
    
        if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
        if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
        if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';

    }
    if (item && (item.proctypeid === 13)) {
        returnVal = innerItem.InsemSplitType;
    
        if (parseInt(innerItem.DisguardOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[DISC]</span>';
        if (parseInt(innerItem.TransfferdOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[TRAN]</span>';
        if (parseInt(innerItem.FreezedOn) == parseInt(innerItem.Expr2)) returnVal += ' <span className="text-xs">[FZ]</span>';

    }



    return returnVal;
}

export function getBackgroundColor(innerItem) {
    //     if completedisguard = "True" then
    //     if editable = "1" then
    //     thefontdisplay = "gentxtgreybold"
    //     else
    //     thefontdisplay = "gentxtblackbold"
    //     end if
    //     tr_data = "tr-data-discard"
    // elseif complettransferd = "True" then
    //     thefontdisplay = "gentxtblackbold"
    //     tr_data = "tr-data-transferd"
    // elseif completfreezed = "True" then
    //     thefontdisplay = "gentxtblackbold"
    //     tr_data = "tr-data-freezed"
    // else
    //     thefontdisplay = "gentxtblackbold"
    //     tr_data = "tr-data"
    // end if

    let thefontdisplay, tr_data;



    if (innerItem.Disguard) {
        if (innerItem.editable == "1") {
            thefontdisplay = "gentxtgreybold";
        } else {
            thefontdisplay = "gentxtblackbold";
        }
        tr_data = "bg-rose-200";
    } else if (innerItem.Transferd) {
        thefontdisplay = "gentxtblackbold";
        tr_data = "bg-[#ddf2cc]";
    } else if (innerItem.Freezed) {
        thefontdisplay = "gentxtblackbold";
        tr_data = "bg-[#C2EBFA]";
    } else {
        thefontdisplay = "gentxtblackbold";
        tr_data = "tr-data";
    }

    return tr_data;
}