import { NumberExtractor } from "../../../../../common";

export const getMedicationType = (drugId) => {
  if (typeof drugId == 'string') {
    drugId = NumberExtractor(drugId)
  }
  switch (drugId) {

    case 337: return 12108161; //Provera
    // case 337: return 12108161; //Provera
    case 327: return 103; //Endometrin
    case 322: return 4450159; //Utrogestan
    // case 327: return 103; //Endometrin
    case 313: return 12108166; //Oripro Pessaries
    case 310: return 40; //Crinone
    // case 310: return 40; //Crinone
    case 304: return 31; //Gonal F
    case 292: return 32; //Puregon
    case 290: return 31; //Gonal F
    case 286: return 4450158; //Decapeptyl
    case 281: return 38; //Lucrin
    case 278: return 35; //Synarel
    // case 278: return 35; //Synarel
    case 257: return 4272156; //Prednisone
    // case 257: return 4272156; //Prednisone
    case 248: return 36; //Pregnyl
    // case 248: return 36; //Pregnyl
    // case 248: return 36; //Pregnyl
    case 245: return 33; //Ovidrel
    case 176: return 33; //Ovidrel
    case 330: return 33; //Ovidrel

    case 241: return 12108165; //Ovaleap
    case 236: return 12108159; //Estrogen Tablet
    // case 236: return 12108159; //Estrogen Tablet
    // case 236: return 12108162; //Estrogen Patch
    // case 236: return 12108162; //Estrogen Patch
    case 229: return 4272155; //Aspirin
    // case 229: return 4272155; //Aspirin
    case 223: return 11864158; //Clexane
    case 220: return 39; //Luveris
    case 217: return 3456155; //Letrozole
    case 214: return 42; //Clomid
    case 210: return 4450158; //Decapeptyl
    case 207: return 38; //Lucrin
    case 204: return 35; //Synarel
    case 201: return 37; //Orgalutran 
    case 200: return 34; //Cetrotide 
    case 195: return 84; //Menopur 
    // case 195: return 84; //Menopur 
    case 189: return 67; //Elonva
    // case 189: return 67; //Elonva
    // case 189: return 67; //Elonva
    case 186: return 4450160; //Bemfola
    // case 186: return 4450160; //Bemfola
    case 183: return 4450162; //Rekovelle
    // case 183: return 4450162; //Rekovelle
    case 180: return 24156; //Pergoveris
    case 177: return 32; //Puregon
    case 172: return 31; //Gonal F

    case 212: return 12108167;
    case 251: return 12108168;
    case 254: return 12108169;
    case 260: return 12108170;
    case 263: return 12108171;
    case 266: return 12108172;
    case 269: return 12108173;
    case 276: return 12108174;
    case 280: return 12108175;
    case 294: return 12108176;
    case 295: return 12108177;
    case 296: return 12108178;
    case 303: return 12108179;
    case 305: return 12108180;
    case 306: return 12108181;
    case 339: return 12108182;
    case 368: return 12108183;
    case 371: return 12108184;
    case 389: return 12108185;
    case 390: return 12108186;
    case 391: return 12108187;


    default:
      return 0; // default case if none of the above matches
  }
};


export function getMedicationTypeNew(value) {
  if (typeof value == 'string') {
    value = NumberExtractor(value)
  }
  switch (value) {
    case 12108161: return 337; // Provera
    // case 33: return 330; // Ovidrel
    case 33: return  176; // Ovidrel
    case 103: return 327; // Endometrin
    case 4450159: return 322; // Utrogestan
    case 12108166: return 313; // Oripro Pessaries
    case 40: return 310; // Crinone
    case 31:
      // if (someCondition) return 304; // Gonal F
      // return 290; // Gonal F
      return 172; // Gonal F
    case 32: return 292; // Puregon
    case 4450158:
      // if (someCondition) return 210; // Decapeptyl
      return 286; // Decapeptyl
    case 38:
      // if (someCondition) return 207; // Lucrin
      return 281; // Lucrin
    case 35:
      // if (someCondition) return 204; // Synarel
      return 278; // Synarel
    case 4272156: return 257; // Prednisone
    case 36: return 248; // Pregnyl
    case 12108165: return 241; // Ovaleap
    case 12108159: return 236; // Estrogen Tablet
    case 4272155: return 229; // Aspirin
    case 11864158: return 223; // Clexane
    case 39: return 220; // Luveris
    case 3456155: return 217; // Letrozole
    case 42: return 214; // Clomid
    case 37: return 201; // Orgalutran
    case 34: return 200; // Cetrotide
    case 84: return 195; // Menopur
    case 67: return 189; // Elonva
    case 4450160: return 186; // Bemfola
    case 4450162: return 183; // Rekovelle
    case 24156: return 180; // Pergoveris
    case 12108167: return 212;
    case 12108168: return 251;
    case 12108169: return 254;
    case 12108170: return 260;
    case 12108171: return 263;
    case 12108172: return 266;
    case 12108173: return 269;
    case 12108174: return 276;
    case 12108175: return 280;
    case 12108176: return 294;
    case 12108177: return 295;
    case 12108178: return 296;
    case 12108179: return 303;
    case 12108180: return 305;
    case 12108181: return 306;
    case 12108182: return 339;
    case 12108183: return 368;
    case 12108184: return 371; // Cyclogest
    case 12108185: return 389;
    case 12108186: return 390;
    case 12108187: return 391;


    default:
      return null; // default case if none of the above matches
  }
}
