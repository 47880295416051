import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay, isSameMonth, isSameWeek, addDays } from 'date-fns';
import { formatTime } from '../../../../common';

const AppointmentsList = ({ selectedMode, selectedDates, appointments }) => {

    useEffect(() => {
        
    }, [selectedDates, selectedMode]);

    const renderAppointments = () => {
        return appointments.map((appointment) => {
            
            const isInSelectedDay = selectedMode === 'Daily' && selectedDates.some((date) => isSameDay(date, appointment.appointmentstarton));
            const isInSelectedWeek = selectedMode === 'Weekly' && selectedDates.some((date) => isSameWeek(date, appointment.appointmentstarton));
            const isInSelectedMonth = selectedMode === 'Monthly' && selectedDates.some((date) => isSameMonth(date, appointment.appointmentstarton));

            if (isInSelectedDay || isInSelectedWeek || isInSelectedMonth) {
                return (
                    <div key={appointment.appointmentid} className="p-2 border-b">
                        <p className="font-medium flex items-center"><div className={`w-4 mr-3 border border-black h-4 bg-${appointment.calendar_palettecolor}-200`}></div> {appointment.title || appointment.itemtypename} - {appointment.patient_firstname}</p>
                        <p className="font-normal text-sm">{appointment.comment}</p>
                        <p className="text-gray-600 text-sm">{format(appointment.appointmentstarton, 'PP')} - {formatTime(appointment.appointmentstarton)} {appointment.appointmentendon && appointment.appointmentendon != appointment.appointmentstarton ? (' to ' + format(appointment.appointmentstarton, 'PP') + '-' + formatTime(appointment.appointmentstarton)) : ''}</p>
                    </div>
                );
            }
            return null;
        });
    };

    return (
        <div className="mt-4 h-[76vh] overflow-auto">
            {renderAppointments()}
        </div>
    );
};

export default AppointmentsList;
