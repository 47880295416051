import axios from "axios";
import { baseApiUrl } from '../../session'
const apiCall = baseApiUrl + 'Procedures/';


export const createAdminTemplate = (templateName) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(apiCall + 'CreateAdminTemplate', templateName,
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8'
          }
        });
      dispatch({ type: "Success", payload: 'success' });
      return response.data;
    } catch (error) {
      dispatch({ type: "Failed", payload: error });
      throw error;
    }

  };
};

export const AddTPReferenceItems = (data) => {
  return async (dispatch) => {
    try {
      const response = axios.post(apiCall + 'CreateProcedureObject', data,
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8'
          }
        });
      dispatch({ type: "Success", payload: 'success' });
      return response;

    } catch (error) {
      dispatch({ type: "Failed", payload: error });
      throw error;
    }

  };
};

export const LoadCycleItems = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(apiCall + 'GetProcedureTypeList');
      const data = await response.data;
      dispatch({ type: "Success", payload: data });
      return data;
    } catch (error) {
      dispatch({ type: "Failed", payload: error });
      throw error;
    }
  };
};

export const getReferenceItemsList = (id, tpid) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(apiCall + 'GetProcedureItems/' + id + '/' + tpid);
      const data = await response.data;
      dispatch({ type: "Success", payload: data });
      return data;
    } catch (error) {
      dispatch({ type: "Failed", payload: error });
      throw error;
    }
  };
};

export const getReferenceItems = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(apiCall + 'GetProceduresForReference');
      const data = await response.data;
      dispatch({ type: "Success", payload: data });
      return data;
    } catch (error) {
      dispatch({ type: "Failed", payload: error });
      throw error;
    }
  };
};

export const DeleteTPReferenceItems = (id) => {
  return async (dispatch) => {
    try {
      const response = axios.delete(apiCall + 'DeleteTreatmentPlanObject?objectId=' + id);
      dispatch({ type: "Success", payload: 'success' });
      return response;

    } catch (error) {
      dispatch({ type: "Failed", payload: error });
      throw error;
    }

  };
};

export const fetchDataRequest = () => ({
  type: 'FETCH_DATA_REQUEST'
});

export const fetchDataSuccess = (data) => ({
  type: 'FETCH_DATA_SUCCESS',
  payload: data
});

export const fetchDataFailure = (error) => ({
  type: 'FETCH_DATA_FAILURE',
  payload: error
});
