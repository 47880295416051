import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getCorrectDateForInput, getDateByRange } from '../../../../../common'
import { useAppContext } from '../../../../../AppContextProvider '

export const MedicationPlanner = ({ treatmentPlan }) => {
   
    const theme = useAppContext().themeDetails;

    const getDate = (dayNumber) => {
        return moment(getCorrectDateForInput(treatmentPlan?.items[0]?.DayOneOfCycleArt)).add((dayNumber - 1), 'days').format("ddd DD MMM")
    }

    return (
        <>
            <div className='w-full flex items-center gap-2 flex-wrap justify-center my-2'>

                {Array.from({ length: 30 }, (_, index) => index + 1).map((number) => (
                    <div className='w-[16.2%] border shadow rounded-b-md cursor-pointer hover:scale-110 hover:bg-gray-100' key={number}>
                        <div className={theme.tHedUpdte + ' text-white font-medium flex items-center justify-between text-xs p-1'}>
                            <p>{getDate(number)}</p>
                            <p>{'Day-' + number}</p>
                        </div>
                        <div className={`${theme.thumb} ${theme.track} scrollbar scrollbar-thin hover:text-gray-300 h-16 p-1 relative`} >
                            {/* Watermark */}
                            <div className="absolute inset-0 flex items-center justify-center text-gray-100 hover:text-gray-300 font-bold text-xl">{getDate(number)}</div>
                            {/* Watermark */}

                            {number === 3 ? <div className="flex flex-wrap gap-1"><p className="text-sm text-black bg-red-200 w-fit px-1 rounded">Rekovelle 200</p></div> : ''}
                            {number === 11 || number === 21 || number === 2 ? <div className="flex flex-wrap gap-1"><p className="text-sm text-black bg-red-200 w-fit px-1 rounded">Rekovelle 200</p><p className="text-sm bg-green-200 text-black w-fit px-1 rounded">Puregon 400</p></div> : ''}
                            {number === 16 ? <div className="flex flex-wrap gap-1"><p className="text-sm text-black bg-red-200 w-fit px-1 rounded">Rekovelle 200</p></div> : ''}
                            {number === 12 ? <div className="flex flex-wrap gap-1"><p className="text-sm text-black bg-purple-200 w-fit px-1 rounded">Cetrotide 250 mcg</p></div> : ''}
                            {number === 13 ? <div className="flex flex-wrap gap-1"><p className="text-sm text-black bg-purple-200 w-fit px-1 rounded">Decapeptyl - Trigger .200mcg</p></div> : ''}
                            {number === 22 ? <div className="flex flex-wrap gap-1"><p className="text-sm text-black bg-red-200 w-fit px-1 rounded">Rekovelle 200</p></div> : ''}
                            {number === 26 ? <div className="flex flex-wrap gap-1"><p className="text-sm text-black bg-purple-200 w-fit px-1 rounded">Cetrotide 250 mcg</p></div> : ''}
                        </div>


                    </div>
                ))}

            </div>
            <div className='w-full text-sm flex items-center gap-3 justify-end'>
                <p className='bg-red-200 border border-black w-4 h-4'>&nbsp;</p>
                <p>Stimulation Medication</p>
                <p className='bg-purple-200 border border-black w-4 h-4'>&nbsp;</p>
                <p>Luteal Medication</p>
                <p className='bg-green-200 border border-black w-4 h-4'>&nbsp;</p>
                <p>Trigger Medication</p>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationPlanner)