import {
    getTreatmentPlanSuccess, getTreatmentPlanFail

} from "../constants";

export const initialState = {
    user: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getTreatmentPlanSuccess:
            return {
                ...state,
                loading: false,
                treatmentPlan: action.payload,
            };
        case getTreatmentPlanFail:
            return {
                ...state,
                loading: true,
                treatmentPlan: action.payload.message,
            };
        default:
            return state;
    }
}
