import axios from "axios";
import {
  Success,
  Fail
} from "../constants";

import { baseApiUrl } from '../../session'
// const apiCall = baseApiUrl + 'UserManagment/';
axios.defaults.withCredentials = true;

// export const getUserDetails = (userid) => {
//     return async (dispatch) => {
//         try {
//             const response = await axios.get(apiCall + `GetUserByUserId/` + userid);
//             const data = await response.data;
//             dispatch({ type: Success, payload: data });
//             return data;
//         } catch (error) {
//             dispatch({ type: Fail, payload: error });
//             throw error;
//         }
//     };
// };

// export const getUserDetails = (userid) => {
//     return (dispatch) => {
//         return axios.get(apiCall + `GetUserByUserId/` + userid).then((response) => {
//             const data = response.data;
//             dispatch({ type: Success, payload: data });
//             return data;
//         }).catch((error) => {
//                 dispatch({ type: Fail, payload: error });
//                 throw error;
//             });
//     };
// };

// export const getUserDetails = (userid) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(apiCall + '/GetUserByUserId/' + userid)
//       .then((response) => {
//         const data = response.data;
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };


export const getUserDetailsWithBaseUrl = (baseUrl, userid) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + 'UserManagment/GetUserByUserId/' + userid)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const loginUser = (givenUrl) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseApiUrl + givenUrl)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};