import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import chaartwhitelogo from './../../Assets/images/chaartlogo.png';
import logoami from './../../Assets/images/i2.gif';

// import audioFile from './../../Assets/images/v';
import './style.css'

import { ApiCall_GET } from '../../Redux/Generic/action';
import Swal from 'sweetalert2';
import { loginUser } from '../../Redux/Auth/action';
import { useAppContext } from '../../AppContextProvider ';
import { setGlobalIsLoading, setSession } from '../../session';

export const Login = ({ setIsLogin, ApiCall_GET, sendData }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { themeDetails, setThemeDetails } = useAppContext({});

  var Themes = require('../../Theme.json');
  const getThemeColor = (clr) => {
    let selectedTheme = Themes.filter(x => x.themeColorId === clr)[0]

    setThemeDetails(selectedTheme || Themes[0]);
  }
  useEffect(() => {
    setIsLogin(true);
    sendData(null)
  }, []);

  const handleLogin = () => {
    // Add your authentication logic here.

    if (!username.trim()) {
      Swal.fire({
        icon: 'warning',
        text: 'Please Enter Username',
        showConfirmButton: true,
      });
    } else if (!password.trim()) {
      Swal.fire({
        icon: 'warning',
        text: 'Please Enter Password',
        showConfirmButton: true,
      });
    } else {


      setGlobalIsLoading(true)
      // ApiCall_GET('UserManagment/Login/' + username + '/' + password, true).then(function (payload) {
      loginUser('UserManagment/Login/' + username + '/' + password).then(function (payload) {
        if (payload) {
          setSession('user', payload);
          getThemeColor(Number(payload.ColorId))
          navigate('/home?userid=' + payload.UserId);


        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Login Failed',
            text: 'Please check username, password or permissions',
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="flex absolute top-0 w-full h-screen  bakImg bg-no-repeat bg-center ">




      {/* <video src={logoami} autoPlay loop muted/> */}
      <div className={'w-full  flex justify-center items-center'}>
        {/* <img className='h-[90% w-full' src={logoami} alt="Animated GIF" /> */}
        {/* <img className='h-[90% w-full' src={i1} alt="Animated GIF" /> */}
        {/* <img className='h-[90% w-full' src={i2} alt="Animated GIF" /> */}
        {/* <img className='h-[90% w-full' src={i3} alt="Animated GIF" /> */}
        {/* <img className='h-[90% w-full' src={i4} alt="Animated GIF" /> */}
        {/* <img className='h-[90% w-full cover object-fill' src={i5} alt="Animated GIF" /> */}
        {/* <img className='h-[90% w-full' src={i6} alt="Animated GIF" /> */}
        {/* <img className='h-[90% w-full' src={i7} alt="Animated GIF" /> */}
        {/* <video height={'10%'} className={'rounded h-full'} src={logoami} autoPlay loop muted /> */}

        <div className="rounded-lg -md  w-[26%] py-4  bg-white shadow-xl flex Items-center justify-center">
          <div className='bg-white p-8 w-4/5 flex flex-col bg-red-300  Items-center justify-center' >
            {/* <h2 className="text-xl font-semibold mb-6 text-center">City Fertility Clinic</h2> */}
            <div className='flex justify-center items-center mb-6 '>

              <img src={chaartwhitelogo} className=" self-center" alt="img" width='300px' height='100px' />
            </div>

            <div className={`flex`}>
              <h2 className="text-2xl font-semibold  text-center w-1/3" style={{
                cursor: 'pointer',
                fontSize: '16px',
                // color: '#888',
                lineHeight: '50px',
                backgroundColor: '#f1f1f1',
                borderRadius: '10px 10px 0 0',
              }}>Login</h2>

              <div className={'w-1/3 bg-[#f1f1f1]'}>
                <div className={' bg-white w-full h-full'} style={{ borderRadius: '0px 0px 0px 10px', }}></div>
              </div>
            </div>
            <div className="mb-2">
              <input
                type="text"
                placeholder="Username"
                className="w-full p-3 border border-gray-100 rounded focus:outline-none focus:border-blue-500 "
                style={{ borderRadius: '0px 15px 0 0', backgroundColor: '#f1f1f1', fontFamily: 'Roboto,sans-serif' }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyPress}
                autoFocus={true}
              />
            </div>
            <div className="mb-2">
              <input
                style={{ backgroundColor: '#f1f1f1' }}
                type="password"
                placeholder="Password"
                className="w-full p-3 border border-gray-200 rounded focus:outline-none focus:border-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </div>
            <button

              style={{ backgroundColor: ' #303030', borderRadius: '0px 0px 10px 10px', }}
              className="w-full bg-blue-500 text-white py-3 rounded hover-bg-blue-600 focus:outline-none"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};

const mapStateToProps = (state) => ({
  patient: state.patientReducer.patient,
});
const mapDispatchToProps = { ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
