import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ApiCall_POST, ApiCall_GET } from '../../../../../../../Redux/Generic/action';


export const DonorDetails = ({ rowData, ApiCall_GET, ApiCall_POST, theme, task }) => {

    const [dropdown, setDropdown] = useState([])
    const [value, setValue] = useState(null)
    var isDonorExist = false;
    if (rowData.DonatingOptions.split(':')[1]?.trim() !== '') {
        isDonorExist = true
    }

    var isRecipientExist = false;
    if (rowData.DonorRecipientOptions.split(':')[1]?.trim() !== '') {
        isRecipientExist = true
    }

    useEffect(() => {
        ApiCall_GET('Art/LoadCoupleDetailsList', true).then(function (payload) {
            setDropdown(payload);
        });

    }, [])

    const saveDonor = (savedBy) => {

        var ObjId = 0;
        if (savedBy === 'Donor') {
            ObjId = rowData.DonorRecipientOptionProcedureId;
        }
        else if (savedBy === 'Recipient') {
            ObjId = rowData.DonatingOptionProcedureId;
        }

        var dataToSend = {
            TreatmentPlanId: rowData.TreatmentPlanId,
            ObjectId: ObjId,
            RunningSheetId: rowData.Id,
            Value: value
        }

        ApiCall_POST('Art/SaveDonorDetailsInTreatmentplan', dataToSend, true).then(function (payload) {
            // setDropdown(payload);
            window.location.reload();
        });
    }


    return (
        <div className='  p-2'>
            <label className={' font-bold  ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>{rowData.TaskId === task.isDonating ? 'Recipient' : 'Donor'} Information</label>
            {(rowData.TaskId === task.isDonating && !isDonorExist) || (rowData.TaskId !== task.isDonating && !isRecipientExist) ?
                <>
                    <p className='mx-5 mt-3 text-sm  font-medium text-gray-500'> {rowData.TaskId === task.isDonating ? 'Recipient' : 'Donor'} has not been assigned</p>
                    <div className=' mb-3 flex items-center my-2'>
                        <p className='mx-5 text-sm  font-bold'> {rowData.TaskId === task.isDonating ? 'Recipient' : 'Donor'} List : </p>
                        <select name='dataTypeId' id="" onChange={(e) => setValue(e.target.value)} defaultValue={0} className={"w-80 bg-white border text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block ring-1 ring-inset ring-gray-300   p-1"
                        } >
                            <option disabled value={0}>-- Select {rowData.TaskId === task.isDonating ? 'Recipient' : 'Donor'} --</option>
                            {dropdown && dropdown.filter(x => (rowData.TaskId !== task.isDonating) === x.donor).map((item) => (
                                <option value={item.surname + ', ' + item.firstname + ' ~ ' + item.couplecode}>{item.surname + ', ' + item.firstname + ' ~ ' + item.couplecode}</option>
                            ))}
                        </select>
                        <button className="bg-transparent ml-5 hover:bg-green-600 text-green-800 font-semibold text-sm hover:text-white py-1 px-2 border border-green-500 hover:border-transparent rounded" onClick={() => { saveDonor(rowData.TaskId === task.isDonating ? 'Recipient' : 'Donor') }} >  Save {rowData.TaskId === task.isDonating ? 'Recipient' : 'Donor'}</button>
                    </div>
                </>
                :
                <>
                    <p className='mx-5 mt-3 text-sm  font-medium text-gray-500'> {rowData.TaskId === task.isDonating ? 'Recipient' : 'Donor'} has been assigned</p>
                    <div className=' mb-3 flex items-center my-2'>
                        {rowData.TaskId === task.isDonating ?
                            <>
                                <p className='mx-5 text-sm  font-bold'> Recipient Name : </p>
                                <p className='mx-5 text-sm  font-bold'> {rowData.DonatingOptions.split(':')[1]?.trim() !== '' ? rowData.DonatingOptions.split(':')[1] : ''}</p>
                            </>
                            :
                            <>
                                <p className='mx-5 text-sm  font-bold'> Donor Name : </p>
                                <p className='mx-5 text-sm  font-bold'> {rowData.DonorRecipientOptions.split(':')[1]?.trim() !== '' ? rowData.DonorRecipientOptions.split(':')[1] : ''}</p>
                            </>
                        }
                    </div>
                </>
            }

        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(DonorDetails)