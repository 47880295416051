import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';
import { andrologyType } from './AndrologyTypeList';
import { formatTime, formatTimeDifference, getCorrectDate, getUserInformation, replaceEmptyObjectsWithNull, returnNullIfEmpty } from '../../../../common';
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCheck, faEye, faPrint, faTable, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import AddAndrologyRequest from './AddAndrologyRequest';
import AndrologyRequestView from './Views/AndrologyRequestView/AndrologyRequestView';
import AndrologyResultView from './Views/AndrologyResultView/AndrologyResultView';
import AndrologyResultViewTesaPesa from './Views/AndrologyResultViewTesaPesa/AndrologyResultViewTesaPesa';
import { useLocation, useNavigate } from 'react-router-dom';
import { isUserPermited, UserType } from '../../../../session';

export const Andrology = ({ ApiCall_GET, coupleId, patientState, getInventoryDetails, setShowEmbryology, ...otherProps }) => {
    let navigate = useNavigate();
    const location = useLocation();
    const [artId, setArtId] = useState(location.state.artid)
    if (!coupleId) {
        coupleId = location.state.coupleid;
    }
    const theme = useAppContext().themeDetails;
    const [selectedTab, setSelectedTab] = useState(7)
    const [openForm, setOpenForm] = useState(false)
    const [andrologyList, setAndrologyList] = useState([])

    const [openViewModal, setOpenViewModal] = useState(null)

    useEffect(() => {
        getAndrologyList(location.state.artid)
    }, [])

    const getAndrologyList = (artid) => {
        ApiCall_GET('Lab/GetAndrologyList/' + coupleId + (artid ? '/' + artid : '/0'), true).then(function (payload) {
            setAndrologyList(replaceEmptyObjectsWithNull(payload));
        });
    };

    // function Andrology_TreatmentLabelFromDS(sarqDS) {
    //     let sb = [];

    //     if (sarqDS["semenAnalysisOnly"]) sb.push("Semen Analysis only\n");
    //     if (sarqDS["semenAnalysisAntibodies"]) sb.push("SA + Antibody Testing\n");
    //     if (sarqDS["semenAnalysisDNAFrag"]) sb.push("SA + DNA Frag\n");
    //     if (sarqDS["semenAnalysisDNAFragRepeat"]) sb.push("Repeat DNA Frag\n");
    //     if (sarqDS["semenAnalysisIUIPrep"]) sb.push("IUI Preparation\n");
    //     if (sarqDS["semenAnalysisARTSuitability"]) sb.push("SA + ART\n");
    //     if (sarqDS["semenAnalysisARTSuitabilityAntibodies"]) sb.push("SA + ART + Antibody Testing\n");
    //     if (sarqDS["donorPreparation"]) sb.push("Donor Prep\n");
    //     if (sarqDS["spermFreeze"]) sb.push("Semen Freeze\n");

    //     if (sb.length < 1) sb.push("-Unspecified-");

    //     return sb.join('');
    // }


    return (
        < >
            <div className={theme.bg_Light + '  p-2 mt-2  flex gap-2 items-center justify-between shadow-md w-full '}>

                {/* <div className='flex gap-2 items-center'>
                    {andrologyType.filter(x => x.id == 7).map((item) => (
                        <div key={item?.id} onClick={() => { setSelectedTab(item?.id); setOpenForm(false) }} className={theme.side_Br_Hover + ' ' + theme.borderColor + ' p-1  h-full  shadow rounded flex flex-col place-items-center justify-center p-2 cursor-pointer ' + (selectedTab == item?.id ? theme.txt_color + ' hover:text-white ' : theme.txt_color + ' hover:text-white ')}>
                            {item?.icon}
                            <p className={'  text-sm text-center font-bold textOverflowNavART '}>{item?.text}</p>
                        </div>

                    ))}
                </div> */}
                <div className='flex gap-2 items-center'>
                    {(isUserPermited(UserType.Administrator) || isUserPermited(UserType.Doctor) || isUserPermited(UserType.Nurse)) &&
                        <div onClick={() => setOpenForm(true)} className={`${theme.side_Br_Hover}  ${theme.borderColor}  p-1  h-full  shadow rounded flex flex-col place-items-center justify-center p-2 cursor-pointer  ${theme.txt_color}  hover:text-white `}>
                            <FontAwesomeIcon icon={faAdd} />
                            <p className={'  text-sm text-center font-bold textOverflowNavART '}>Add {andrologyType?.filter(x => x.id == selectedTab)[0]?.text}</p>
                        </div>

                    }
                </div>
                {/* {(isUserPermited(UserType.Administrator) || isUserPermited(UserType.Doctor) || isUserPermited(UserType.Nurse)) && <div onClick={() => setOpenForm(true)} className={theme.side_Br_Hover + ' ' + theme.borderColor + 'p-1  h-full  shadow rounded flex flex-col place-items-center justify-center p-2 cursor-pointer ' + theme.txt_color + ' hover:text-white '}>
                    <FontAwesomeIcon icon={faAdd} />
                    <p className={' pl-3 text-sm text-center font-bold textOverflowNavART '}>Add {andrologyType?.filter(x => x.id == selectedTab)[0]?.text}</p>
                </div>} */}
                <div className='w-48 ml-5 cursor-pointer'
                    onClick={() => { getInventoryDetails('E'); setShowEmbryology(true); }}
                >
                    <div className='flex items-center justify-between  cursor-pointer pr-2    ' >
                        <label className={'pr-1 font-bold text- xl  cursor-pointer border-b-[3px] border-b-amber-500 ' + theme.txt_color + ''}>Current Inventory</label>
                        <svg className={` hover:scale-105  shadow `} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 2H19M11 6H16M11 12H19M11 16H16M1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H6C6.26522 1 6.51957 1.10536 6.70711 1.29289C6.89464 1.48043 7 1.73478 7 2V6C7 6.26522 6.89464 6.51957 6.70711 6.70711C6.51957 6.89464 6.26522 7 6 7H2C1.73478 7 1.48043 6.89464 1.29289 6.70711C1.10536 6.51957 1 6.26522 1 6V2ZM1 12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11H6C6.26522 11 6.51957 11.1054 6.70711 11.2929C6.89464 11.4804 7 11.7348 7 12V16C7 16.2652 6.89464 16.5196 6.70711 16.7071C6.51957 16.8946 6.26522 17 6 17H2C1.73478 17 1.48043 16.8946 1.29289 16.7071C1.10536 16.5196 1 16.2652 1 16V12Z" stroke={theme.iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </div>

                    <div className='w-full mt-2 bg-white rounded p-2 shadow hover:bg-gray-100'>
                        <div className='flex items-center'>
                            <div className='w-5/6'>
                                <p className='text-sm font-medium'>Embryos in Storage :</p>
                            </div>
                            <div className='w-1/6'>
                                <p className='text-sm ml-2'>{patientState?.currentInventory?.EmbCount ?? 0}</p>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className='w-5/6'>
                                <p className='text-sm font-medium'>Eggs in Storage :</p>
                            </div>
                            <div className='w-1/6'>
                                <p className='text-sm ml-2'>{patientState?.currentInventory?.EggCount ?? 0}</p>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className='w-5/6'>
                                <p className='text-sm font-medium'>Sperm in Storage :</p>
                            </div>
                            <div className='w-1/6'>
                                <p className='text-sm ml-2'>{patientState?.currentInventory?.SpermCount ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectedTab === 7 ?
                <div className={'   w-full'}>
                    {openForm ?
                        <AddAndrologyRequest coupleId={coupleId} setOpenForm={setOpenForm} artid={location.state.artid} successFunction={getAndrologyList} />
                        :
                        ''}
                </div >
                : ''}
            {openViewModal ?
                <div className={'   w-full'}>
                    {openViewModal?.type == 'request' ?
                        <AndrologyRequestView coupleId={coupleId} andrologyid={openViewModal?.id} setOpenForm={setOpenViewModal} />
                        : openViewModal?.type == 'results' ?
                            openViewModal?.andrologytypeid == 7 ?
                                <AndrologyResultViewTesaPesa coupleId={coupleId} andrologyid={openViewModal?.id} andrologytypeid={openViewModal?.andrologytypeid} setOpenForm={setOpenViewModal} />
                                : <AndrologyResultView coupleId={coupleId} andrologyid={openViewModal?.id} andrologytypeid={openViewModal?.andrologytypeid} setOpenForm={setOpenViewModal} />

                            : ''
                    }
                </div >
                : ''}



            <div className={' bg-white h-[80vh] mb-3 pt-3 w-full ' + (artId ? 'p-2' : '')}>

                {artId ?
                    <div className='flex items-center justify-between pb-3'>
                        <div className='flex items-center'>
                            <p className={' pl-3 text-sm text-center font-bold textOverflowNavART '}>Filtered by Art Cycle : {'ART' + artId} </p>
                            <span onClick={() => { setArtId(0); getAndrologyList(0) }} className='text-red-500 hover:text-white hover:bg-red-500 ml-5 py-1 text-sm px-2 border border-red-500 cursor-pointer rounded' title='Remove Filter'><FontAwesomeIcon icon={faTimes} /> Remove Filter</span>
                        </div>
                        <button
                            onClick={() => navigate('/coupledetails', { state: { id: coupleId } })}
                            className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2"
                        >
                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
                            </svg>
                            <label className='pl-2 cursor-pointer'> Back to ART</label>

                        </button>
                    </div>

                    : ''}

                <div className={theme.thumb + ' ' + theme.track + '   overflow-auto h-[70vh] scrollbar  scrollbar-thin w-full '} style={{ width: "100%" }}>
                    <div className={'w-full flex py-1 text-white rounded ' + theme.navbar}>
                        <div style={{ width: '3%' }} className={'flex item-center justify-center py-1'}>
                            <p className='text-sm font-bold'>#</p>
                        </div>
                        <div style={{ width: '5%   ' }} className={'py-1'}>
                            <p className='text-sm font-bold'></p>
                        </div>
                        <div style={{ width: '12%' }} className={'py-1'}>
                            <p className='text-sm font-bold'>Request Entered Date</p>
                        </div>
                        <div style={{ width: '18%' }} className={'py-1'}>
                            <p className='text-sm font-bold'>Andrology Cycle Type</p>
                        </div>
                        <div style={{ width: '12%' }} className={'py-1'}>
                            <p className='text-sm font-bold'>Sample Date</p>
                        </div>
                        <div style={{ width: '15%' }} className={'py-1'}>
                            <p className='text-sm font-bold'>Clinician</p>
                        </div>
                        <div style={{ width: '15%' }} className={'py-1'}>
                            <p className='text-sm font-bold'>Scientist</p>
                        </div>
                        <div style={{ width: '8%' }} className={'py-1'}>
                            <p className='text-sm font-bold'>Results</p>
                        </div>
                        <div style={{ width: '12%' }} className={'py-1'}>
                            <p className='text-sm font-bold'></p>
                        </div>
                    </div>

                    {andrologyList && andrologyList?.map((item, index) => {
                        let iconSuffix = "";
                        switch (item.andrologytypeid) {
                            case 0: iconSuffix = "request"; break;
                            case 1: iconSuffix = "sa"; break;
                            case 2: iconSuffix = "saart"; break;
                            // case 3: iconSuffix = "ivf"; break; // Commented out as per provided logic
                            case 4: iconSuffix = "iui"; break;
                            case 5: iconSuffix = "freeze"; break;
                            case 6: iconSuffix = "ivf"; break;
                            case 7: iconSuffix = "tesapesa"; break;
                            default: iconSuffix = "unknown"; break;
                        }


                        return (
                            <div key={index} className={`w-full cursor-pointer flex text-sm mb-0.5 pt-1 rounded shadow border hover:bg-purple-100 items-center ${iconSuffix === "request" ? "bg-red-100" : ""}`}>
                                <div style={{ width: '3%' }} className={'flex item-center justify-center py-1'}>
                                    <p className='text-sm'>{andrologyList?.length - index}</p>
                                </div>
                                <div style={{ width: '5%' }} className={theme.toggle + ' px-3 cursor-pointer self-center hover:scale-125 h-full'} onClick={() => iconSuffix === "request" ? setOpenViewModal({ type: 'request', id: item.andrologyid, andrologytypeid: item.andrologytypeid }) : setOpenViewModal({ type: 'results', id: item.andrologyid, andrologytypeid: item.andrologytypeid })}>
                                    {andrologyType.filter(x => x.suffix == item.andrologytypeid)[0]?.icon}
                                </div>
                                <div style={{ width: '12%' }} className={'center'}>
                                    <p className='text-sm'>{getCorrectDate(item?.addedon)}</p>
                                    <em style={{ fontSize: '11px' }}>{item?.addedon ? formatTimeDifference(item?.addedon) : ''}</em>
                                </div>
                                <div style={{ width: '18%' }} className={'center'}>
                                    <strong>{item?.andrologytypename}</strong>
                                </div>
                                <div style={{ width: '12%' }} className={'center'}>
                                    <p className='text-sm'>{getCorrectDate(item?.sampledate)}</p>
                                    <em style={{ fontSize: '11px' }}>{item?.sampledate ? formatTimeDifference(item?.sampledate) : ''}</em>
                                </div>
                                <div style={{ width: '15%' }} className={'center'}>
                                    <div className={`doctor_${item?.cliniciancolor} doctor-label`}>
                                        {item?.clinicianname}
                                        {item?.locumb === 1 && <span className="locum-label rounded-md font-bold text-xss ml-2 p-1 bg-red-200">LOCUM</span>}
                                    </div>
                                </div>
                                <div style={{ width: '15%' }} className={'center'}>
                                    <span className={`ic16-andrology-${iconSuffix}`}>&nbsp;</span>&nbsp;{item?.scientistname}
                                </div>
                                <div style={{ width: '8%' }} className={'center'}>
                                    {iconSuffix === "request" ? <em>Pending</em> : "Ready to view"}
                                </div>
                                <div style={{ width: '12%' }} className={'flex  justify-center'} >
                                    <div style={{ width: '150px', minWidth: '150px' }}>

                                        <button
                                            onClick={() => iconSuffix === "request" ? setOpenViewModal({ type: 'request', id: item.andrologyid, andrologytypeid: item.andrologytypeid }) : setOpenViewModal({ type: 'results', id: item.andrologyid, andrologytypeid: item.andrologytypeid })}
                                            className="flex items-center cursor-pointer justify-center text-gray-500 bg-yellow-400 border border-gray-300 focus:outline-none hover:text-black  hover:bg-yellow-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                                            <FontAwesomeIcon icon={faEye} />
                                            <label className="pl-2 cursor-pointer">View / Print</label>
                                            <FontAwesomeIcon icon={faPrint} className='ml-2' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>


            </div >
        </ >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(Andrology)