import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CalendarSmall from './CalendarComponents/CalendarSmall';
import { ApiCall_GET } from '../../../Redux/Generic/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faList, faCalendar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import AppointmentsList from './CalendarComponents/AppointmentsList';
import CalendarFull from './CalendarComponents/CalendarFull';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay, isSameMonth, isSameWeek, addDays } from 'date-fns';
import { replaceEmptyObjectsWithNull } from '../../../common';

const Calendar = ({ ApiCall_GET }) => {
    const [selectedMode, setSelectedMode] = useState('Daily'); // 'Daily', 'Weekly', 'Monthly'
    const [selectedDates, setSelectedDates] = useState([new Date()]);
    const [calendarData, setCalendarData] = useState([]);
    const [isCalendarView, setIsCalendarView] = useState(false);
    const [expandedItems, setExpandedItems] = useState({});
    const [appointments, setAppointments] = useState([]);
    const [originalAppointments, setOriginalAppointments] = useState([]);


    useEffect(() => {
      
        if (selectedDates) {
            getAppointments(selectedDates[0], selectedDates[1] ? selectedDates[1] : selectedDates[0])
        }
    }, [selectedDates])



    useEffect(() => {
        // Fetch appointments based on selected dates or mode
        // This is just a mockup, replace it with an API call or actual data source
        // const fetchedAppointments = [
        //     { id: 1, date: new Date(), title: "Meeting with team", time: "10:00 AM" },
        //     { id: 2, date: new Date(), title: "Doctor's appointment", time: "02:00 PM" },
        //     { id: 3, date: addDays(new Date(), 1), title: "Project deadline", time: "All day" }, // addDays is used here
        //     { id: 4, date: new Date(), title: "Doctor's appointment", time: "02:00 PM" },
        // ];
        // setAppointments(fetchedAppointments);

        ApiCall_GET('Calendar/GetCalendarDetails', true).then(payload => {
            const transformedData = payload?.CalendarHeader.reduce((acc, item) => {
                const { calendarid, calendarname, calendaritemtypeid, itemtypename, ...rest } = item;
                if (!acc.find(parent => parent.id === calendarid)) {
                    acc.push({
                        ...rest,
                        id: calendarid,
                        name: calendarname,
                        checked: true,
                        children: [{ id: calendaritemtypeid, name: itemtypename, checked: true, ...rest }]
                    });
                } else {
                    const parentIndex = acc.findIndex(parent => parent.id === calendarid);
                    acc[parentIndex].children.push({ id: calendaritemtypeid, name: itemtypename, checked: true, ...rest });
                }
                return acc;
            }, []);


            setCalendarData(transformedData);

            var today = moment().format('yyyy-MM-DD')
            getAppointments(today, today)
        });
    }, [ApiCall_GET]);
    // Handle parent checkbox change
    const handleParentChange = (calendarId, checked) => {
        setCalendarData(prevData =>
            prevData.map(parent => {
                if (parent.id === calendarId) {
                    return {
                        ...parent,
                        checked: checked,
                        children: parent.children.map(child => ({
                            ...child,
                            checked: checked,
                        })),
                    };
                }
                return parent;
            })
        );

        if (!checked) {
            const filteredAppointments = appointments.filter(x => x.calendarid != calendarId);
            setAppointments(filteredAppointments);
        } else {
            const filteredAppointments = originalAppointments.filter(x => x.calendarid == calendarId);
            setAppointments([...appointments, ...filteredAppointments]); // Spread the array
        }
    };

    // Handle child checkbox change
    const handleChildChange = (calendarId, childId, checked) => {
        setCalendarData(prevData =>
            prevData.map(parent => {
                if (parent.id === calendarId) {
                    const children = parent.children.map(child => {
                        if (child.id === childId) {
                            return {
                                ...child,
                                checked: checked,
                            };
                        }
                        return child;
                    });

                    // Update the parent checkbox based on child checkboxes
                    const allChecked = children.every(child => child.checked);
                    const noneChecked = children.every(child => !child.checked);
                    return {
                        ...parent,
                        children: children,
                        checked: allChecked && !noneChecked ? allChecked : false,
                    };
                }
                return parent;
            })
        );

        if (!checked) {
            const filteredAppointments = appointments.filter(x => x.calendaritemtypeid != childId);
            setAppointments(filteredAppointments);
        } else {
            const filteredAppointments = originalAppointments.filter(x => x.calendaritemtypeid == childId);
            setAppointments([...appointments, ...filteredAppointments]); // Spread the array
        }
    };




    // Handle toggling the expansion of parent items
    const handleToggle = (calendarId) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [calendarId]: !prevState[calendarId],
        }));
    };

    const getAppointments = (startDate, endDate) => {

        startDate = moment(startDate).format('yyyy-MM-DD')
        endDate = moment(endDate).format('yyyy-MM-DD')

        ApiCall_GET('Calendar/GetCalendarAppointments/' + startDate + '/' + endDate, true).then(payload => {
            payload = replaceEmptyObjectsWithNull(payload)
            setAppointments(payload);
            setOriginalAppointments(payload);
        });
    }



    return (
        <div className="w-full flex items-center p-5 gap-3 h-screen bg-white">
            <div className='w-1/4 border h-full rounded-xl '>
                <div className="w-full text-center py-4">
                    <p className="font-medium text-xl">Calendar</p>
                    <p className="font-medium text-sm">{moment().format('MMMM DD, yyyy')}</p>
                </div>
                <p className="border-b w-full"></p>
                <div className="w-full text-center">
                    <CalendarSmall
                        selectedMode={selectedMode}
                        setSelectedMode={setSelectedMode}
                        selectedDates={selectedDates}
                        setSelectedDates={setSelectedDates}
                    />
                </div>
                <p className="border-b w-full"></p>
                <div className="w-full text-sbase p-3 h-[40vh] overflow-auto">
                    {calendarData.map(parent => (
                        <div key={parent.id} className="mb-3">
                            <div className="flex items-center cursor-pointer" >
                                <input
                                    type="checkbox"
                                    checked={parent.checked}
                                    onChange={(e) => handleParentChange(parent.id, e.target.checked)}
                                    className="mr-3"
                                />
                                <p className={`font-medium bg-${parent.palettecolor}-200 p-1`}  onClick={() => handleToggle(parent.id)}>{parent.name}</p>
                                <FontAwesomeIcon
                                    icon={faAdd}
                                    className={`ml-2 ${expandedItems[parent.id] ? 'rotate-45 text-red-800' : ''}`}
                                    onClick={() => handleToggle(parent.id)}
                                />
                            </div>

                            {/* Conditionally render child items */}
                            {expandedItems[parent.id] && (
                                <div className="ml-3">
                                    {parent.children.map(child => (
                                        <div key={child.id} className="flex items-center pl-3">
                                            <input
                                                type="checkbox"
                                                id={"child_" + child.id}
                                                checked={child.checked}
                                                onChange={(e) => handleChildChange(parent.id, child.id, e.target.checked)}
                                                className="mr-3"
                                            />
                                            <label className={`font-medium bg-${child.palettecolor}-200 p-1`}  htmlFor={"child_" + child.id}>{child.name}</label>

                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className='w-3/4 border h-full rounded-xl'>
                <div className="w-full p-4">
                    <div className='flex  mb-2 justify-between'>
                        <h2 className="text-xl font-semibold">Appointments
                            <h2 className="text-sm italic text-gray-400 font-semibold">
                                {selectedMode === 'Monthly' && selectedDates.length > 0
                                    ? `Selected Dates : ${format(selectedDates[0], 'MMM d, yyyy')} to ${format(endOfMonth(selectedDates[0]), 'MMM d, yyyy')}`
                                    : selectedMode === 'Weekly' && selectedDates.length > 0
                                        ? `Selected Dates : ${format(startOfWeek(selectedDates[0], { weekStartsOn: 1 }), 'MMM d, yyyy')} to ${format(endOfWeek(selectedDates[0], { weekStartsOn: 1 }), 'MMM d, yyyy')}`
                                        : selectedMode === 'Daily' && selectedDates.length > 0
                                            ? 'Selected Date : ' + format(selectedDates[0], 'MMM d, yyyy')
                                            : 'No dates selected'}
                            </h2>

                        </h2>
                        <div className="flex text-sm justify-center mb-4">
                            <button onClick={() => setIsCalendarView(false)} className={(!isCalendarView ? "bg-gray-600" : "bg-gray-500") + " px-4 py-2 text-white rounded-l-lg"}><FontAwesomeIcon icon={faList} /> </button>
                            <button onClick={() => setIsCalendarView(true)} className={(isCalendarView ? "bg-gray-600" : "bg-gray-500") + " px-4 py-2 text-white rounded-r-lg"}><FontAwesomeIcon icon={faCalendar} /></button>
                        </div>
                    </div>
                    {isCalendarView ?
                        <CalendarFull
                            events={appointments}
                            calendarData={calendarData}
                            selectedMode={selectedMode}
                            setSelectedMode={setSelectedMode}
                            selectedDates={selectedDates}
                            setSelectedDates={setSelectedDates}
                        />
                        :
                        <AppointmentsList
                            appointments={appointments}
                            selectedMode={selectedMode}
                            setSelectedMode={setSelectedMode}
                            selectedDates={selectedDates}
                            setSelectedDates={setSelectedDates}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
