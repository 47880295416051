import React from 'react'
import { connect } from 'react-redux'
import SubTaskDetails from './SubTaskDetails/SubTaskDetails'
import { TaskTypes, getCorrectDate, getDateByRange } from '../../../../../../common'


export const TaskItem = (props) => {

    // artid={artData.artid}
    var rowData = props.rowData;
    var theme = props.theme;
    return (

        <div className={(theme.tableBg)} key={rowData.id} onDoubleClick={() => props.handleEdit(rowData.Id, 'DetailsExpanded', !rowData.DetailsExpanded)}>
            <div className={ ' w-full flex   shadow rounded border ' + (rowData.SubTaskId === TaskTypes.DonorInformation && rowData.Status === 0 ? ' border-red-500 ' : ' ') + ((rowData.TaskId === TaskTypes.AnzardData  || rowData.TaskId === TaskTypes.Day1Date) && rowData.Status === 0 ? ' bg-red-200' : theme.panelBg)} >
                <div style={{ width: '6%' }} className=' flex items-center justify-center  px-2 py-1 '>
                    <div className={theme.txt_color + '   rounded minWidthDay ' + theme.borderColor}>
                        <div className={'w-full h-3 border-b text-center  text-white text-xs7  ' + theme.tHedUpdte + ' '}>DAY</div>
                        <div className={'w-full h-4 text-center text-sm font-bold bg-white rounded ' + (rowData.DayOneofCycleDate.length > 0 ? '' : ' mb-1')}>{rowData.Day}</div>
                        {rowData.DayOneofCycleDate.length > 0 ? (
                            <div className={'w-full  text-center text-xs7 px-1 font-bold bg-white rounded '}>{rowData.DayOneofCycleDate.length > 0 ? getDateByRange(rowData.DayOneofCycleDate, rowData.Day - 1) : ''}</div>
                        ) : ''}
                    </div>
                </div>
                <div style={{ width: '17%' }} className={' flex items-center justify-left px-1 py-0.5'}>

                    <div className={'text-sm rounded px-2 py-1 mr-2 '}>
                        {rowData.TreatmentPlanId > 0 &&
                            <p className={(rowData.TreatmentPlanId ? theme.txt_color + '    cursor-pointer' : 'text-gray-600 cursor-not-allowed') + ' ml-1 text-sm font-medium '}>TP {rowData.TreatmentPlanId && <span className={theme.itemActive + ' px-0.5 rounded text-xss'}>{rowData.TPVersion}</span>}</p>
                        }
                        {rowData.BookingFormId > 0 &&
                            <p className={(rowData.BookingFormId ? theme.txt_color + ' cursor-pointer' : 'text-gray-600 cursor-not-allowed') + ' ml-1 text-sm font-medium '}>BF {rowData.BookingFormId && <span className={theme.itemActive + ' px-0.5 rounded text-xss'}>{rowData.BFVersion}</span>}</p>
                        }
                    </div><label className='text-sm'>{rowData.TaskName}</label>
                </div>
                <div style={{ width: '22%' }} className={' flex items-center justify-left px-2 py-1'}>
                    <p className='text-sm  '>{rowData.Comment}</p>
                </div>
                <div style={{ width: '15%' }} className={' flex items-center justify-left px-2 py-1'}>
                    <p className='text-sm  '>{rowData.TaskNotes}</p>
                </div>
                <div style={{ width: '15%' }} className={'  justify-left py-1'}>
                    <p className='text-sm  '>{rowData.CreatedDate ? getCorrectDate(rowData.CreatedDate) : '--'} </p>
                    {rowData.CreatedBy ? <p className='text-xss  '>Created By : <b>{rowData.CreatedBy}</b></p> : ''}
                </div>
                <div style={{ width: '10%' }} className={' justify-left px-2 py-1'}>
                    <p className='text-sm  '>{rowData.UpdatedDate !== '-' ? getCorrectDate(rowData.UpdatedDate) : '--'} </p>
                    {rowData.UpdatedBy !== '-' ? <p className='text-xss  '>Updated By : <b>{rowData.UpdatedBy}</b></p> : ''}
                </div>
                {/* <div style={{ width: '7%' }} className={' flex items-center justify-left px-2 py-1'}>
                    <p className='text-sm  '>{rowData.FollowUpDate ? moment(rowData.FollowUpDate).format('DD/MM/yyyy') : '--'}</p>
                </div> */}

                <div style={{ width: '12%' }} className={' flex items-center justify-left px-2 py-1'}>
                    {rowData.Status === 0 ? (
                        <>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.25 8.25H6.75V3.75H8.25M8.25 11.25H6.75V9.75H8.25M7.5 0C6.51509 0 5.53982 0.193993 4.62987 0.570904C3.71993 0.947814 2.89314 1.50026 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C2.89314 13.4997 3.71993 14.0522 4.62987 14.4291C5.53982 14.806 6.51509 15 7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 6.51509 14.806 5.53982 14.4291 4.62987C14.0522 3.71993 13.4997 2.89314 12.8033 2.1967C12.1069 1.50026 11.2801 0.947814 10.3701 0.570904C9.46018 0.193993 8.48491 0 7.5 0Z" fill="#F8CF65" />
                            </svg>

                            <p className='text-lg font-medium ml-2'>Pending</p>
                        </>
                    ) : 
                     (
                        <>
                            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.6 14.6L15.65 7.55L14.25 6.15L8.6 11.8L5.75 8.95L4.35 10.35L8.6 14.6ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z" fill="#387153" />
                            </svg>

                            <p className='text-lg font-medium ml-2'>Done</p>
                        </>
                    )}


                </div>

                <div style={{ width: '4%' }} className={' flex items-center justify-between '}>

                    {rowData.TreatmentPlanId && (rowData.TaskId === TaskTypes.TreatmentPlanCreated || rowData.TaskId === TaskTypes.TreatmentPlanRevised) ?
                        <div className={'cursor-pointer  mr-2' + (rowData.DetailsExpanded ? theme.bg_Light + '  rounded-full' : '')} title="Details" onClick={() => props.showDetails(rowData.TaskId)} >
                            <svg width="14" height="12" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.45348 2.07643H10.1794V3.46071H4.45348V2.07643ZM4.45348 0H11.4518V1.38429H4.45348V0ZM0 0H3.81727V4.15286H0V0ZM4.45348 7.61357H10.1794V8.99786H4.45348V7.61357ZM4.45348 5.53714H11.4518V6.92143H4.45348V5.53714ZM0 5.53714H3.81727V9.69H0V5.53714Z" fill={theme.iconColor} />
                            </svg>
                        </div>
                        :
                        <div className={'cursor-pointer  mr-2' + (rowData.DetailsExpanded ? theme.bg_Light + '  rounded-full' : '')} title="Details" onClick={() => props.handleEdit(rowData.Id, 'DetailsExpanded', !rowData.DetailsExpanded)} >
                            <svg width="14" height="12" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.45348 2.07643H10.1794V3.46071H4.45348V2.07643ZM4.45348 0H11.4518V1.38429H4.45348V0ZM0 0H3.81727V4.15286H0V0ZM4.45348 7.61357H10.1794V8.99786H4.45348V7.61357ZM4.45348 5.53714H11.4518V6.92143H4.45348V5.53714ZM0 5.53714H3.81727V9.69H0V5.53714Z" fill={theme.iconColor} />
                            </svg>
                        </div>
                    }

                    {/* } */}
                    {/* <div className={'cursor-pointer mr-2 ' + (rowData.EditExpanded ? theme.bg_Light + '  rounded-full' : '')} title="Edit" onClick={() => props.handleEdit(rowData.Id, 'EditExpanded', !rowData.EditExpanded)} >
                        <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.71466 9.14466H4.13804C4.21326 9.1451 4.28783 9.13069 4.35747 9.10225C4.4271 9.07382 4.49044 9.03193 4.54385 8.97898L8.49899 5.01963L10.1222 3.43132C10.1758 3.37821 10.2183 3.31502 10.2473 3.24539C10.2763 3.17577 10.2913 3.10109 10.2913 3.02567C10.2913 2.95025 10.2763 2.87557 10.2473 2.80595C10.2183 2.73633 10.1758 2.67314 10.1222 2.62002L7.69882 0.169C7.64568 0.115449 7.58247 0.0729455 7.51282 0.0439396C7.44317 0.0149337 7.36847 0 7.29302 0C7.21756 0 7.14286 0.0149337 7.07321 0.0439396C7.00356 0.0729455 6.94035 0.115449 6.88721 0.169L5.27543 1.78588L1.30886 5.74522C1.25588 5.79861 1.21397 5.86192 1.18553 5.93153C1.15709 6.00114 1.14267 6.07568 1.14311 6.15087V8.57333C1.14311 8.72486 1.20332 8.87018 1.31051 8.97732C1.4177 9.08447 1.56307 9.14466 1.71466 9.14466ZM7.29302 1.38023L8.91051 2.9971L8.09891 3.8084L6.48141 2.19152L7.29302 1.38023ZM2.28621 6.38512L5.67552 2.9971L7.29302 4.61398L3.90371 8.00199H2.28621V6.38512ZM10.8595 10.2873H0.571553C0.419968 10.2873 0.274591 10.3475 0.167404 10.4547C0.060217 10.5618 0 10.7071 0 10.8587C0 11.0102 0.060217 11.1555 0.167404 11.2627C0.274591 11.3698 0.419968 11.43 0.571553 11.43H10.8595C11.0111 11.43 11.1565 11.3698 11.2637 11.2627C11.3708 11.1555 11.4311 11.0102 11.4311 10.8587C11.4311 10.7071 11.3708 10.5618 11.2637 10.4547C11.1565 10.3475 11.0111 10.2873 10.8595 10.2873Z" fill={theme.iconColor} />
                        </svg>
                    </div> */}

                </div>
            </div>
            {rowData.DetailsExpanded && (
                <SubTaskDetails props={props} artid={props.artid} />

            )}

        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TaskItem)