
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import Draggable from 'react-draggable';
import { useAppContext } from '../../AppContextProvider ';
import { getUserInformation } from '../../common';
import { useDispatch } from 'react-redux';



const PinModal = (props) => {
    const [pin, setPin] = useState(['', '', '', '', '']);
    const [showAlert, setShowAlert] = useState(false);
    const theme = useAppContext().themeDetails;
    const [ShowModal, setShowModal] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const dispatch = useDispatch();

    const handlePinChange = (e) => {
        setShowAlert(false);
        if (/^\d$/.test(e.key) && selectedIndex <= 4) {
            setPin((prevPin) => {
                const pinTemp = [...prevPin];
                pinTemp[selectedIndex] = Number(e.key);

                if (selectedIndex === 4) {
                    handlePinCompletion(pinTemp.join(''))
                } else {
                    setSelectedIndex(selectedIndex + 1);
                }

                return pinTemp;
            });
        } else if (e.key === 'Backspace') {
            setPin((prevPin) => {
                const pinTemp = [...prevPin];

                // Check if the current box has a value before decrementing selectedIndex
                if (pinTemp[selectedIndex] !== '') {
                    pinTemp[selectedIndex] = '';
                } else if (selectedIndex > 0) {
                    setSelectedIndex(selectedIndex - 1);
                    pinTemp[selectedIndex - 1] = '';
                }

                return pinTemp;
            });
        }
        else if (e.key === 'Delete' && selectedIndex > 0) {
            setPin((prevPin) => {
                const pinTemp = [...prevPin];
                pinTemp[selectedIndex] = '';
                // setSelectedIndex(selectedIndex - 1);
                return pinTemp;
            });
        }

    };

    const handlePinCompletion = (pin) => {
        setShowAlert(false);
        if (pin.length > 4) {
            var userInfo = getUserInformation();
            if (userInfo.PinNumber === Number(pin)) {
                if (props.successFunction && typeof props.successFunction === 'function') {
                    props.successFunction();
                }
                else {
                    window.location.reload()
                }

            } else {
                setShowAlert(true);

            }
        } else {
            setShowAlert(false);
        }
        return;
    };



    return (
        ShowModal ?
            <div className="fixed z-50 inset-0 overflow-y-auto " >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div onClick={() => { if (!props?.CloseBlocked) { (props?.setShowModal && props?.setShowModal(false)); setShowModal(false); if (props?.setStateFunction && typeof props?.setStateFunction === 'function') { props?.setStateFunction(false); } } }} style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                        <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                    {/* <Draggable> */}
                    <div className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >

                        <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                            <svg className={(props?.CloseBlocked ? 'hidden' : '') + ' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                                onClick={() => { (props?.setShowModal && props?.setShowModal(false)); setShowModal(false); if (props?.setStateFunction && typeof props?.setStateFunction === 'function') { props?.setStateFunction(false); } }}
                                width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                            </svg>
                            <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" > {props.title}  </h3>
                            {/* <h3 className="text-lg leading-6 font-semibold text-gray-900" id="modal-headline" > Saving Bloods  </h3> */}

                            <div className=' my-3 w-full items-center bg-gray-100 p-3 my-2  rounded-lg' onMouseDown={(e) => e.stopPropagation()}>
                                {/* <div className='flex items-center'>
                                </div> */}
                                <p className='leading-6 text-sbase items-center text-center pb-3 font-semibold' >Enter your PIN to continue..</p>
                                {/* <input type='password' name='PIN' placeholder='Enter PIN' autoFocus={true} maxLength={5} onChange={handlePinChange} className={'rounded w-full bg-white px-2 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none '} /> */}
                                <div className="flex justify-center Items-center space-x-2">
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <input
                                            key={index}
                                            type="password"
                                            name={`PIN-${index + 1}`}
                                            placeholder="-"
                                            maxLength={1}
                                            style={{ caretColor: 'transparent' }}
                                            value={pin[index]}
                                            // disabled={true}
                                            onKeyUp={(e) => handlePinChange(e)}
                                            autoFocus={selectedIndex == index}
                                            // onChange={(e) => handlePinChange(e.target.value, index)}
                                            className={theme.txt_color + ' ' + (selectedIndex == index ? "border " + theme.borderColor : "") + ` inputs ring-0 rounded w-10 h-10 bg-white px-2 py-2 text-xl font-semibold shadow-sm  hover:bg-gray-50 text-center  ${showAlert ? 'animate-pulse  border border-red-300 ' : ''}`}
                                        // ref={inputRefs[index]}
                                        />
                                    ))}
                                </div>

                                {showAlert && (
                                    <div className="buzz-alert text-red-600 text-sm text-center mt-3">
                                        Wrong password! Please try again.
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                    {/* </Draggable> */}

                </div>
            </div>
            : ''
    );
};

export default PinModal;
