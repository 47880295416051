import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ApiCall_POST, ApiCall_GET } from '../../../../../../../Redux/Generic/action';
import { useAppContext } from '../../../../../../../AppContextProvider ';
import { calculateBMIpatient, getMonthsDropdown, getPatientInformation, getYearsDropdown, preventInvalidNumberInput } from '../../../../../../../common';
import Swal from 'sweetalert2';


export const Anzard = ({ artId, ApiCall_GET, ApiCall_POST }) => {
    const patientInfo = getPatientInformation()

    const { themeDetails, setThemeDetails } = useAppContext({});
    const [formData, setFormData] = useState({})

    useEffect(() => {
        ApiCall_GET('Patient/GetAnzardDataDetails/' + artId + '/' + patientInfo.coupleId, true).then(function (payload) {
            payload.partnerBMI = calculateBMIpatient(payload.partnerHeightCentimetres, payload.partnerWeightKilograms);
            payload.patientBMI = calculateBMIpatient(payload.patientHeightCentimetres, payload.patientWeightKilograms);
            setFormData(payload)
        })
    }, [])

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        if (name === "previousTreatment_IUI_NumberOf") {
            setFormData(prevFormData => ({
                ...prevFormData,
                ['previousTreatment_IUI']: value,
            }));
        }
        if (name === "previousTreatment_IVF_NumberOf") {
            setFormData(prevFormData => ({
                ...prevFormData,
                ['previousTreatment_IVF']: value,
            }));
        }
        if (name === "previousTreatment_FET_NumberOf") {
            setFormData(prevFormData => ({
                ...prevFormData,
                ['previousTreatment_FET']: value,
            }));
        }

        if (name === 'partnerHeightCentimetres') {
            let bmi = calculateBMIpatient(value, formData.partnerWeightKilograms);
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                partnerBMI: bmi,
            }));
        }
        else if (name === 'partnerWeightKilograms') {
            let bmi = calculateBMIpatient(formData.partnerHeightCentimetres, value);
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                partnerBMI: bmi,
            }));
        }
        else if (name === 'patientHeightCentimetres') {
            let bmi = calculateBMIpatient(value, formData.patientWeightKilograms);
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                patientBMI: bmi,
            }));
        }
        else if (name === 'patientWeightKilograms') {
            let bmi = calculateBMIpatient(formData.patientHeightCentimetres, value);
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                patientBMI: bmi,
            }));
        } else if (type === 'radio') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value === 'on' ? true : false,
            }));
        }
        else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    }
    const saveAnzardData = () => {
        ApiCall_POST('Patient/SaveAnzardData', formData, true).then(function (payload) {
            Swal.fire({
                icon: 'success',
                title: 'Information has been saved.',
                showConfirmButton: false,
                timer: 1500
            })
            window.location.reload();
        })
    }


    return (
        <div className='w-full bg-gray-200 shadow px-3 py-2'>
            <div className='w-full bg-gray-200 shadow p-2 mb-2'>
                <label className={' font-bold  ' + themeDetails.txt_color + ' border-b-[3px] border-b-amber-500 '}>Patient/Couple Previous Cycles</label>
                <p className='py-2 text-sm  font-bold'>Previous Cycles External to City Fertility Group</p>
                <div className='flex w-full gap-x-2 '>
                    <div className='w-1/4 flex flex-wrap rounded shadow border border-gray-300 justify-between items-center p-2 border-r'>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >IVF Stim</p>
                            <input name='previoustreatment_IVF_NumberOf'
                                value={formData.previoustreatment_IVF_NumberOf}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3  items-center'}>
                            <p className={" text-sm mx-2    "} >When</p>
                            <input name='previousTreatment_IVF_When'
                                value={formData.previousTreatment_IVF_When}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >Where</p>
                            <input name='previousTreatment_IVF_Where'
                                value={formData.previousTreatment_IVF_Where}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                    </div>
                    <div className='w-1/4 flex flex-wrap shadow rounded border border-gray-300 justify-between items-center p-2 border-r'>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >Thaw</p>
                            <input name='previoustreatment_FET_NumberOf'
                                value={formData.previoustreatment_FET_NumberOf}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3  items-center'}>
                            <p className={" text-sm mx-2    "} >When</p>
                            <input name='previousTreatment_FET_When'
                                value={formData.previousTreatment_FET_When}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >Where</p>
                            <input name='previousTreatment_FET_Where'
                                value={formData.previousTreatment_FET_Where}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                    </div>
                    <div className='w-1/4 flex flex-wrap shadow border rounded border-gray-300 justify-between items-center p-2 border-r'>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >IUI</p>
                            <input name='previoustreatment_IUI_NumberOf'
                                value={formData.previoustreatment_IUI_NumberOf}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3  items-center'}>
                            <p className={" text-sm mx-2    "} >When</p>
                            <input name='previousTreatment_IUI_When'
                                value={formData.previousTreatment_IUI_When}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >Where</p>
                            <input name='previousTreatment_IUI_Where'
                                value={formData.previousTreatment_IUI_Where}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                    </div>

                    <div className='w-1/4 flex flex-wrap shadow border rounded border-gray-300 justify-between items-center p-2 border-r'>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >FOC</p>
                            <input name='previoustreatment_FOC_NumberOf'
                                value={formData.previoustreatment_FOC_NumberOf}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3  items-center'}>
                            <p className={" text-sm mx-2    "} >When</p>
                            <input name='previousTreatment_FOC_When'
                                value={formData.previousTreatment_FOC_When}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                        <div className={'w-1/3 items-center'}>
                            <p className={" text-sm mx-2    "} >Where</p>
                            <input name='previousTreatment_FOC_Where'
                                value={formData.previousTreatment_FOC_Where}
                                onChange={(e) => { handleInputChange(e); }}
                                className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex bg-gray-200  '>
                <div className='w-1/3 shadow rounded p-2'>
                    <label className={' font-bold  ' + themeDetails.txt_color + ' border-b-[3px] border-b-amber-500 '}>Patient/Couple Statistics</label>
                    <p className='pt-2 text-sm  font-bold'>Previous Cycles External to City Fertility Group</p>
                    <div className=' w-full pl-2'>
                        <p className=' text-sm  font-bold'>Patient</p>
                        <div className='w-full flex flex-wrap  justify-between items-center '>
                            <div className={'w-1/3 items-center'}>
                                <p className={" text-sm mx-2    "} >Height(cm)</p>
                                <input
                                    type='number'
                                    onKeyDown={preventInvalidNumberInput}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    value={formData.patientHeightCentimetres}
                                    name="patientHeightCentimetres"
                                    className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                />

                            </div>
                            <div className={'w-1/3  items-center'}>
                                <p className={" text-sm mx-2    "} >Weight(kg)</p>
                                <input
                                    type='number'
                                    onKeyDown={preventInvalidNumberInput}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    value={formData.patientWeightKilograms}
                                    name="patientWeightKilograms"

                                    className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                />   </div>
                            <div className={'w-1/3 items-center'}>
                                <p className={" text-sm mx-2    "} >BMI</p>
                                <input
                                    type='text'
                                    name='patientBMI'
                                    disabled={true}
                                    value={formData.patientBMI}
                                    className={themeDetails.cheqColor + ' w-[96%] px-2 text-sm text-gray-700 '} />
                            </div>
                        </div>
                        <p className='pt-2 text-sm  font-bold'>Partner</p>
                        <div className='w-full flex flex-wrap  justify-between items-center '>
                            <div className={'w-1/3 items-center'}>
                                <p className={" text-sm mx-2    "} >Height(cm)</p>
                                <input
                                    type='number'
                                    onKeyDown={preventInvalidNumberInput}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    value={formData.partnerHeightCentimetres}
                                    name="partnerHeightCentimetres"

                                    className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                />  </div>
                            <div className={'w-1/3  items-center'}>
                                <p className={" text-sm mx-2    "} >Weight(kg)</p>
                                <input
                                    type='number'
                                    onKeyDown={preventInvalidNumberInput}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    value={formData.partnerWeightKilograms}
                                    name="partnerWeightKilograms"

                                    className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none '}
                                />  </div>
                            <div className={'w-1/3 items-center'}>
                                <p className={" text-sm mx-2    "} >BMI</p>


                                <input
                                    type='text'
                                    name='partnerBMI'
                                    value={formData.partnerBMI}
                                    disabled={true}
                                    onChange={(e) => { handleInputChange(e); }}
                                    className={themeDetails.cheqColor + ' w-[96%] px-2 text-sm text-gray-700 '} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-1/3 shadow rounded p-2'>
                    <label className={' font-bold  ' + themeDetails.txt_color + ' border-b-[3px] border-b-amber-500 '}>Patient/Couple Information</label>
                    <div className=' w-full flex justify-between p-2'>
                        <div className='w-1/2 justify-between items-center '>
                            <p className=' text-sm  font-bold'>Duration of Infertility</p>
                            <div className='flex'>
                                <div className={'w-1/2 items-center'}>
                                    <p className={" text-sm mx-2    "} >Month</p>
                                    <select className={' rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} name={'durationMonth'} value={formData.durationMonth} onChange={e => handleInputChange(e)}>
                                        {getMonthsDropdown()}
                                    </select>
                                </div>
                                <div className={'w-1/2  items-center'}>
                                    <p className={" text-sm mx-2 "} >Year</p>
                                    <select
                                        className={' rounded  px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '}
                                        name={'durationOfInfertilityYears'} value={formData.durationOfInfertilityYears} onChange={e => handleInputChange(e)}>
                                        {getYearsDropdown()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/2 justify-between items-center '>
                            <p className=' text-sm  font-bold'>Pregnancy External to CFC Group</p>
                            <div className='flex'>
                                <div className={'w-1/2 items-center'}>
                                    <p className={" text-sm mx-2    "} >{'>'} 20 Preg</p>
                                    <input name='greater'
                                        value={formData.greater}
                                        onChange={(e) => { handleInputChange(e); }}
                                        className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                                <div className={'w-1/2  items-center'}>
                                    <p className={" text-sm mx-2    "} >{'<'} 20 Preg</p>
                                    <input name='lesser'
                                        value={formData.lesser}
                                        onChange={(e) => { handleInputChange(e); }}
                                        className={themeDetails.cheqColor + ' w-[96%] shadow border rounded rounded px-2 text-sm text-gray-700 focus:border-teal-500 focus:outline-none  '} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-wrap  justify-between items-center '>
                        <div className={'w-full items-center'}>
                            <p className={" text-sm mx-2 "} >Intending to get Pregnant this cycle</p>
                            <div className={' flex mt-2'}>
                                <div className={'flex items-center  ml-4 flex items-center cursor-pointer'}>
                                    <input type='radio' id="rdioPre"
                                        onChange={(e) => { handleInputChange(e) }}
                                        checked={formData.patientIntendPregThisCycle == true}
                                        className={themeDetails.cheqColor + ' border focus:outline-none'}
                                        name={"patientIntendPregThisCycle"} />
                                    <label htmlFor="PatientIntendPregThisCycleYes" className=" ml-2 text-sm text-gray-800 dark:text-gray-600" >Yes</label>
                                </div>


                                <div className={'flex items-center  ml-4 flex items-center cursor-pointer'}>
                                    <input type='radio' id="rdioPreThis"
                                        onChange={(e) => { handleInputChange(e) }}
                                        value={false}
                                        checked={formData.patientIntendPregThisCycle == false}
                                        className={themeDetails.cheqColor + ' border focus:outline-none'}
                                        name={"patientIntendPregThisCycle"} />
                                    <label htmlFor="PatientIntendPregThisCycleNo" className=" ml-2 text-sm text-gray-800 dark:text-gray-600" >No</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-1/3 shadow rounded p-2'>
                    <label className={' font-bold  ' + themeDetails.txt_color + ' border-b-[3px] border-b-amber-500 '}>ANZARD Completion</label>
                    <div className='w-full flex flex-wrap  justify-between items-center '>
                        <div className={'w-full items-center'}>
                            <p className={" text-sm mx-2    "} >ANZARD Completed for Cycle</p>
                            <div className={' flex mt-2'}>
                                <div className={'flex items-center  ml-4 flex items-center cursor-pointer'}>
                                    <input type='radio' id="rdioYes"
                                        onChange={(e) => { handleInputChange(e) }}
                                        checked={formData.anzardCompletedForArtid === true}
                                        className={themeDetails.cheqColor + ' border focus:outline-none'}
                                        name={"anzardCompletedForArtid"} />
                                    <label htmlFor="rdioYes" className=" ml-2 text-sm text-gray-800 dark:text-gray-600" >Yes</label>
                                </div>
                                <div className={'flex items-center  ml-4 flex items-center cursor-pointer'}>
                                    <input type='radio' id="rdioNo"
                                        onChange={(e) => { handleInputChange(e) }}
                                        checked={formData.anzardCompletedForArtid === false}
                                        value={false}
                                        className={themeDetails.cheqColor + ' border focus:outline-none'}
                                        name={"anzardCompletedForArtid"} />
                                    <label htmlFor="rdioNo" className=" ml-2 text-sm text-gray-800 dark:text-gray-600" >No</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=' w-full  justify-between p-2'>
                        <p className=' text-sm  font-bold'>Comments</p>
                        <textarea
                            onChange={(e) => { handleInputChange(e) }}
                            name='comments'
                            value={formData.comments}
                            style={{
                                height: 'fit-content', // Let the textarea adjust its height based on content
                                minHeight: 'fit-content', // Minimum height to fit content
                                resize: 'none', // Prevent manual resizing by the user
                            }}
                            rows={3} className='text-xss text-left bg-gray-100 font-medium w-full rounded p-1 disabled  scrollbar-thin' />


                    </div>
                </div>

            </div>
            <div className='flex justify-end p-2 border shadow'>
                <button className="bg-transparent ml-5 hover:bg-green-600 text-green-800 font-semibold text-sm hover:text-white py-1 px-2 border border-green-500 hover:border-transparent rounded"
                    onClick={() => { saveAnzardData() }}
                >  Save </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET, ApiCall_POST }

export default connect(mapStateToProps, mapDispatchToProps)(Anzard)