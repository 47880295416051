import { getUserDetailsWithBaseUrl } from "./Redux/Auth/action";

let globalIsLoading = false;
export var baseApiUrl = "";
if (window.location.hostname === 'localhost') {
    baseApiUrl = "https://localhost:44313/api/";
}
else if (window.location.hostname === "staging.chaart.cityfertility.com.au") {
    baseApiUrl = "https://staging.api.chaart.cityfertility.com.au/api/"; // staging 1
}
else if (window.location.hostname === "cf.artdesk.ui.net.au") {
    baseApiUrl = "http://cf.artdesk.api.net.au/api/"; // staging 2
}
else if (window.location.hostname === 'chaart.cityfertility.com.au') {
    baseApiUrl = "https://chaart.api.cityfertility.com.au/api/" // Production
}
else {
    baseApiUrl = "http://154.57.216.235:1122/api/";// Pak Server
}

export const setGlobalIsLoading = (value) => {
    globalIsLoading = value;
};

export const getGlobalIsLoading = () => globalIsLoading;

export async function setSessions(url) {
    const userid = extractUserIdFromUrl(url);
    const sessionUser = getSession('user');

    if (sessionUser && sessionUser.UserId === userid) {
        return sessionUser;
    }

    if (userid) {
        try {
            const session = await setUserSession(userid);

            return session;
        } catch (error) {
            // Handle the error, e.g., redirect to an error page or display an error message
            return null;
        }
    } else {
        return null;
    }
}


export function setSession(sessionName, Data) {
    sessionStorage.setItem(sessionName, JSON.stringify(Data));

}

export function getSession(sessionName) {
    var Obj = JSON.parse(sessionStorage.getItem(sessionName));
    return Obj;
}

export function resetSession(sessionName) {
    sessionStorage.setItem(sessionName, null);
}

function extractUserIdFromUrl(url) {
    const urlObj = new URL(url);
    const urlParams = new URLSearchParams(urlObj.search);
    const userId = urlParams.get('userid');
    return userId;
}

export function extractVarFromUrl(url, Var) {
    const urlObj = new URL(url);
    const urlParams = new URLSearchParams(urlObj.search);
    const varVal = urlParams.get(Var);
    return varVal;
}


function setUserSession(userid) {
    // Return a Promise that resolves when the user details are fetched and set
    return new Promise((resolve, reject) => {
        getUserDetailsWithBaseUrl(baseApiUrl, userid)
            .then((payload) => {
                setSession('user', payload);
                resolve(payload); // Resolve the Promise with user data
            })
            .catch((error) => {
                reject(error); // Reject the Promise with an error
            });
    });
}

export const UserType = {
    Administrator: 1,
    Doctor: 2,
    Nurse: 3,
    Scientist: 4,
    MedicalLiaison: 5,
    CorporateUser: 6,
    CallCentre: 7,
    Counsellor: 8,
    DoctorConsultantAdmin: 9,
    ClinicalAdmin: 10,
    PatientServices: 11,
    CorporateManagement: 12
};

export function getUserType() {
    var user = getSession('user')
    return Object.keys(UserType).find(key => UserType[key] === user.UserTypId);
}


export function getUserTypeId() {
    var user = getSession('user')
    return user.UserTypId;
}

export function isUserPermited(userType) {
    var user = getSession('user')
    return (user && user.UserTypId === userType) || false;
}