import {
    getPatientSuccess, getPatientFail

} from "../constants";

export const initialState = {
    user: null,
    tokens: {},
    userCreated: false,
    passwordUpdate: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case getPatientSuccess:
            return {
                ...state,
                patient: action.payload,
            };
        case getPatientFail:
            return {
                ...state,
                patient: action.payload.message,
            };
        default:
            return state;
    }

}