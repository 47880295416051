import React from 'react'
import logoimg from '../../../Assets/images/cc.png'
import { getClinicianBgColor, getCorrectDate, getCorrectDateForInput, getCycleTypeColor, getTextColor } from '../../../common';
import moment from 'moment'
import { useAppContext } from '../../../AppContextProvider ';

export default function SideBarSearchItem(props) {
    const theme = useAppContext().themeDetails;
    const hoverIn = (id, clinicianName) => {
        const element = document.getElementById("tooltip_" + id);
        if (element) {
            element.innerHTML = "<p>" + clinicianName + "</p>";
            element.classList.remove("hidden");
        }
    }

    const hoverOut = (id) => {

        const element = document.getElementById("tooltip_" + id);
        if (element) {
            element.innerHTML = "";
            element.classList.add("hidden");
        }
    }
    return (
        <div className={(props.isSelected ? theme.navbar : " ") + " flex items-center px-2 py-1 mt-0.5 rounded  drop-shadow-lg " + (!props.sortOrder && ' bg-white ') + ((props.sortOrder == 1 || props.sortOrder == 1.0 || props.sortOrder == 1.1) && ' bg-green-100 ') + ((props.sortOrder == 2 || props.sortOrder == 2.0 || props.sortOrder == 2.1) && ' bg-yellow-100 ') + ((props.sortOrder == 3 || props.sortOrder == 3.0 || props.sortOrder == 3.1) && ' bg-orange-100 ') + ((props.sortOrder == 4 || props.sortOrder == 4.0 || props.sortOrder == 4.1) && ' bg-blue-100 ')} >
            <div className="flex-none">
                {props.isSelected ? (
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill={(!props.isSelected ? theme.iconColor : "white")} />
                    </svg>
                ) : (
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.07143 8.00558C7.11335 8.00558 8.1126 7.59168 8.84935 6.85493C9.5861 6.11818 10 5.11893 10 4.07701C10 3.03509 9.5861 2.03584 8.84935 1.29909C8.1126 0.56234 7.11335 0.148438 6.07143 0.148438C5.02951 0.148438 4.03026 0.56234 3.29351 1.29909C2.55676 2.03584 2.14286 3.03509 2.14286 4.07701C2.14286 5.11893 2.55676 6.11818 3.29351 6.85493C4.03026 7.59168 5.02951 8.00558 6.07143 8.00558ZM15 8.00558C15.7578 8.00558 16.4845 7.70456 17.0203 7.16874C17.5561 6.63293 17.8571 5.9062 17.8571 5.14844C17.8571 4.39068 17.5561 3.66395 17.0203 3.12813C16.4845 2.59231 15.7578 2.29129 15 2.29129C14.2422 2.29129 13.5155 2.59231 12.9797 3.12813C12.4439 3.66395 12.1429 4.39068 12.1429 5.14844C12.1429 5.9062 12.4439 6.63293 12.9797 7.16874C13.5155 7.70456 14.2422 8.00558 15 8.00558ZM2.14286 9.43415C1.57454 9.43415 1.02949 9.65992 0.627628 10.0618C0.225765 10.4636 0 11.0087 0 11.577V11.6842C0 11.6842 0 15.8627 6.07143 15.8627C12.1429 15.8627 12.1429 11.6842 12.1429 11.6842V11.577C12.1429 11.0087 11.9171 10.4636 11.5152 10.0618C11.1134 9.65992 10.5683 9.43415 10 9.43415H2.14286ZM15 14.4342C13.96 14.4342 13.1371 14.2927 12.485 14.0677C12.7936 13.5677 12.9707 13.0856 13.0707 12.6963C13.1436 12.4165 13.1898 12.1305 13.2086 11.842C13.21 11.8134 13.2114 11.7877 13.2114 11.7656L13.2121 11.7356V11.577C13.2134 10.7863 12.9221 10.023 12.3943 9.43415H18.2807C18.5065 9.43406 18.73 9.47844 18.9386 9.56477C19.1472 9.65109 19.3367 9.77767 19.4964 9.93726C19.656 10.0969 19.7827 10.2863 19.8691 10.4949C19.9555 10.7034 20 10.927 20 11.1527C20 11.1527 20 14.4342 15 14.4342Z" fill={(!props.isSelected ? theme.iconColor : "white")} />
                    </svg>
                )}
            </div>
            <div className="flex-auto pl-1">
                <p className={props.isSelected ? "text-xss font-medium textOverflow leading-3 text-white" : "text-xss font-medium textOverflow leading-3 text-black"}>{props.surname}, {props.firstName}</p>
                <p className={props.isSelected ? "text-xss textOverflow leading-3 text-gray-300" : "text-xss textOverflow leading-3 text-gray-500"}>{props.partnerSurName}, {props.partnerFirstName}</p>
                <p className={props.isSelected ? "text-xs textOverflow leading-3 text-gray-300" : "text-xs textOverflow leading-3 text-gray-500"}>{props.couplecode}</p>
            </div>
            <div className="flex-auto pl-1 w-16 ">
                <p className={props.isSelected ? "text-xs font-medium textOverflow text-white mb-2" : "text-xs font-medium textOverflow text-black mb-2"}><span className='text-gray-300'>DOB   </span> {moment(getCorrectDateForInput(props.dob)).format('DD-MMM-yyyy')}</p>
                <div className="flex items-center justify-end ">
                    {props.clinicianInitials && props.clinicianInitials != "-" ? (
                        <>
                            <div onMouseEnter={() => hoverIn(props.rowNo, props.clinicianName)} onMouseLeave={() => hoverOut(props.rowNo, props.clinicianName)}
                                className='flex mx-0.5 border' >
                                <p className='text-xss m-0 px-0.5 ' style={{ backgroundColor: getClinicianBgColor(props.clinicianColor).split(',')[0], color: getTextColor(getClinicianBgColor(props.clinicianColor).split(',')[0]) }} >{props.clinicianInitials.split('')[0]}</p>
                                <p className='text-xss m-0 px-0.5 ' style={{ backgroundColor: getClinicianBgColor(props.clinicianColor).split(',')[1], color: getTextColor(getClinicianBgColor(props.clinicianColor).split(',')[1]) }} >{props.clinicianInitials.split('')[1]}</p>
                            </div>
                            {props.clinicianName && <div className={theme.borderColor + "   z-10 text-xss font-bold top-0 right-0 max-w-xs shadow absolute hidden inline-block  p-1 text-xs font-medium text-gray-900 bg-gray-200 border  rounded-lg shadow-sm tooltip"} id={"tooltip_" + props.rowNo}>
                            </div>}
                        </>
                    ) : ''}
                    {props.Chartinglabel && <div className={theme.borderColor + "   z-10 text-xss font-bold top-0 right-0 max-w-xs shadow absolute hidden inline-block  p-1 text-xs font-medium text-gray-900 bg-gray-200   rounded-lg shadow-sm tooltip"} id={"tooltip_" + props.rowNo}>
                    </div>}

                    {props.cycleType === "---" ? '' : (
                        <svg width="17" height="15" viewBox="0 0 14 15" className='flex mx-0.5' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.148438" width="14" height="14" rx="2" fill={getCycleTypeColor(props.cycleType)} />
                            <g filter="url(#filter0_i_0_1)">
                                <path d="M1.04001 12.7813V7.89844H13.0399V12.7734C13.0399 12.7734 13.0399 13.5234 12.0799 13.5234H2C1.04001 13.5234 1.04001 12.7813 1.04001 12.7813Z" fill="#E1E8ED" />
                            </g>
                            <text x="2" y="7" className='text-xs7' fill={getTextColor(getCycleTypeColor(props.cycleType))}>{props.cycleType}</text>
                            <defs>
                                <filter id="filter0_i_0_1" x="1.04004" y="7.89844" width="11.9998" height="5.625" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="0.5" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.556863 0 0 0 0 1 0 0 0 0.5 0" />
                                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
                                </filter>
                            </defs>
                        </svg>
                    )}
                    <div onMouseEnter={() => hoverIn(props.rowNo, props.Chartinglabel)} onMouseLeave={() => hoverOut(props.rowNo, props.Chartinglabel)} className={`logoImg shadow flex-none icon_16_${props.BrandCode}`}></div>
                </div>
            </div>
        </div >
    )
}
