import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../../AppContextProvider ';
import { getSession } from '../../../../../../session';
import { ApiCall_GET } from '../../../../../../Redux/Generic/action'
import { formatTime, getCorrectDate, getCorrectDateForInput, getUserInformation, replaceEmptyObjectsWithNull } from '../../../../../../common';
import logo from '../../../../../../Assets/images/white.png';
import moment from 'moment'
import { getByTitle } from '@testing-library/react';

export const AndrologyResultViewPrint = ({ andrologytypeid, andrologyid, couple, andrology, ReferenceRange, DBNbr }) => {
    const theme = useAppContext().themeDetails;
    const user = getUserInformation()
    const getTitle = (andrologytypeid) => {
        var iconSuffix = ''
        switch (andrologytypeid) {
            case 1: iconSuffix = "Semen Analysis"; break;
            case 2: iconSuffix = "Suitability for ART"; break;
            case 4: iconSuffix = "Intrauterine Insemination"; break;
            case 5: iconSuffix = "Semen Freeze Report"; break;
            case 6: iconSuffix = "Sperm For IVF"; break;
            default: iconSuffix = "unknown"; break;
        }
        return iconSuffix
    };

    return (


        <div id="divPrint" className=" flex min-h-screen  w-full  delay-900 duration-700 pagebreak ">
            {/* Header */}
            <div className="relative w-full  mx-auto p-3">
                <div className={` ${theme.navbar} ` + 'flex justify-between   mb-2  p-2'} >

                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='160px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className=' text-white  text-sm ml-1'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                    </div>

                </div>

                <label className={theme.txt_color + " text-xl  leading-6 font-bold text-gray-900"}>Andrology Report :
                    {getTitle(andrologytypeid)}
                </label>
                <div className='w-full mt-2 shadow mr-1 mt-3 mb-3'>
                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>

                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex items-center  py-1  border-b-[1px]  border-b-gray-200    w-1/2 '>
                            <p className=' font-bold  text-sm text-gray-500'>Clinician</p>
                            {/* <p className={'pl-2 text-sm text-gray-500  '}> {couple?.clinicianName}</p> */}
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {andrology?.clinicianName || couple?.clinicianName}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>Ph: </b>
                                    {andrology?.clinicianphone}
                                </p>
                            </div>
                        </div>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <p className=' text-sm  w-24  font-bold  text-sm text-gray-500'>Couple Code</p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.coupleCode}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>Andrology ID </b>
                                    {andrologyid}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer  font-bold  text-sm text-gray-500 '>{andrologytypeid !== 4 ? "Patient" : "Partner"} </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500 flex items-center gap-1 '}>
                                    {couple?.partnerFullName}
                                    {couple?.partnerGender === 'F' ?
                                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#FE9696" />
                                        </svg>
                                        :
                                        <svg width="15" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#A9C6FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    }

                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>DOB: </b>{couple?.partnerDOB}   <b>({couple?.partnerAge.split(',')[0]}) </b>
                                </p>
                            </div>
                        </div>

                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <div className='flex  flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500 flex items-center gap-3 '}><b>Scientist: </b>{andrology?.sciName}</p>
                            </div>
                        </div>
                    </div>
                    {andrologytypeid == 4 && <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer  font-bold  text-sm text-gray-500 '>Patient </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500 flex items-center gap-1 '}>
                                    {couple?.patientFullName}
                                    {couple?.patientGender === 'F' ?
                                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#FE9696" />
                                        </svg>
                                        :
                                        <svg width="15" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#A9C6FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    }

                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>DOB: </b>{couple?.patientDOB}   <b>({couple?.patientResAge.split(',')[0]}) </b>
                                </p>
                            </div>
                        </div>

                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <div className='flex  flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500 flex items-center gap-3 '}><b>Sample # </b>{andrology?.sample_no}</p>
                            </div>
                        </div>
                    </div>}

                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <div className='flex  flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>Mobile : </b>
                                    {couple?.PartnerPhoneMobile}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>Home : </b>
                                    {couple?.PartnerPhoneHome}
                                </p>
                            </div>
                        </div>
                        <div className=' text-left  mx-2 flex  py-1    w-1/2 '>
                            <p className=' text-sm font-medium w-1/3 text-gray-500'><b>Patient Addres : </b></p>
                            <p className={'pl-2 text-sm text-gray-500 text-end w-2/3'}> {couple?.partnerAddress}</p>
                        </div>
                    </div>
                    <div className='flex w-full'>
                    </div>
                </div>
                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>

                    {andrologytypeid == 4 ?
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Patient </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.patientFullName}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b>{couple?.patientDOB}
                                    </p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700 w-24'>Sample #</p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {andrology?.sample_no}
                                    </p>
                                </div>
                            </div>
                        </div>
                        : ''}

                    <div className='flex w-full'>
                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer text-sbase font-medium   text-gray-700'>{andrologytypeid == 4 ? 'Partner' : 'Patient'} </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.partnerFullName}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>DOB: </b>{couple?.partnerDOB}
                                </p>
                            </div>
                        </div>
                        {/* <div className=' text-left  flex items-center py-1 gap-x-2 border-b-[1px] bg-red-300  border-b-gray-200   w-1/2 '>
                            <div className='flex  flex-wrap justify-between items-center w-1/2'>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700 w-24'>Couple Code</p>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.coupleCode}
                                </p>
                              
                            </div>
                            <div className='flex flex-wrap justify-between items-center w-1/2'>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700 w-24'>Andrology ID :</p>

                                <p className={'text-sm text-gray-500  '}>
                                    {andrologyid}
                                </p>
                              
                            </div>
                        </div> */}

                        <div className='flex items-center justify-between gap-x-2 border-b-[1px]  border-b-gray-200  w-1/2 '>
                            <div className='flex  flex-wrap justify-between items-center  w-1/2'>
                                <p className='cursor-pointer text-sbase font-medium  text-gray-700 w-24'>Couple Code</p>
                                <p className={'text-sm text-gray-500  '}>{couple?.coupleCode}</p>
                            </div>
                            <div className='flex  flex-wrap justify-between items-center  w-1/2'>
                                <p className=' text-sbase font-medium   text-gray-700 w-24'>Andrology ID :</p>
                                <p className={'text-sm text-gray-500  '}>{andrologyid}</p>
                            </div>
                        </div>
                    </div>


                    <div className='flex w-full'>

                        <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-full '>
                            <p className=' text-sm font-medium   text-gray-700'>Address</p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  '}>
                                    {couple?.partnerAddress}
                                </p>
                                <p className={'text-sm text-gray-500 flex gap-1 '}>
                                    <b>Phone (H): </b>{couple?.PartnerPhoneHome}
                                    <b>Phone (M): </b>{couple?.PartnerPhoneMobile}
                                </p>
                            </div>
                        </div>
                        {/* <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                            <p className=' text-sm font-medium w-24  text-gray-700'>Clinician</p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={'text-sm text-gray-500  flex gap-2 '}>
                                    {andrology?.clinicianName}
                                    <b>Ph: </b>{andrology?.clinicianphone}
                                </p>
                                <p className={'text-sm text-gray-500  '}>
                                    <b>Scientist: </b>{andrology?.sciName}
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t flex justify-between'}>Semen Assessment <i> ~Tests performed at Room Temperature</i></p>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px]  border-b-gray-200'>
                        <div className=' flex py-1 w-40 '>
                            <p className='text-sm font-medium text-gray-700'>Sample Date: </p>
                            <div className='pl-2 text-sm text-gray-500'>{getCorrectDate(andrology?.sampledate)}</div>
                        </div>
                        <div className=' flex py-1 w-40 '>
                            <p className='text-sm font-medium text-gray-700'>Sample #: </p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.sample_no}</div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center w-full px-2 border-b-[1px]  border-b-gray-200'>
                        <div className=' flex py-1 w-60'>
                            <p className='text-sm font-medium text-gray-700'>Time Produced:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.sa_time_prod}</div>
                        </div>
                        <div className=' flex py-1 w-48'>
                            <p className='text-sm font-medium text-gray-700'>Time Analysed:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.sa_time_anal}</div>
                        </div>
                        <div className=' flex py-1  w-40'>
                            <p className='text-sm font-medium text-gray-700'>Days Abstinence:</p>
                            <div className='pl-2 text-sm text-gray-500'>{andrology?.sa_days_abst}</div>
                        </div>
                    </div>
                    {andrologytypeid == 6 || andrologytypeid == 4 ?
                        <div className='flex justify-between items-center w-full px-2  border-b-[1px]  border-b-gray-200'>
                            <div className=' flex py-1 w-60'>
                                <p className='text-sm font-medium text-gray-700'>Semen Source:</p>
                                <div className='pl-2 text-sm text-gray-500'>{andrology?.iui_ytpe == "Partner" ? couple?.partnerFullName : andrology?.thedonorcode}</div>
                            </div>
                            <div className=' flex py-1 w-48'>
                                <p className='text-sm font-medium text-gray-700'>Type of Sperm:</p>
                                <div className='pl-2 text-sm text-gray-500'>{andrology?.iui_sperm_type}</div>
                            </div>
                            <div className=' flex py-1  w-40'>
                                <p className='text-sm font-medium text-gray-700'>Straws Used:</p>
                                <div className='pl-2 text-sm text-gray-500'>{andrology?.iui_donor_straws}</div>
                            </div>
                        </div>
                        : ''}


                </div>

                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <div className="noborder ">
                        <table className="w-full text-sm noborder" cellSpacing="0" cellPadding="3">
                            <thead>
                                <tr className={theme.tHedUpdte + ' rounded-md rounded-t'}>
                                    <th className="border-b border-gray-300 text-white"></th>
                                    <th className="border-b border-gray-300 text-white" colSpan="3" style={{ textAlign: 'center' }}><span className="titled">Result</span></th>
                                    <th className="border-b border-gray-300 text-white" style={{ textAlign: 'center' }}><span className="titled">Lower Reference Range *</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-gray-200 hover:bg-gray-300 cursor-pointer">
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;Volume</b></td>
                                    <td className="w-12"></td>
                                    <td className="w-12"><b>{andrology?.sa_volume}</b></td>
                                    <td className="w-20 text-right border-r border-gray-300">(ml)&nbsp;&nbsp;</td>
                                    <td className="text-center">{ReferenceRange?.VOLUME}</td>
                                </tr>
                                <tr className="hover:bg-gray-300 cursor-pointer">
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;PH</b></td>
                                    <td></td>
                                    <td><b>{andrology?.sa_ph}</b></td>
                                    <td className="text-right border-r border-gray-300">&nbsp;&nbsp;</td>
                                    <td className="text-center">{ReferenceRange.PH}</td>
                                </tr>
                                <tr className="bg-gray-200 hover:bg-gray-300 cursor-pointer">
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;Count</b></td>
                                    <td></td>
                                    <td><b>{andrology?.displaycount1 ? "< 0.1" : andrology?.sa_count}</b></td>
                                    <td className="text-right border-r border-gray-300">(x10<sup>6</sup>/ml)&nbsp;&nbsp;</td>
                                    <td className="text-center">{ReferenceRange.COUNT}</td>
                                </tr>
                                <tr className="hover:bg-gray-300 cursor-pointer">
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;Total Count per Ejaculate</b></td>
                                    <td></td>
                                    <td><b>{andrology?.displaycount1 ? "< 0.1" : (andrology?.sa_count * andrology?.sa_volume).toFixed(2)}</b></td>
                                    <td className="text-right border-r border-gray-300">(x10<sup>6</sup>)&nbsp;&nbsp;</td>
                                    <td className="text-center">{ReferenceRange.COUNTPEREJAC}</td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " bg-gray-200") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;Normal Morphology</b></td>
                                    <td></td>
                                    <td><b>{andrology?.sa_morphology === "" ? "" : andrology?.sa_morphology}</b></td>
                                    <td className="text-right border-r border-gray-300">(%)&nbsp;&nbsp;</td>
                                    <td className="text-center">{ReferenceRange.MORPHOLOGY}</td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;Motility</b></td>
                                    <td colSpan="3" className="border-r border-gray-300"></td>
                                    <td></td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " bg-gray-200") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Overall</b></td>
                                    <td></td>
                                    <td><b>{andrology?.sa_motility === "" ? "" : andrology?.sa_motility}</b></td>
                                    <td className="text-right border-r border-gray-300">(%)&nbsp;&nbsp;</td>
                                    <td className="text-center">{ReferenceRange.MOTILITY_OVERALL}</td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Progressive Motility</b></td>
                                    <td></td>
                                    <td><b>{andrology?.sa_grade2 === "" ? "" : andrology?.sa_grade2}</b></td>
                                    <td className="text-right border-r border-gray-300">(%)&nbsp;&nbsp;</td>
                                    <td className="text-center">{ReferenceRange.MOTILITY_PROGRESSIVE}</td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " bg-gray-200") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Non Progressive Motility</b></td>
                                    <td></td>
                                    <td><b>{andrology?.sa_grade1 === "" ? "" : andrology?.sa_grade1}</b></td>
                                    <td className="text-right border-r border-gray-300">(%)&nbsp;&nbsp;</td>
                                    <td className="text-center"></td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Immotile</b></td>
                                    <td></td>
                                    <td><b>{andrology?.sa_grade0 === "" ? "" : andrology?.sa_grade0}</b></td>
                                    <td className="text-right border-r border-gray-300">(%)&nbsp;&nbsp;</td>
                                    <td className="text-center"></td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " bg-gray-200") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;Total Motile Count</b></td>
                                    <td></td>
                                    <td><b>{andrology?.displaycount2 ? "< 0.1" : andrology?.sa_total_motile_count === "" ? "" : andrology?.sa_total_motile_count}</b></td>
                                    <td className="text-right border-r border-gray-300">(x10<sup>6</sup>)&nbsp;&nbsp;</td>
                                    <td className="text-center"></td>
                                </tr>
                                <tr className={(DBNbr(andrology?.sa_count) === 0 ? "text-green-100" : " ") + " hover:bg-gray-300 cursor-pointer"}>
                                    <td className="text-gray-700 border-r border-gray-300"><b>&nbsp;&nbsp;Total Progressive Motile Count</b></td>
                                    <td></td>
                                    <td><b>{andrology?.displaycount2 ? "< 0.1" : andrology?.sa_total_progressive_motile_count === "" ? "" : andrology?.sa_total_progressive_motile_count}</b></td>
                                    <td className="text-right border-r border-gray-300">(x10<sup>6</sup>)&nbsp;&nbsp;</td>
                                    <td className="text-center"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='w-full mt-2 shadow mr-1 mt-3 mb-2'>
                    <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Anti-Sperm Antibodies</p>
                    <div className='flex justify-between items-start w-full px-2 border-b-[1px]  border-b-gray-200'>
                        <div className=' flex py-1 w-1/2 '>
                            <p className='text-sm font-medium text-gray-700'>Anti-Sperm Antibodies: </p>
                            <div className='pl-2 text-sm text-gray-500'>
                                {!andrology?.sa_anti_sperm ? 'NOT PERFORMED' : andrology?.sa_anti_sperm + " % Sperm Bound"}
                                <p>(Direct IgG Immunobead Agglutination Test)</p>
                            </div>
                        </div>
                        <div className=' flex py-1 w-40 w-1/2'>
                            <p className='text-sm font-medium text-gray-700'>Result:</p>
                            <div className='pl-2 text-sm text-gray-500'>
                                {!andrology?.sa_anti_sperm ? 'N/A' : andrology?.sa_result + (andrology?.sa_result?.toUpperCase() == 'POSITIVE' ? ' (' + andrology?.sa_bindingsite + ')' : '')}
                            </div>
                        </div>

                    </div>
                </div>


                {andrologytypeid == 1 ?
                    <div className="w-full  shadow text-sm mr-1 mb-1 my-2">
                        <p className={theme.tHedUpdte + '   font-medium text-white text-left p-1  rounded-t'}>Sperm DNA Fragmentation Index</p>
                        <div className="flex items-center flex-wrap px-2">
                            <div className=' text-left   py-1    w-1/2  '>
                                <div className='w-full flex items-center'>
                                    <p className='cursor-pointer w-52 font-medium   text-gray-700'>DNA Fragmentation </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>{andrology?.DNA_Fragmentation_perc ? andrology?.DNA_Fragmentation_perc + ' %' : ''}</p>
                                    </div>
                                </div>
                                <div className='w-full flex items-center'>
                                    <p className='cursor-pointer w-52 font-medium   text-gray-700'>DNA Fragmentation Range </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>{andrology?.DNA_Fragmentation ? andrology?.DNA_Fragmentation : ''}</p>
                                    </div>
                                </div>
                            </div>

                            <div className=' text-left flex  py-1  w-1/2  '>
                                <p className='cursor-pointer  font-medium   text-gray-700'>Result </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={' text-gray-500  '}>{andrology?.DNA_Fragmentation_result?.toUpperCase()}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                    : andrologytypeid == 5 ?
                        <div className="w-full shadow text-sm mr-1 my-2">
                            <p className={theme.tHedUpdte + ' font-medium text-white text-left p-1 rounded-t'}>Post Freeze Analysis</p>
                            <div className="flex items-center py-1 flex-wrap px-2">
                                <div className='text-left flex w-1/2'>
                                    <p className='cursor-pointer w-40 font-medium text-gray-700'>Freeze Method</p>
                                    <p className={'text-gray-500'}>{andrology?.sf_freeze_method}</p>
                                </div>
                                <div className='text-left flex  w-1/2'>
                                    <p className='cursor-pointer w-44 font-medium text-gray-700'>Num Straws Frozen</p>
                                    <p className={'text-gray-500'}>{andrology?.sf_Straws_frozen}</p>
                                </div>
                                <div className='text-left flex  w-1/2'>
                                    <p className='cursor-pointer w-40 font-medium text-gray-700'>Storage Tank</p>
                                    <p className={'text-gray-500'}>{andrology?.storagetankname}</p>
                                </div>
                                <div className='text-left flex  w-1/2'>
                                    <p className='cursor-pointer w-44 font-medium text-gray-700'>Storage Location</p>
                                    <p className={'text-gray-500'}>{andrology?.storagelocationname}</p>
                                </div>
                                <div className='text-left  flex w-1/2'>
                                    <p className='cursor-pointer w-40 font-medium text-gray-700'>Goblet Colour</p>
                                    <p className={'text-gray-500'}>{andrology?.sf_goblit_colour}</p>
                                </div>
                                <div className='text-left flex w-1/2'>
                                    <p className='cursor-pointer w-44 font-medium text-gray-700'>Total Progressive Count / Straw</p>
                                    <p className={' pl-2 text-gray-500'}>{andrology?.displaycount4 ? "< 0.1" : andrology?.sf_final_motile?.toFixed(1)} (x10<sup>6</sup>)</p>
                                </div>
                                <div className='text-left flex  w-1/2'>
                                    <p className='cursor-pointer w-40 font-medium text-gray-700'>Volume</p>
                                    <p className={'text-gray-500'}>{andrology?.sf_volume?.toFixed(1)} (ml)</p>
                                </div>
                                <div className='text-left flex w-1/2'>
                                    <p className='cursor-pointer w-44 font-medium text-gray-700'>Count</p>
                                    <p className={'text-gray-500'}>{andrology?.displaycount3 ? "< 0.1" : andrology?.sf_count?.toFixed(1)} (x10<sup>6</sup>)</p>
                                </div>
                                <div className='text-left flex  w-1/2'>
                                    <p className='cursor-pointer w-40 font-medium text-gray-700'>Progressive Motility</p>
                                    <p className={'text-gray-500'}>{andrology?.sf_motility?.toFixed(1)} (%)</p>
                                </div>
                                <div className='text-left flex  w-1/2'>
                                    <p className='cursor-pointer w-44 font-medium text-gray-700'>Suitable For</p>
                                    <p className={'text-gray-500'}>
                                        {andrology?.postthawsuitIUI ? "IUI / " : ""}
                                        {andrology?.postthawsuitIVF ? "IVF / " : ""}
                                        {andrology?.postthawsuitFET ? "ICSI" : ""}
                                    </p>
                                </div>
                            </div>
                        </div>

                        :
                        <div className="w-full  shadow text-sm mr-1  my-2">
                            <p className={theme.tHedUpdte + '   font-medium text-white text-left p-1  rounded-t'}>Final Preparation</p>
                            <div className="flex items-center flex-wrap px-2">
                                <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer  font-medium   text-gray-700'>Volume </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>{andrology?.saart_volume ? andrology?.saart_volume?.toFixed(1) : '0.0'} (ml)</p>
                                    </div>
                                </div>

                                <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer  font-medium   text-gray-700'>Count </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>{andrology?.displaycount3 ? '< 0.1' : (andrology?.saart_count ? andrology?.saart_count.toFixed(1) : '0.0')} (x10<sup>6</sup>/ml)</p>
                                    </div>
                                </div>
                                <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer  font-medium w-36  text-gray-700'>Progressive Motility </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>{andrology?.saart_motility ? andrology?.saart_motility.toFixed(1) : '0.0'} (%)</p>
                                    </div>
                                </div>

                                <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer  font-medium w-60  text-gray-700'>Total Progressive Motile Count </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>{andrology?.displaycount4 ? '< 0.1' : (andrology?.saart_final_insem ? andrology?.saart_final_insem.toFixed(1) : '0.0')} (x10<sup>6</sup>)</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                }

                {andrology?.comments && <div className="w-full  shadow text-sm mr-1  my-2">
                    <p className={theme.tHedUpdte + '   font-medium text-white text-left p-1  rounded-t'}>Comments</p>
                    <textarea
                        style={{
                            // overflowY: 'hidden', // Hide scrollbar
                            resize: 'none', // Prevent manual resizing by the user
                        }}
                        disabled
                        rows={andrology?.comments && Math.max(andrology.comments.split('\n').length, +1)}
                        className='text-xss text-left  font-medium w-full p-1 disabled  scrollbar-thin'
                        value={andrology?.comments}
                    />
                </div>}

                <div className="w-full  shadow text-sm mr-1 mt-3  mb-2">
                    <div className="flex items-center flex-wrap px-2">
                        {andrologytypeid == 4 ?
                            <>
                                <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer  font-medium w-36  text-gray-700'>Collected By </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>____________________________________________________________</p>
                                    </div>
                                </div>

                                <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                    <p className='cursor-pointer  font-medium   text-gray-700'>Date </p>
                                    <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                        <p className={' text-gray-500  '}>____________________________________________________________</p>
                                    </div>
                                </div>
                            </>
                            : ''}
                        <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer  font-medium w-36  text-gray-700'>Report By </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={' text-gray-500  '}>{andrology?.reportBy ? andrology?.reportBy : ''}</p>
                            </div>
                        </div>

                        <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer  font-medium   text-gray-700'>Date </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={' text-gray-500   '}>{andrology?.reporton ? getCorrectDate(andrology?.reporton) + ' ' + formatTime(andrology?.reporton, true) : ''}</p>
                            </div>
                        </div>
                        <div className=' text-left flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer  font-medium w-36  text-gray-700'>Verified By </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                <p className={' text-gray-500  '}>{andrology?.verifyBy ? andrology?.verifyBy : ''}</p>
                            </div>
                        </div>

                        <div className=' text- flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                            <p className='cursor-pointer  font-medium   text-gray-700'>Date </p>
                            <div className='flex pl-2 flex-wrap justify-between items-center w-full  '>
                                <p className={' text-gray-500 '}>{andrology?.verifyon ? getCorrectDate(andrology?.verifyon) + ' ' + formatTime(andrology?.verifyon, true) : ''}</p>
                            </div>
                        </div>
                    </div>

                </div>






                <footer className='flex mt-3 p-2 hidden'>
                    <div className='w-full flex items-center'>
                        <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                        <div className='border-gray-200  p-1 ml-2 '>
                            <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                                Document generated on 19-JUN-2023 16:10:05 AEST<br />
                                Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                                Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>
                        </div>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(AndrologyResultViewPrint)
