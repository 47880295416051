import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { generateScanInput, getCorrectDate, getScanResults } from '../../../common';
import { ApiCall_GET } from '../../../Redux/Generic/action';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../../AppContextProvider ';

export const InCycleBloodScan = ({ ApiCall_GET, getScansAndUpdateBF }) => {
    const theme = useAppContext().themeDetails;
    const [allScans, setAllScans] = useState(null);
    const [bloodObject, setBloodObject] = useState(null);

    const location = useLocation();
    var artid = location.state.artid

    useEffect(() => {

        var scanList = {};
        var bloodinfo = {};
        ApiCall_GET('Art/GetBloodTestDetails/' + artid, true).then(function (response) {

            response = response.sort((a, b) => a.testdayofcycle - b.testdayofcycle).reverse();

            setBloodObject(response)
            bloodinfo = response
        });

        ApiCall_GET('Art/GetScanDetails/' + artid, true).then(function (response) {
            scanList = response.ScanDetailList.sort((a, b) => a.scanresultid - b.scanresultid).reverse();
            if (scanList[0]?.scanresultid > 0 && scanList.length > 0 && (scanList[0].result_l || scanList[0].result_r)) {
                setAllScans(scanList[0])
                getScansAndUpdateBF && getScansAndUpdateBF(scanList[0])
            }
        });
    }, [])


    return (
        <div className='xl:flex  items-between py-2'>
            {/* In Cycle Scan Informatio */}
            <div className='xl:w-1/2 lg:w-full mr-1 shadow '>
                <div className='w-full   '>
                    <div className={'  bg-[#5599ae] text-white text-left p-1  rounded-t'}>
                        <p className='text-sbase font-medium'>In Cycle Scan Information</p>
                    </div>
                    {allScans && allScans.scanresultid > 0 ?
                        <div className={theme.thumb + ' ' + theme.track + '   overflow-auto scrollbar  scrollbar-thin w-full px-2 py-1 '} style={{ width: "100%" }}>
                            <div className={' bg-[#a9cbd6a6] w-full  flex  mt-1 py-1 rounded-t items-center justify-center '}>
                                {/* theme.bg_Light + + theme.txt_color */}
                                <div style={{ width: '15%', height: "100% " }} className={' flex item-center justify-center  py-1 '}>
                                    <p className='text-sm font-medium '>Scan Date  </p>
                                </div>
                                <div style={{ width: '13%', height: "100% " }} className={' py-1   flex items-center justify-center  '}>
                                    <p className='text-sm font-medium '>Cycle Day</p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={'  py-1    flex items-center  '}>
                                    <p className='text-sm font-medium '>Next Scan </p>
                                </div>
                                <div style={{ width: '20%', height: "100% " }} className={'  py-1  flex items-center  '}>
                                    <p className='text-sm font-medium '> Performed By</p>
                                </div>
                                <div style={{ width: '22%', height: "100% " }} className={'  py-1   flex items-center justify-center '}>
                                    <p className='text-sm font-medium '>Uterus</p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={'  py-1  text-sm flex items-center justify-center '}>
                                    <p className='text-sm font-medium '>Endo Thickness</p>
                                </div>
                                {/* <div style={{ width: '18%', height: "100% " }} className={'  py-1   flex items-center '}>
                                    <p className='text-sm font-medium '>Comments</p>
                                </div> */}

                            </div>
                            {/* {bloodObject && bloodObject[0]?.bloodtestid > 0 && bloodObject.map((item) => ( */}
                            {/* ))} */}
                            <div className='shadow border rounded-b'>
                                < div className={'  w-full  flex   border   items-center'} >
                                    <div style={{ width: '15%', height: "100% " }} className={' flex  items-center justify-center  py-1 '}>
                                        <p className='text-sm text-gray-700 '>{getCorrectDate(allScans?.scandate)}</p>
                                    </div>
                                    <div style={{ width: '13%', height: "100% " }} className={' px-1   flex items-center  justify-center'}>
                                        <p className='text-sm  text-gray-700'>{allScans?.scandayofcycle}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }} className={' py-1     '}>
                                        <p className='text-sm text-gray-700 '>{getCorrectDate(allScans?.nextscan)}</p>
                                    </div>
                                    <div style={{ width: '20%', height: "100% " }} className={'  py-1   flex items-center '} >
                                        <p className='text-sm text-gray-700 '>{allScans?.clinicianname}</p>
                                    </div>
                                    <div style={{ width: '22%', height: "100% " }} className={'  py-1    flex items-center justify-center '}>
                                        <p className='text-sm text-gray-700 '>{allScans?.result_uterus}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }} className={'  py-1    flex items-center justify-center '}>
                                        <p className='text-sm text-gray-700 '>{allScans?.result_endo}</p>

                                    </div>


                                </div>
                                <div className='flex items-center'>

                                    {/* + theme.hoverBg */}
                                    <div style={{ height: '90px' }} className={'w-1/2 ml-2 relative text-left p-1 hover:bg-[#a9cbd6a6]  '}>
                                        <label className={' font-bold text-sbase ' + theme.txt_color + ' border-b-[3px] border-b-amber-500'}>Scan Result</label>

                                        {/* <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Scan Result</p> */}
                                        <div style={{ width: '98%' }} className={' text-left px-2 pt-2 scrollbar scrollbar-thin overflow-x-auto absolute ' + theme.thumb + ' ' + theme.track}>
                                            <div className='flex '>
                                                <label className={" text-sm   font-bold mr-1"} >L: </label>
                                                <label style={{ width: '94%' }} className={" text-sm   "} >{allScans && allScans.result_l && allScans.result_l}</label>
                                            </div>
                                            <div className='flex'>
                                                <label className={" text-sm   font-bold mr-1"} >R: </label>
                                                <label style={{ width: '96%' }} className={" text-sm "} >{allScans && allScans.result_r}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'w-1/2  p-1  flex item-center justify-center'}>
                                        <p className='text-sm font-medium text-gray-700 px-2'>Comments:</p>
                                        <textarea
                                            style={{
                                                // overflowY: 'hidden', // Hide scrollbar
                                                height: 'fit-content', // Let the textarea adjust its height based on content
                                                minHeight: 'fit-content', // Minimum height to fit content
                                                resize: 'none', // Prevent manual resizing by the user
                                            }}
                                            rows={3} className='text-xss text-left bg-gray-100 font-medium w-11/12 rounded p-1 disabled  scrollbar-thin' disabled value={allScans?.comments} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <p className='px-2 py-1 text-sm text-gray-400 text-center font-bold '>~No Scans found~</p>
                    }
                </div>
            </div>
            {/* In Cycle Blood Information */}
            <div className='xl:w-1/2 lg:w-full   shadow '>
                <div className='w-full   '>
                    <div className={' bg-[#5599ae] text-white text-left p-1  rounded-t'}>
                        <p className='text-sbase font-medium'>In Cycle Blood Information</p>
                    </div>
                    {bloodObject && bloodObject[0]?.bloodtestid > 0 ?
                        <div className={theme.thumb + ' ' + theme.track + '   overflow-auto scrollbar  scrollbar-thin w-full px-2 py-1 '} style={{ width: "100%", height: '200px' }}>
                            <div className={' bg-[#a9cbd6a6] w-full  flex  mt-1  rounded-t py-1 '}>
                                <div style={{ width: '10%', height: "100% " }} className={' flex item-center justify-center  py-1 '}>
                                    <p className='text-sm font-medium '>Day </p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={' py-1  '}>
                                    <p className='text-sm font-medium '>Test Date </p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={'  py-1 '}>
                                    <p className='text-sm font-medium '>E2 pmol/L</p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={'  py-1 '}>
                                    <p className='text-sm font-medium '>P4 nmol/L</p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={'  py-1 '}>
                                    <p className='text-sm font-medium '>LH IU/L</p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={'  py-1 '}>
                                    <p className='text-sm font-medium '>FSH IU/L</p>
                                </div>
                                <div style={{ width: '15%', height: "100% " }} className={'  py-1 '}>
                                    <p className='text-sm font-medium '>HCG IU/L</p>
                                </div>

                            </div>
                            {bloodObject && bloodObject[0]?.bloodtestid > 0 && bloodObject.map((item) => (
                                < div className={'  w-full  flex  py-2  rounded-b shadow border'} >
                                    <div style={{ width: '10%', height: "100% " }} className={' flex item-center justify-center  '}>
                                        <p className='text-sm text-gray-700 '>{item.testdayofcycle}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }}  >
                                        <p className='text-sm  text-gray-700'>{getCorrectDate(item.testdate)}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }}  >
                                        <p className='text-sm text-gray-700 '>{item.e2_dose}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }}  >
                                        <p className='text-sm text-gray-700 '>{item.p4_dose}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }}  >
                                        <p className='text-sm text-gray-700 '>{item.lh_dose}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }}  >
                                        <p className='text-sm text-gray-700 '>{item.fsh_dose}</p>
                                    </div>
                                    <div style={{ width: '15%', height: "100% " }}  >
                                        <p className='text-sm text-gray-700 '>{item.hcg_dose}</p>
                                    </div>

                                </div>
                            ))}
                        </div>
                        : <p className='px-2 py-1 text-sm text-gray-400 text-center font-bold '>~No Blood Test found~</p>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    apiresult: state.genericReducer.apiresult
})

export default connect(mapStateToProps, { ApiCall_GET })(InCycleBloodScan);
