import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../Assets/images/white.png';
import { getCorrectDate, getUserInformation } from '../../../../common';
import moment from 'moment'
import { getSession } from '../../../../session';
import { CycleType } from '../../../../common';
import { useAppContext } from '../../../../AppContextProvider ';

export const BookingFormPrint = ({ selectedBookingForm }) => {

    const user = getUserInformation()
    const theme = useAppContext().themeDetails;
    const [couple, setCouple] = useState(null)
    useEffect(() => {
        setCouple(getSession('patient'))

    }, [])

    return (
        <div id="divPrint" className=" flex min-h-screen w-full  p-2 delay-900 duration-700  ">
            {/* Header */}
            <div className=" w-full   bg-white p-3">
                <div className={` ${theme.navbar} ` + ' p-2 flex justify-between  w-full mb-2'} >
                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='160px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>
                        <p className=' text-white  text-sm'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                    </div>
                </div>

                <main className=' '>
                    <label className={theme.txt_color + 'text-xl font-semibold'}> Booking Form <label className="text-sbase">v {selectedBookingForm?.BFVersion}</label></label>
                    {/* patient info */}
                    <div className='w-full mt-2 shadow mr-1 mt-3 mb-3'>
                        <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Patient </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>{couple?.patientFullName}</p>
                                    <p className={'text-sm text-gray-500  '}><b>DOB: </b>{couple?.patientDOB}</p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Partner
                                </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>{couple?.partnerFullName}</p>
                                    <p className={'text-sm text-gray-500  '}><b>DOB: </b> {couple?.partnerDOB}</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex items-center  py-1    w-1/2 '>
                                <p className=' text-sm font-medium   text-gray-700'>Clinician</p>
                                <p className={'pl-2 text-sm text-gray-500  '}> {couple?.clinicianName}</p>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className=' text-sm font-medium w-24  text-gray-700'>Couple Code</p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>{couple?.coupleCode}</p><p className={'text-sm text-gray-500  '}><b>ART </b>{selectedBookingForm?.artid}                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='flex mt-2'>
                        <div className='w-[45%] shadow mr-1 pb-2'>
                            <div className={theme.tHedUpdte + ' text-white text-left p-1  rounded-t'}>
                                <p className='text-sbase font-medium'>Thaw & Transfer Procedure</p>
                            </div>
                            <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Procedure Type </p>

                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>
                                    {/* {getEnumValueById(selectedBookingForm?.ProcedureTypeId) === "---" ? '' : (
                                        <div className={`flex flex-col h-8 w-8 shadow rounded selectedBookingForms-center justify-center items-center `}
                                            style={{ backgroundColor: (getCycleTypeColor(getEnumValueById(selectedBookingForm?.ProcedureTypeId).split(' ')[0].trim()) || 'gray') }}>
                                            <label className={` text-sm text-center font-semibold w-7 overflow-hidden pb-1  text-${getTextColor(getCycleTypeColor(selectedBookingForm?.ProcedureTypeId))}`}>
                                                {getEnumValueById(selectedBookingForm?.ProcedureTypeId)}
                                            </label>
                                            <div className="rounded bg-white flex  selectedBookingForms-center justify-center h-4 w-[80%] my-0.5" >
                                                {selectedBookingForm?.BFVersion && <label className={`  text-sm3 font-bold   text-gray-600`}>v{selectedBookingForm?.BFVersion} </label>}
                                            </div>
                                        </div>
                                    )} */}
                                    <label className=''> {(selectedBookingForm?.ProcedureName)}</label>
                                </p>
                            </div>
                            <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Day one of cycle </p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(selectedBookingForm?.dayOneOfCycleFET)}</p>
                            </div>

                            {selectedBookingForm?.ProcedureTypeId === CycleType.IVF || selectedBookingForm?.ProcedureTypeId === CycleType.IUI
                                || selectedBookingForm?.ProcedureTypeId === CycleType.OvulationInduction || selectedBookingForm?.ProcedureTypeId === CycleType.FOC
                                || selectedBookingForm?.ProcedureTypeId === CycleType.DonorIUI || selectedBookingForm?.ProcedureTypeId === CycleType.EggDonorIVF ? (

                                <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                    <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Procedure Date </p>
                                    <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(selectedBookingForm ? selectedBookingForm?.procedureDate : '')}
                                    </p>
                                </div>
                            ) : (
                                <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                    <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Progesterone Start Date</p>
                                    <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(selectedBookingForm?.progesteroneStartDate)}</p>
                                </div>
                            )}
                            {selectedBookingForm?.fetSurgeDate &&
                                < div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                    <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>LH Surge Date</p>
                                    <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(selectedBookingForm?.fetSurgeDate)}</p>
                                </div>
                            }
                            {selectedBookingForm?.ProcedureTypeId === CycleType.IVF || selectedBookingForm?.ProcedureTypeId === CycleType.IUI || selectedBookingForm?.ProcedureTypeId === CycleType.OvulationInduction || selectedBookingForm?.ProcedureTypeId === CycleType.FOC || selectedBookingForm?.ProcedureTypeId === CycleType.DonorIUI || selectedBookingForm?.ProcedureTypeId === CycleType.EggDonorIVF ?
                                <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                    <p className='cursor-pointer text-sbase font-bold  text-gray-700'>Predicted # of Follicles*</p>
                                    <p className='cursor-pointer text-sbase mx-1 text-gray-700 flex items-center'>{selectedBookingForm ? selectedBookingForm?.predictedFollicleCount : ''}</p>
                                </div>
                                : ""}
                            {selectedBookingForm?.fetTriggerDate &&
                                <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                    <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Trigger Date</p>
                                    <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(selectedBookingForm?.fetTriggerDate)}</p>
                                </div>
                            }
                            {/* {selectedBookingForm?.ProcedureTypeId === CycleType.FET || selectedBookingForm?.ProcedureTypeId === CycleType.FOT &&
                                <>ww
                                    {selectedBookingForm?.fetThawDate && <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Thaw Date</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(selectedBookingForm?.fetThawDate)}</p>
                                    </div>}
                                    {selectedBookingForm?.embryosToThawCount && <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'># of Embryos / Oocytes to thaw</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.embryosToThawCount}</p>
                                    </div>}
                                    {selectedBookingForm?.embryosToThawComments && <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Embryos To Thaw Comments</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.embryosToThawComments}</p>
                                    </div>}
                                </>
                            } */}

                            {(selectedBookingForm?.ProcedureTypeId === CycleType.FET || selectedBookingForm?.ProcedureTypeId === CycleType.FOT) && selectedBookingForm?.ThawAllFET ?
                                <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                    <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Thaw</p>
                                    <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.ThawAllFET ? "Yes" : "No"}</p>
                                </div>
                                : <>
                                    {selectedBookingForm?.fetThawDate && <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Thaw Date</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{getCorrectDate(selectedBookingForm?.fetThawDate)}</p>
                                    </div>}
                                    {selectedBookingForm?.embryosToThawCount > 0 && <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'># of Embryos / Oocytes to thaw</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.embryosToThawCount}</p>
                                    </div>}
                                    {selectedBookingForm?.embryosToThawComments && <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Embryos To Thaw Comments</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.embryosToThawComments}</p>
                                    </div>}
                                </>}
                            <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Clinic</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.ClinicName}</p>
                            </div>
                            <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-36  text-gray-700'>Procedure Location</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.hospitalNameFETName}</p>
                            </div>

                            {selectedBookingForm?.semenPrepMethodName &&
                                <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                    <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Semen Prep. method</p>
                                    <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.semenPrepMethodName}</p>
                                </div>}
                            <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700 '>Anaesthetic type</p>
                                <p className='cursor-pointer text-sbase w-2/3 text-gray-700 items-center '>
                                    {selectedBookingForm?.anestheticOption_GA ? 'General Anesthetic (GA), ' : ''}<br />
                                    {selectedBookingForm?.anestheticOption_Sedation ? 'IV Sedation, ' : ''}<br />
                                    {selectedBookingForm?.anestheticOption_LA ? 'Methoxyflurane(Penthrox) inhalation' : ''}
                                </p>
                            </div>
                        </div>
                        <div className='w-[55%] shadow ml-1'>
                            {selectedBookingForm?.ProcedureTypeId === CycleType.IUI || selectedBookingForm?.ProcedureTypeId === CycleType.OvulationInduction || selectedBookingForm?.ProcedureTypeId === CycleType.FOC || selectedBookingForm?.ProcedureTypeId === CycleType.DonorIUI || selectedBookingForm?.ProcedureTypeId === CycleType.EggDonorIVF ?
                                <><div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}><p className='text-sbase font-medium'>Other Information</p> </div></>
                                :
                                <>
                                    <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}>
                                        <p className='text-sbase font-medium'>Embryo Transfer Procedure</p> </div>
                                    <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Endometrial thickness (mm)</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.endometrialThickness}</p>
                                    </div>
                                    <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Transfer Stage</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.transferStageName}</p>
                                    </div>
                                    <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Freeze All</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.transferStage_FreezeAll ? 'Yes' : 'No'}</p>
                                    </div>
                                    {selectedBookingForm?.embryoTransferDateTimeCalc && <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Embryo Transfer Date</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{(getCorrectDate(selectedBookingForm?.embryoTransferDateTimeCalc))}</p>
                                    </div>}
                                    <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'># of Embryos to Transfer</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.embryosToTransferCount > 0 && selectedBookingForm?.embryosToTransferCount}</p>
                                    </div>
                                    <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>Embryos To Transfer Comments</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.transferStage_FreezeAll ? "" : selectedBookingForm?.embryosToTransferComments}</p>
                                    </div>
                                    <div className=' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   '>
                                        <p className='cursor-pointer text-sbase font-bold w-36 text-gray-700'>TBC / PRN</p>
                                        <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{selectedBookingForm?.embryosToTransferCountTBC ? 'Yes' : 'No'}</p>
                                    </div>
                                </>}
                            <div className=' text-left p-1 py-2 mx-2   border-b-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Comments <label className={' text-sm text-gray-800 font-medium text-sbase font-bold '}>~ by <i><b>{selectedBookingForm?.Username}</b></i></label></p>
                                <textarea rows={selectedBookingForm?.comments && Math.max(selectedBookingForm?.comments.split('\n').length, 1)} className='text-xss text-left  font-medium w-full p-1 disabled ' disabled value={selectedBookingForm?.comments} />
                            </div>
                        </div>
                    </div>
                    <div className='w-full shadow  mt-2'>
                        <div className={theme.tHedUpdte + ' text-white text-left p-1 rounded-t'}><p className='text-sbase font-medium'>Height, weight & BMI calculation</p></div>
                        <div className='flex w-full '>
                            <div className='flex text-left p-1 py-2 mx-2 w-1/3 border-r-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-1/3 text-gray-700'>Patient </p>
                                <div className='w-2/3 px-2'>
                                    <div className='flex'>
                                        <p className='cursor-pointer text-sbase w-2/3 text-gray-700'>Height (cm)  </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.patientHeight > 0 ? selectedBookingForm?.patientHeight : "--"}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className='cursor-pointer text-sbase w-2/3 text-gray-700'>Weight (kg)  </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.patientWeight > 0 ? selectedBookingForm?.patientWeight : "--"}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className='cursor-pointer text-sbase w-2/3 text-gray-700'>BMI </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.patientBMI > 0 ? selectedBookingForm?.patientBMI : "--"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex text-left p-1 py-2 mx-2 w-1/3 border-r-[2px]  border-b-gray-200   '>
                                <p className='cursor-pointer text-sbase font-bold w-1/3 text-gray-700 '>Partner </p>
                                <div className='w-2/3 '>
                                    <div className='flex w-full '>
                                        <p className='cursor-pointer text-sbase w-2/3 text-gray-700'>Height (cm)  </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.partnerHeight > 0 ? selectedBookingForm?.partnerHeight : "--"}</p>
                                    </div>
                                    <div className='flex w-full '>
                                        <p className='cursor-pointer text-sbase w-2/3 text-gray-700'>Weight (kg)  </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.partnerWeight > 0 ? selectedBookingForm?.partnerWeight : "--"}</p>
                                    </div>
                                    <div className='flex w-full '>
                                        <p className='cursor-pointer text-sbase w-2/3 text-gray-700'>BMI </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.partnerBMI > 0 ? selectedBookingForm?.partnerBMI : "--"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-left p-1 py-2 mx-2  w-1/3  '>
                                <p className='cursor-pointer text-sbase font-bold w-44 text-gray-700'>Pathology Results / Status </p>
                                <div>
                                    <div className='flex'>
                                        <p className='cursor-pointer text-sbase w-32 text-gray-700'>Patient  </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.pathologyStatus_Patient_Neg ? "Neg" : (selectedBookingForm?.pathologyStatus_Patient_Pos ? "Pos" : "")} - {selectedBookingForm?.pathologyStatus_Patient_Comment}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className='cursor-pointer text-sbase w-32 text-gray-700'>Partner  </p>
                                        <p className='cursor-pointer text-sbase font-bold  text-gray-700'>{selectedBookingForm?.pathologyStatus_Partner_Neg ? "Neg" : (selectedBookingForm?.pathologyStatus_Partner_Pos ? "Pos" : "")} - {selectedBookingForm?.pathologyStatus_Partner_Comment}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                <footer className='flex mt-3 p-2 hidden'>
                    <div className='w-full flex items-center'>
                        <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                        <div className='border-gray-200  p-1 ml-2 '>
                            <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                                Document generated on 19-JUN-2023 16:10:05 AEST<br />
                                Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                                Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>
                        </div>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(BookingFormPrint)

export default connect(mapStateToProps, {})(BookingFormPrint);