import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../../AppContextProvider ';
import { ApiCall_GET } from '../../../../../Redux/Generic/action';
import { getCorrectDate, getCorrectDateForInput, getDateByRange, getUniqueObjectsByKey, replaceEmptyObjectsWithNull, returnNullIfEmpty } from '../../../../../common';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { getMedicationType, getMedicationTypeNew } from './DrugSwitch';

export const Medications = ({ autoAddMedicines, setFormObj, ApiCall_GET, setAddDrugModal, removeDrug, dayOneOfCycle, IsCalledByView, artData, artid, medication, NumberOfColums, setMedication, ...props }) => {

    const theme = useAppContext().themeDetails
    artData = replaceEmptyObjectsWithNull(artData)



    const medicationVariables = [
        {
            obj: 'Stim',
            label: 'Medication'
        },
        {
            obj: 'Trigger',
            label: 'Trigger'
        },
        {
            obj: 'Luteal',
            label: 'Luteal Phase Support'
        },

    ]

    useEffect(() => {
        ApiCall_GET('Art/GetStimMedicationDetails/' + artid, true).then(function (payload) {
           
            setMedication(replaceEmptyObjectsWithNull(payload))
        })
    }, [])

    const getLabelContent = (item, index, Type) => {

        const filteredMedications = medication[Type]?.filter(
            x => x.drugmastername === item?.drugmastername && x?.dayoffset === (index + 1)
        );
        if (filteredMedications?.length > 0) {
            return filteredMedications[filteredMedications?.length - 1];
        }
    };

    const getKeyDates = (index, date) => {
        if (index == 0) {
            return <label className='bg-[#00509E] rounded text-white py-1 px-3'>LMP</label>
        }
        else if (date == getCorrectDate(artData?.dateofEPU)) {
            return <label className='bg-[#A91111] rounded text-white py-1 px-3'>EPU</label>
        }
        else {
            return <label className=' rounded text-white py-1 px-3'></label>
        }
    }

    const openAddPopup = (item, index, obj) => {
        autoAddMedicines()
        const selectedDate = getDateByRange(dayOneOfCycle, index)
        var extendedObj = {
            medTypeId: item?.medicationTypeId,
            drugId: getMedicationTypeNew(item?.drugmasterid),
            dosage: item?.dose,
            drugName: item?.drugmastername,
            frequency: item.frequency,
            period: item.stimunitsb || item.period,
            units: item.units?.split(' ').length > 1 ? item.units?.split(' ')[1] : item.units,
            dateAdministered: item?.medicationTypeId == 2 ? getCorrectDateForInput(selectedDate) : '',
            startDate: item?.medicationTypeId !== 2 ? getCorrectDateForInput(selectedDate) : '',
            endDate: item?.medicationTypeId !== 2 ? getCorrectDateForInput(selectedDate) : '',
        }
        setFormObj(extendedObj)
        setAddDrugModal(true);
    }


    return (
        <div className='relative  w-full'>
            <label className={theme.txt_color + '  font-bold cursor-pointer border-b-[3px] mb-3 border-b-amber-500'}>Schedule Information </label>
            {IsCalledByView ? <span className='text-sm font-bold'>( <FontAwesomeIcon icon={faEye} /> View Only Mode )</span> : ''}
            {/* <div className={theme.bg_Light + " hidden flex justify-between items-center p-1 w-full shadow rounded-t"}>
                <div className='items-center flex'>
                </div>
                <label className='mr-2 text-gray-700 bg-white cursor-pointer hover:shadow text-orange-400 font-medium rounded-lg text-sm px-5 py-2 '>Add Admin Template</label>
            </div> */}
            {/*Schedule Information------- */}
            <div className={theme.thumb + ' ' + theme.track + ' scrollbar scrollbar-thin flex overflow-auto my-3 pb-3 w-full     '}>
                {/* {TreatmentPlanState && TreatmentPlanState.map((item, index) => ( */}

                <div>
                    <div className={'flex items-center text-white '}>
                        <div className='w-36  p-2 text-sm font-bold sticky shadow  border-r  bg-[#5599ae] '  >
                            <label>Drug Date</label>
                        </div>
                        {Array.from({ length: NumberOfColums }, (_, index) => (
                            <div className='w-16 p-2 text-sm font-bold sticky  border-r   bg-[#5599ae] '  >
                                <label>{dayOneOfCycle ? moment(getCorrectDate(dayOneOfCycle), 'DD-MM-YYYY').add(index, 'days').format('ddd D') : 'Day ' + (index + 1)}</label>
                            </div>
                        ))}

                    </div>
                    <div className={'flex items-center text-white '}>
                        <div className='w-36  p-2 text-sm font-bold sticky shadow  border-r  bg-[#5599ae] '  >
                            <label> Day of Cycle</label>
                        </div>
                        {Array.from({ length: NumberOfColums }, (_, index) => (
                            <div className='w-16 p-2 text-sm font-bold sticky  border-r   bg-[#5599ae] '  >
                                <label>{index + 1}</label>
                            </div>
                        ))}
                    </div>
                    <div className={'flex items-center my-1 text-white '}>
                        <div className='w-36 p-2 text-gray-600 font-bold text-sm   border-r cursor-pointer  bg-[#DDFFFF] '  >
                            <label >Key Dates</label>
                        </div>
                        {Array.from({ length: NumberOfColums }, (_, index) => (
                            <div className='w-16 p-2 text-gray-600 font-bold text-sm   border-r cursor-pointer  bg-[#DDFFFF] '  >
                                {getKeyDates(index, moment(getCorrectDate(dayOneOfCycle), 'DD-MM-YYYY').add(index, 'days').format('DD-MM-YYYY'))}
                            </div>
                        ))}
                    </div>
                    {/* drugmastername */}
                    {medication && medicationVariables.map((med) => getUniqueObjectsByKey(medication[med?.obj], 'drugmastername')?.length > 0 ?
                        <>
                            <p className={theme.txt_color + '  font-bold cursor-pointer p-1 '}>{med?.label}</p>
                            {getUniqueObjectsByKey(medication[med?.obj], 'drugmastername').map((item) => (
                                <div className={'flex items-center my-1  '}>
                                    <div className='w-36 h-14 flex items-center p-2 text-gray-600 font-bold text-md   border-r cursor-pointer  bg-[#DDFFFF] '  >
                                        <label >{item?.drugmastername} {!IsCalledByView ? <span className='ml-2 text-red-500 bg-white cursor-pointer  border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm p-2 ' onClick={() => removeDrug(item)}><FontAwesomeIcon icon={faTrash} /></span> : ''}</label>
                                    </div>
                                    {Array.from({ length: NumberOfColums }, (_, index) => (
                                        <div onClick={() => { openAddPopup(item, index, med?.obj) }} className={'w-16 h-14    font-bold hover:scale-125 flex flex-col text-center justify-center border-r cursor-pointer ' + (getLabelContent(item, index, med?.obj) ? 'bg-orange-300' : (moment(getCorrectDate(dayOneOfCycle)).add(index, 'days').format('ddd') == 'Sat' || moment(getCorrectDate(dayOneOfCycle)).add(index, 'days').format('ddd') == 'Sun' ? 'bg-green-100' : 'bg-[#DDFFFF]') + ' ')}  >
                                            <p className=' rounded text-center text-sm px-3'>
                                                {getLabelContent(item, index, med?.obj)?.dose}<br />
                                            </p>
                                            <p className=' rounded text-center text-xs px-3'>
                                                {/* {(getLabelContent(item, index, med?.obj)?.theunits ? getLabelContent(item, index, med?.obj)?.theunits : '') + ' ' + (getLabelContent(item, index, med?.obj)?.frequency ? getLabelContent(item, index, med?.obj)?.frequency : '') + ' ' +(getLabelContent(item, index, med?.obj)?.units1 ? getLabelContent(item, index, med?.obj)?.units1 : '') + ' ' + (getLabelContent(item, index, med?.obj)?.period ? getLabelContent(item, index, med?.obj)?.period : '') + ' ' + (getLabelContent(item, index, med?.obj)?.stimunitsb ? getLabelContent(item, index, med?.obj)?.stimunitsb : '')}<br /> */}
                                                {(getLabelContent(item, index, med?.obj)?.units1 ? getLabelContent(item, index, med?.obj)?.units1 : '') + ' ' + (getLabelContent(item, index, med?.obj)?.period ? getLabelContent(item, index, med?.obj)?.period : '')}<br />
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))

                            }
                        </>
                        : ''
                    )}



                </div>

                {/* ))} */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(Medications)